@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:400,500,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:400,500,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:400,500,600,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.HeaderContact .headerContact {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 360px;
  background-size: cover;
  position: relative;
}

.myrow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  column-count: 2;
}

/* slider --------------------------- */
.sliderContactUs {
  height: 360px;
  width: 100%;
  overflow: hidden;
  margin: 0px;
  margin-top: -12px !important;
  filter: brightness(0.6);
  animation: hidde 30s infinite alternate ease-in-out;
}

.sliderContactUs ul {
  display: flex;
  padding: 0;
  width: 400%;
  animation: cambio 30s infinite alternate steps(1);
}

.sliderContactUs li {
  width: 100%;
  list-style: none;
}

.sliderContactUs img {
  width: 100%;
}

@keyframes hidde {
  0% {
    opacity: 1;
  }

  19% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  45% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  55% {
    opacity: 1;
  }

  65% {
    opacity: 1;
  }

  70% {
    opacity: 1;
  }

  75% {
    opacity: 1;
  }

  100% {
  }
}

@keyframes cambio {
  0% {
    margin-left: 0;
  }

  20% {
    margin-left: 0;
  }

  25% {
    margin-left: -150%;
  }

  45% {
    margin-left: -150%;
  }

  50% {
    margin-left: -300%;
  }

  70% {
    margin-left: -300%;
  }

  75% {
    margin-left: -300%;
  }

  100% {
    margin-left: -300%;
  }
}

.HeaderContact .headerContact h1 {
  color: white;
  font-size: 40px;
  font-weight: bold;
  margin-top: -20px;
}

.HeaderContact .headerContact h2 {
  text-align: center;
  color: white;
  width: 500px;
  font-size: 25px;
  font-weight: bolder;
}

.HeaderContact .headerContact button {
  background-color: #D1422F;
  color: white;
  padding: 14px;
  font-size: 17px;
  border: 0px;
  padding-inline: 50px;
  border-radius: 6px;
  cursor: pointer;
  outline: none;
  transition: 0.2s;
}

.HeaderContact .headerContact button:hover {
  color: #D1422F;
  background-color: white;
}

/* ContactFormComp ----------------------------- */

.ContactFormCompComponent .Contact {
  padding: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  background-color: white;
  position: relative;
  border-radius: 10px;
}

.MyContactForm .Contact {
  padding: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  background-color: white;
  position: relative;
  border-radius: 10px;
}

.ContactFormCompComponent .Contact .lastChild {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}

.MyContactForm .Contact .lastChild {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}

.ContactFormCompComponent .Contact .lastChild .contentInputs {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-right: 20px;
  margin-bottom: 20px;
}

.MyContactForm .Contact .lastChild .contentInputs {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-right: 20px;
  margin-bottom: 20px;
}

.ContactFormCompComponent .Contact .lastChild .contentInputs2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 20px;
  margin-bottom: 20px;
}

.MyContactForm .Contact .lastChild .contentInputs2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 20px;
  margin-bottom: 20px;
}

.ContactFormCompComponent .Contact .lastChild .contentInputs3 {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.MyContactForm .Contact .lastChild .contentInputs3 {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.ContactFormCompComponent .Contact .lastChild .contentInputs input {
  padding: 7px;
  border-radius: 5px;
  border: 2px solid #cdcdcd;
}

.MyContactForm .Contact .lastChild .contentInputs input {
  padding: 7px;
  border-radius: 5px;
  border: 2px solid #cdcdcd;
}

.ContactFormCompComponent .Contact .lastChild .contentInputs span {
  font-size: 12px;
  margin-bottom: 7px;
  color: #000000;
  font-weight: 400;
}

.MyContactForm .Contact .lastChild .contentInputs span {
  font-size: 12px;
  margin-bottom: 7px;
  color: #000000;
  font-weight: 400;
}

.ContactFormCompComponent .Contact .lastChild .contentInputs2 span {
  font-size: 12px;
  margin-bottom: 7px;
  color: #000000;
  font-weight: 400;
}

.MyContactForm .Contact .lastChild .contentInputs2 span {
  font-size: 12px;
  margin-bottom: 7px;
  color: #000000;
  font-weight: 400;
}

.ContactFormCompComponent .Contact .lastChild .contentInputs2 input {
  padding: 7px;
  border-radius: 5px;
  border: 2px solid #cdcdcd;
}

.MyContactForm .Contact .lastChild .contentInputs2 input {
  padding: 7px;
  border-radius: 5px;
  border: 2px solid #cdcdcd;
}

.ContactFormCompComponent .Contact .lastChild .contentInputs3 span {
  font-size: 12px;
  margin-bottom: 7px;
  color: #000000;
  font-weight: 400;
}

.MyContactForm .Contact .lastChild .contentInputs3 span {
  font-size: 12px;
  margin-bottom: 7px;
  color: #000000;
  font-weight: 400;
}

.ContactFormCompComponent .Contact .lastChild .contentInputs3 textarea {
  padding: 7px;
  border-radius: 5px;
  border: 2px solid #cdcdcd;
  min-height: 150px;
  max-height: 150px;
  width: 97%;
  min-width: 97%;
  max-width: 97%;
}

.MyContactForm .Contact .lastChild .contentInputs3 textarea {
  padding: 7px;
  border-radius: 5px;
  border: 2px solid #cdcdcd;
  min-height: 150px;
  max-height: 150px;
  width: 97%;
  min-width: 97%;
  max-width: 97%;
}

.ContactFormCompComponent {
  background-color: #f3f3f3;
  padding-top: 1px;
  margin: 340px;
  margin-bottom: 90px;
  margin-top: -90px;
  border-radius: 10px;
  box-shadow: 0px 0px 18px 0px #00000036;
}

.MyContactForm {
  background-color: #f3f3f3;
  padding-top: 1px;
  border-radius: 10px;
  box-shadow: 0px 0px 18px 0px #00000036;
}

.ContactFormCompComponent .Submit {
  width: 97%;
  padding: 10px;
  background-color: #D1422F;
  color: white;
  border: 0px;
  margin-top: 21px;
  border-radius: 6px;
  cursor: pointer;
  transition: 0.2s;
  text-align: center;
}

.MyContactForm .Submit {
  width: 97%;
  padding: 10px;
  background-color: #D1422F;
  color: white;
  border: 0px;
  margin-top: 21px;
  border-radius: 6px;
  cursor: pointer;
  transition: 0.2s;
  text-align: center;
}

.ContactFormCompComponent .Submit:hover {
  color: #D1422F;
  background-color: white;
}

.MyContactForm .Submit:hover {
  color: #D1422F;
  background-color: white;
}

@media screen and (max-width: 920px) {
  .sliderContactUs li {
    width: 100%;
    list-style: none;
    overflow: hidden;
  }
  .sliderContactUs img {
    min-width: 1270px;
    height: 390px;
  }
}
@media screen and (max-width: 1158px) {
  .ContactFormCompComponent {
    background-color: #f3f3f3;
    padding-top: 1px;
    margin: 60px;
    margin-top: -90px;
    border-radius: 10px;
    box-shadow: 0px 0px 18px 0px #00000036;
  }
}
@media screen and (max-width: 412px) {
  .ContactFormCompComponent {
    background-color: #f3f3f3;
    padding-top: 1px;
    margin: 10px;
    margin-top: -90px;
    border-radius: 10px;
    box-shadow: 0px 0px 18px 0px #00000036;
  }
  .ContactFormCompComponent .Contact {
    padding: 35px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    background-color: white;
    position: relative;
    border-radius: 10px;
  }
  .ContactFormCompComponent .Contact .lastChild {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .ContactFormCompComponent .Contact .lastChild .contentInputs {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 0px;
    margin-bottom: 20px;
  }
  .HeaderContact .headerContact h2 {
    text-align: center;
    color: white;
    width: 100%;
    font-size: 25px;
    font-weight: lighter;
  }
  .ContactFormCompComponent .Contact .lastChild .contentInputs2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 0px;
    margin-bottom: 20px;
  }
  .ContactFormCompComponent .Contact .lastChild .contentInputs3 textarea {
    padding: 7px;
    border-radius: 5px;
    border: 2px solid #cdcdcd;
    min-height: 150px;
    max-height: 150px;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
  .ContactFormCompComponent .Submit {
    width: 100%;
    padding: 10px;
    background-color: #D1422F;
    color: white;
    border: 0px;
    margin-top: 21px;
    border-radius: 6px;
    cursor: pointer;
    transition: 0.2s;
  }
}

input.invalid,
select.invalid {
  border: red solid 2px !important;
}

.imageStyles1 {
    background-repeat: no-repeat !important;
    background-position: 0px 0px !important;
}

.footer {
    background-image: url(/static/media/bg2.88fd0b49.png);
    background-size: cover;
    background-color: "#212629";
    background-size: "110%";
}

.MenuMovilContent {
    background-color: white;
    width: 100%;
    height: 70px;
    display: none;
    justify-content: space-between;
    padding: 20px;
    align-items: center;
    position: relative;
    z-index: 6;
}

.MenuMovilContent .imgFirst {
    width: 130px;
}

.MenuMovilContent .imgLast {
    width: 40px;
}

.MenuMovilOptions {
    position: fixed;
    width: 100%;
    top: 0px;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    transition: 0.2s;
    z-index: 99;
}

.MenuMovilOptions .Options {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.MenuMovilOptions .Options .href {
    font-size: 30px;
    font-weight: bold;
    color: #e13019;
    text-align: center;
    margin: 20px;
    line-height: 1;
}

.section4 .qualityImages {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 50px;
    padding-top: 0px;
}

.section4 .qualityImages img {
    width: 256px;
    margin: 24px;
    margin-top: 0px;
}

.container-fluid {
    max-width: none !important;
}

.MenuMovilOptions .Options .href2 {
    font-weight: bold;
    font-size: 30px;
    background-color: #e13019;
    color: white;
    padding: 21px;
    border-radius: 9px;
    margin: 20px;
}

.Close {
    background-color: white;
    display: flex;
    justify-content: flex-end;
}

.Close h1 {
    font-size: 21px;
    font-family: cursive;
    background-color: #e13019;
    color: white;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 8px;
    border-radius: 4px;
}

.LineCardText {
    width: 100%;
    text-align: center;
    margin-top: 50px;
    font-size: 30px;
    font-weight: bold;
}

.ContentAllFSC {
    display: flex;
    flex-direction: row-reverse;
}

.ContentAllFSC .content3 {
    width: 100%;
}

.ContentAllFSC .FSCHome {
    width: 100%;
    max-width: 500px;
}

/* .ContentAllFSC .FSCHome:focus {
    outline: 4px solid #e13019;
} */

.child1 .subChild1ContentBtn {
    display: flex;
    flex-direction: row;
}

.footer .aStyle2 {
    position: relative;
    padding: 2.2rem 0 2.6rem;
    letter-spacing: inherit;
    line-height: 1;
    text-transform: uppercase;
    color: white !important;
}

.page-content {
    margin-top: -648px;
    position: relative;
}

/* slider --------------------------- */
.sliderHome {
    height: 570px;
    width: 100%;
    overflow: hidden;
    margin: 0px;
    filter: brightness(35%);
    animation: hidde 30s infinite alternate ease-in-out;
}

/* //?padin slider */

.pts1 {
    padding-top: 100px;
}


.aStyle3 {
    color: white;
    font-size: 15px;
    margin: 0px;
}

.aStyle3:hover {
    color: rgb(221, 221, 221);
    font-size: 15px;
    margin: 0px;
}

.sliderHome ul {
    display: flex;
    padding: 0;
    width: 400%;
    opacity: 1;
    animation: cambio 30s infinite alternate steps(1);
}

.sliderHome li {
    width: 100%;
    list-style: none;
}

.sliderHome img {
    width: 100%;
}

@keyframes hidde {
    0% {
        opacity: 1;
    }

    19% {
        opacity: 1;
    }

    25% {
        opacity: 0;
    }

    30% {
        opacity: 1;
    }

    45% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    55% {
        opacity: 1;
    }

    65% {
        opacity: 1;
    }

    70% {
        opacity: 1;
    }

    75% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

@keyframes cambio {
    0% {
        margin-left: 0;
    }

    20% {
        margin-left: 0;
    }

    25% {
        margin-left: -150%;
    }

    45% {
        margin-left: -150%;
    }

    50% {
        margin-left: -300%;
    }

    70% {
        margin-left: -300%;
    }

    75% {
        margin-left: -300%;
    }

    100% {
        margin-left: -300%;
    }
}

.child2FormQuota {
    margin-left: -84px;
    margin-top: -640px;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.child2FormQuota .subChild1 {
    display: flex;
    flex-direction: column;
    background-color: #181634;
    color: white;
    padding: 40px;
    border-radius: 10px;
    position: relative;
    top: 100px;
    width: 531px;
    box-shadow: 0px 0px 28px 0px #000000c2;
    z-index: 2;
    margin-top: 30px;
    margin-left: 80px;
}

.child2FormQuota .subChild1 h1 {
    text-align: center;
    color: white;
    font-size: 25px;
    margin-top: 0px;
    margin-bottom: 50px;
    font-weight: bolder;
}

.child2FormQuota .subChild1 span {
    font-size: 13px;
    margin: 8px;
    margin-left: 0px;
    font-weight: bolder;
}

.child2FormQuota .subChild1 input {
    padding: 10px;
    background-color: #2c2a4a;
    border: 0px;
    border-radius: 7px;
    margin-bottom: 15px;
    color: white;
}

.has-center .header-left a {
    padding: 0px !important;
    height: 58px !important;
}

.child2FormQuota .subChild1 button {
    padding: 10px;
    background-color: #e13019;
    color: white;
    border-radius: 6px;
    border: 0px;
    margin-top: 19px;
    cursor: pointer;
    transition: 0.2s;
    font-size: 14px;
}

.child2FormQuota .subChild1 button:hover {
    color: #e13019;
    background-color: white;
}

.page-content .banner-content .child1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 124%;
}

.page-content .banner-content .child1 h1 {
    color: white;
    font-size: 26px;
    margin: 15px;
}

.page-content .banner-content .child1 div input {
    font-size: 20px;
    width: 481px;
    padding: 16px;
    border-radius: 6px;
    border: 0px;
    margin-right: 15px;
}

.page-content .banner-content .child1 div button {
    padding: 12px;
    background-color: #e13019;
    border: 0px;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.2s;
    font-weight: bold;
    font-size: 14px;
    height: 100%;
}

.page-content .banner-content .child1 div button:hover {
    color: #c53724;
    background-color: white;
}

.header-transparent2 {
    position: relative !important;
}

.header-middle2 {
    border: 0px !important;
}

.content3 h1 {
    padding-inline: 60px;
    text-align: center;
    font-size: 50px;
    margin-top: 90px;
}

.content3 span {
    color: #ff472b;
}

.divContentLetter h3 {
    color: white;
    margin: 10px;
    font-size: 18px;
}

.divContentLetter h2 {
    color: white;
    margin: 10px;
    font-size: 13px;
}

.content3 p {
    padding-inline: 6%;
    text-align: left;
    margin-top: 30px;
}

.content3 ul {
    padding-inline: 10%;
    text-align: left;
    margin-top: 30px;
}

.subsection4Other {
    text-align: center;
    padding: 90px;
    padding-top: 0px;
    margin-top: 60px;
}

.subsection4Other h1 {
    font-size: 50px;
}

.subsection4Other h3 {
    font-weight: bold;
    margin-top: -50px;
}

.subsection4Other p {
    font-size: 16px;
}

.redesSociales {
    display: flex;
    justify-content: flex-end;
}

.redesSociales img {
    height: auto;
    width: 26px;
    margin: 7px;
}

.ItemClass {
    font-size: 16px;
    border-bottom: 2px solid #c7c7c7;
    padding-bottom: 6px;
    color: #181634ad;
    padding-top: 6px;
    padding-left: 14px;
    cursor: pointer;
    transition: 0.1s;
}

.ItemClass:hover {
    background-color: #dddddd;
}

.content4 {
    /* background-color: #ff472b; */
    background-color: #ff745e;
    /* color: white; */
    color: #050505;
    padding-inline: 0px;
    padding-top: 60px;
    padding-bottom: 60px;
}

.content4 h1 {
    font-size: 50px;
    color: white;
    text-align: center;
}

.content4 h3 {
    font-weight: bold;
}

.content4 p {
    font-size: 16px;
    text-align: center;
    width: 62%;
    margin: 0 auto;
}

.owl-carousel .owl-item img {
    height: auto;
}

.owl-loaded .owl-stage:after {
    content: "";
}

.owl-carousel:not(.owl-loaded) {
    flex-wrap: nowrap;
    overflow: hidden;
}

.owl-theme .owl-nav.disabled+.owl-dots {
    margin-top: 1.5rem;
}

.owl-theme .owl-dots .owl-dot.active span {
    background-color: #0b80d2;
    border-color: #0b80d2;
}

.owl-theme .owl-dots .owl-dot span {
    margin: 5px;
}

.owl-dot-white .owl-dots .owl-dot span {
    background-color: #fff;
    border-color: #fff;
    opacity: 0.8;
}

.owl-dot-white .owl-dots .owl-dot.active span {
    background-color: #fff;
    border-color: #fff;
    opacity: 1;
}

*,
:after,
:before {
    box-sizing: inherit;
}

html {
    font-size: 62.5%;
    font-size-adjust: 100%;
    font-weight: 400;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
    margin: 0;
    overflow-x: hidden;
    font-family: Poppins, sans-serif;
    font-size: 1.4rem;
    line-height: 1.6;
    color: #666;
}

main {
    display: block;
    position: relative;
}

.page-wrapper {
    position: relative;
    transition: margin 0.4s;
}

.grey-section {
    background: #f3f6f9;
}

ul {
    padding-left: 1.5em;
}

.breadcrumb,
.category ul,
.comments ul,
.dropdown-box,
.filter-items,
.list,
.menu,
.menu ul,
.mobile-menu,
.mobile-menu ul,
.nav,
.nav-filters,
.nav ul,
.pagination,
.product-nav,
.product-tabs>div ul,
.select-menu>ul,
.widget-body,
.widget-body ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
    color: inherit;
    transition: color 0.3s;
}

a:hover {
    color: #0b80d2;
}

:focus {
    outline: 5px auto -webkit-focus-ring-color !important;
    outline-offset: -2px;
    /* outline: 4px solid #407803 !important; */
}

/* :focus { outline: none; } */

img {
    max-width: 100%;
    height: auto;
}

i {
    font-style: normal;
}

/* button:focus {
    outline: none;
} */

button:focus {
    outline: 4px dashed orange;
}

div[tabindex="0"]:focus {
    outline: 4px dashed orange;
}

.scrollable,
.sidebar-content {
    -webkit-overflow-scrolling: touch;
}

.scrollable::-webkit-scrollbar,
.sidebar-content::-webkit-scrollbar {
    height: 7px;
    width: 4px;
}

.scrollable::-webkit-scrollbar-thumb,
.sidebar-content::-webkit-scrollbar-thumb {
    margin-right: 2px;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    cursor: pointer;
}

.appear-animate {
    transform: translate3d(0, 0, 0) scale(1);
    will-change: transform, filter, opacity;
}

.scroll-top {
    position: fixed;
    right: 15px;
    bottom: 0;
    width: 40px;
    height: 40px;
    font-size: 16px;
    color: #fff;
    text-align: center;
    line-height: 40px;
    visibility: hidden;
    opacity: 0;
    transition: transform 0.3s, visibility 0.3s, opacity 0.3s;
    transform: translateY(40px);
    z-index: 9999;
    background-color: #43494e;
}

.scroll-top:hover {
    color: #fff;
}

@media (min-width: 768px) {
    .scroll-top.show {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fixedTop {
    0% {
        transform: translateY(-100%);
        transform-origin: center top 0px;
    }

    to {
        transform: translateY(0);
    }
}

.sticky-content.fix-top {
    top: 0;
}

.sticky-content.fixed {
    position: fixed;
    left: 0;
    right: 0;
    opacity: 1;
    transform: translateY(0);
    background: #fff;
    z-index: 1051;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
}

.sticky-content.fixed.fix-top {
    animation: fixedTop 0.4s;
}

.w-100 {
    width: 100% !important;
}

.x-50 {
    left: 50% !important;
    transform: translateX(-50%) !important;
}

.x-50.y-50 {
    transform: translate(-50%, -50%) !important;
}

.y-50 {
    top: 50% !important;
    transform: translateY(-50%) !important;
}

.d-none {
    display: none !important;
}

.d-block {
    display: block !important;
}

.d-inline-block {
    display: inline-block !important;
}

.d-flex {
    display: flex !important;
}

.justify-content-center {
    justify-content: center !important;
}

.align-items-center {
    align-items: center !important;
}

.flex-wrap {
    flex-wrap: wrap !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

@media (min-width: 782px) {
    .d-lg-none {
        display: none !important;
    }

    .d-lg-block {
        display: block !important;
    }
}

.font-primary {
    font-family: Poppins, sans-serif !important;
}

.font-secondary {
    font-family: "Open Sans", sans-serif !important;
}

.font-tertiary {
    font-family: "Segoe Script", "Savoye LET", sans-serif !important;
}

.font-weight-bold {
    font-weight: 700 !important;
}

.font-weight-semi-bold {
    font-weight: 600 !important;
}

.font-weight-normal {
    font-weight: 400 !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.text-none {
    text-transform: none !important;
}

.text-center {
    text-align: center !important;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-white {
    color: #fff !important;
}

.text-grey {
    color: #999 !important;
}

.text-body {
    color: #666 !important;
}

.text-dark {
    color: #222 !important;
}

.text-primary {
    color: #0b80d2 !important;
}

.ls-s {
    letter-spacing: -0.01em !important;
}

.ls-m {
    letter-spacing: -0.025em !important;
}

.ls-l {
    letter-spacing: -0.05em !important;
}

.lh-1 {
    line-height: 1 !important;
}

.bg-white {
    background-color: #fff !important;
}

.bg-dark {
    background-color: #222 !important;
}

.container,
.container-fluid {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 479px) {

    .container,
    .container-fluid {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.container-fluid {
    max-width: 1800px;
}

.container {
    max-width: 1220px;
}

.divider {
    display: inline-block;
    vertical-align: middle;
    margin-right: 1.4rem;
    height: 1.9rem;
    width: 1px;
    background-color: #e1e1e1;
}

.banner-subtitle {
    font-size: 16px !important;
    border-bottom: 2px solid white !important;
}

.banner-title {
    font-size: 40px !important;
    font-family: system-ui !important;
}

.banner {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    font-size: 1rem;
}

.banner figure img {
    display: block;
    width: 100%;
    height: auto;
}

.banner .banner-content {
    position: relative;
}

.banner-title {
    line-height: 1;
}

.banner-fixed>.banner-content,
.banner-fixed>.container,
.banner-fixed>.container-fluid {
    position: absolute;
    z-index: 1;
}

.banner-fixed>.container,
.banner-fixed>.container-fluid {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.video-banner {
    position: relative;
}

.video-banner .btn-play {
    display: inline-block;
    border-radius: 50%;
    transition: background-color 0.3s, border-color 0.3s;
    border: 1px solid;
    line-height: 49px;
    width: 51px;
    height: 51px;
    font-size: 22px;
}

.video-banner .btn-play:hover {
    background-color: #0b80d2;
    border-color: #0b80d2;
}

.header {
    letter-spacing: -0.01em;
    background: #fff;
    font-size: 1.4rem;
}

.header .container,
.header .container-fluid,
.header .inner-wrap {
    display: flex;
    align-items: center;
}

.header .inner-wrap {
    width: 100%;
}

.header-transparent {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 20;
    background: transparent;
}

.header-transparent .fixed {
    background: #222;
}

.header-bottom,
.header-middle,
.header-top {
    display: flex;
}

.has-center .header-left,
.has-center .header-right {
    flex: 1 1;
}

.has-center .header-right {
    justify-content: flex-end;
}

.has-center .header-center {
    margin-left: 2rem;
    margin-right: 2rem;
}

.header-center,
.header-left,
.header-right {
    display: flex;
    align-items: center;
}

.header-right {
    margin-left: auto;
}

.header-middle {
    padding-top: 0px !important;
    padding-bottom: 1.7rem;
    color: #fff;
    font-weight: 600;
    border: 0px !important;
}

.NewColorMenu {
    background-color: white !important;
}

.header-middle .logo {
    margin-right: 3rem;
}

.header-middle .main-nav {
    margin-right: 0;
}

.header-middle .header-right .cart-dropdown {
    margin-right: 0 !important;
}

.logo {
    max-width: 200px;
    margin-bottom: 0.3rem;
}

.FSCHome .acordeonNsNs {
    height: 462px;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: white;
}

.acordeonNsNs::-webkit-scrollbar {
    -webkit-appearance: none;
}

.acordeonNsNs::-webkit-scrollbar:vertical {
    width: 10px;
}

.acordeonNsNs::-webkit-scrollbar-button:increment,
.acordeonNsNs::-webkit-scrollbar-button {
    display: none;
}

.acordeonNsNs::-webkit-scrollbar:horizontal {
    height: 10px;
}

.acordeonNsNs::-webkit-scrollbar-thumb {
    background-color: #797979;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
}

.acordeonNsNs::-webkit-scrollbar-track {
    border-radius: 10px;
}

.logo img {
    background-color: white;
    padding: 10px;
    border-radius: 8px;
    width: 320px;
    max-width: 240px;
    margin: -11px;
}

.sticky-header.fixed {
    position: fixed;
    top: 0;
    width: 100%;
    padding-top: 1.3rem;
    padding-bottom: 1.3rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1100;
    animation: fixedTop 0.4s;
}

@media (max-width: 991px) {
    .sticky-wrapper {
        height: auto !important;
    }
}

.mobile-menu-toggle {
    display: none;
    width: 24px;
    height: 16px;
    color: #0b80d2;
}

.mobile-menu-toggle svg {
    stroke: #222;
    margin: -17px -13px;
    width: 50px;
    height: 50px;
}

@media (max-width: 1199px) {
    .main-nav .menu>li {
        margin-right: 1.5rem;
    }

    .header-middle .logo {
        margin-right: 2rem;
    }

    .header-middle .divider {
        display: none;
    }
}

@media (max-width: 991px) {

    .header .divider,
    .header .main-nav {
        display: none;
    }

    .header-middle .header-left,
    .header-middle .header-right {
        flex: auto;
    }

    .header-middle .header-right {
        justify-content: flex-end;
    }

    .header-middle .header-center {
        margin-left: auto;
        margin-right: auto;
    }

    .header-middle .logo {
        margin-right: 0;
    }

    .mobile-menu-toggle {
        display: block;
        margin-right: 1.5rem;
    }

    .mobile-menu-toggle svg {
        stroke: #0b80d2;
    }
}

.menu {
    display: flex;
    align-items: center;
}

.menu a {
    display: inline-block;
}

.menu a:hover {
    color: #a01300 !important;
}

.menu a:focus {
    color: #a01300 !important;
    outline: 1px solid rgb(17, 17, 17);
}


.menu .menu-title {
    margin-bottom: 1rem;
    padding: 0 1rem;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
}

.menu li {
    position: relative;
    line-height: 1.5;
}

.menu li a {
    font-size: 1.6rem;
    padding: 0.7rem 0 0.7rem 1rem;
    /* color: #e13019 !important; */
}

.menu li .megamenu,
.menu li>ul {
    position: absolute;
    top: -9999px;
    left: 100%;
    margin: 0;
    box-shadow: 0 2px 35px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    visibility: hidden;
    opacity: 0;
    transition: transform 0.2s ease-out;
    transform: translate3d(0, -10px, 0);
}

.menu li>ul {
    min-width: 22.6rem;
    padding: 2rem 0;
    background: #fff;
}

.menu li>ul .submenu>a:after {
    content: "";
    position: absolute;
    right: 22px;
    top: 50%;
    width: 7px;
    height: 7px;
    border: solid #666;
    border-width: 2px 2px 0 0;
    transform: translateY(-50%) rotate(45deg);
}

.menu li>ul li {
    padding: 0 2rem;
}

.menu li .megamenu a,
.menu li>ul a {
    font-weight: 400;
    color: #666;
}

.menu li .megamenu a:hover,
.menu li>ul a:hover {
    color: #0b80d2;
}

.menu .show .megamenu,
.menu .show>ul,
.menu li:hover .megamenu,
.menu li:hover>ul {
    visibility: visible;
    opacity: 1;
    top: -2rem;
    transform: translate3d(0, 0, 0);
}

.menu>li {
    margin-right: 2.3rem;
}

.menu>li:last-child {
    margin-right: 0;
}

.menu>li>a {
    position: relative;
    padding: 1.2rem 0 1.6rem;
    letter-spacing: inherit;
    line-height: 1;
    text-transform: uppercase;
    color: white;
}

.menu>li>a:hover {
    color: #a01300 !important;
    opacity: 1;
    top: 0;
}

/* .menu > li > a:focus  {
    color: #a01300 !important;
    border-left: 0 none !important;
    text-align: left !important;
    opacity: 1;
    top: 0;
} */


element.style {
    padding-inline: 60px;
    text-align: center;
    font-size: 50px;
    margin-top: 60px;
}

.menu>li .megamenu,
.menu>li>ul {
    left: -1.9rem;
}

.menu>li.show .megamenu,
.menu>li.show>ul,
.menu>li:hover .megamenu,
.menu>li:hover>ul {
    top: 100%;
}

.menu-active-underline>li>a:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    border-bottom: 2px solid;
    transform-origin: right center;
    transform: scale(0, 1);
    transition: transform 0.3s;
}

.menu-active-underline>li.active>a,
.menu-active-underline>li:hover>a {
    color: inherit;
}

.menu-active-underline>li.active>a:before,
.menu-active-underline>li:hover>a:before {
    transform-origin: left center;
    transform: scale(1, 1);
}

.megamenu {
    display: flex;
    padding: 1rem;
    min-width: 92rem;
    background: #fff;
}

.megamenu ul {
    padding: 0;
}

.megamenu .row {
    flex: 1 1;
    padding: 0 1rem;
}

.megamenu .row>* {
    padding: 1.8rem 1rem 0.8rem;
}

.megamenu .menu-banner {
    padding: 0;
    overflow: hidden;
}

.megamenu .menu-banner figure {
    height: 100%;
}

.megamenu .menu-banner img {
    height: 100%;
    object-fit: cover;
}

.megamenu .menu-banner .btn-link:hover {
    color: #0b80d2;
}

.menu-banner1 .banner-content {
    left: 9%;
}

.menu-banner1 .banner-subtitle,
.menu-banner1 .banner-title {
    font-size: 3.6rem;
}

.menu-banner1 .banner-title {
    margin-bottom: 1.8rem;
    padding-left: 1.2rem;
    position: relative;
}

.menu-banner1 .banner-title span {
    display: inline-block;
    position: absolute;
    left: -0.9rem;
    top: 50%;
    font-size: 1.2rem;
    line-height: 1;
    transform: rotateZ(-90deg) translateX(0.6rem);
    letter-spacing: -0.1em;
}

.menu-banner2 .banner-content {
    bottom: 10%;
}

.menu-banner2 .banner-title {
    margin-bottom: 0.6rem;
    font-size: 2.6rem;
}

.megamenu .menu-banner {
    padding: 0;
    overflow: hidden;
}

.megamenu .menu-banner figure {
    height: 100%;
}

.megamenu .menu-banner img {
    height: 100%;
    object-fit: cover;
}

.megamenu .menu-banner .btn-link:hover {
    color: #0b80d2;
}

.mobile-menu-wrapper {
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10000;
    font-family: "Open Sans", sans-serif;
    transition: visibility 0.4s;
}

.mobile-menu-container {
    max-width: 296px;
    padding: 2rem 1.5rem;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background: #222529;
    box-shadow: 1px 0 5px rgba(0, 0, 0, 0.5);
    transform: translateX(-296px);
    transition: transform 0.4s;
}

.mobile-menu-container .mobile-menu {
    margin-bottom: 0.5rem;
    background: #222529;
}

.mobile-menu-container.scrollable::-webkit-scrollbar-thumb,
.mobile-menu-container .sidebar-content::-webkit-scrollbar-thumb {
    margin-right: 2px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 7px;
    cursor: pointer;
}

.mobile-menu {
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: -0.025em;
    color: #e1e1e1;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: -0.025em;
}

.mobile-menu ul {
    display: none;
    width: 100%;
}

.mobile-menu>li:first-child {
    padding-top: 0.5rem;
}

.mobile-menu>li:last-child {
    padding-bottom: 0.5rem;
}

.mobile-menu li a {
    display: block;
    position: relative;
    padding: 1.3rem 0.6rem 1.3rem 1rem;
}

.mobile-menu li i {
    display: inline-block;
    margin-bottom: 1px;
    font-size: 2rem;
    margin-right: 0.5rem;
    line-height: 0;
    vertical-align: middle;
}

.mobile-menu li:not(:last-child) {
    border-bottom: 1px solid #2e3237;
}

.mobile-menu .active>a,
.mobile-menu .expanded {
    background: #2e3237;
}

.mobile-menu-overlay {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: #000;
    opacity: 0;
    transition: opacity 0.4s;
}

.mobile-menu-close {
    position: fixed;
    left: calc(100vw - 50px);
    top: 25px;
    transition: opacity 0.3s;
    opacity: 0;
}

.mobile-menu-close i {
    font-size: 2.8rem;
    color: #e1e1e1;
}

.mmenu-anim {
    transform: translateY(30%);
}

.mmenu-anim,
.mmenu-anim>li {
    transition: transform 0.4s, opacity 0.3s;
    transition-timing-function: cubic-bezier(0.5, 0, 0.3, 1);
}

.mmenu-anim>li:nth-child(0) {
    opacity: 0;
    transform: translateY(0px);
}

.mmenu-anim>li:first-child {
    opacity: 0;
    transform: translateY(50px);
}

.mmenu-anim>li:nth-child(2) {
    opacity: 0;
    transform: translateY(100px);
}

.mmenu-anim>li:nth-child(3) {
    opacity: 0;
    transform: translateY(150px);
}

.mmenu-anim>li:nth-child(4) {
    opacity: 0;
    transform: translateY(200px);
}

.mmenu-anim>li:nth-child(5) {
    opacity: 0;
    transform: translateY(250px);
}

.mmenu-anim>li:nth-child(6) {
    opacity: 0;
    transform: translateY(300px);
}

.mmenu-anim>li:nth-child(7) {
    opacity: 0;
    transform: translateY(350px);
}

.mmenu-active {
    overflow: hidden;
}

.mmenu-active .mmenu-anim,
.mmenu-active .mmenu-anim>li {
    opacity: 1;
    transform: translateY(0);
}

.mmenu-active .page-wrapper {
    margin-left: 296px;
    margin-right: -296px;
}

.mmenu-active .mobile-menu-wrapper {
    visibility: visible;
}

.mmenu-active .mobile-menu-container {
    transform: translateX(0);
}

.mmenu-active .mobile-menu-overlay {
    opacity: 0.8;
}

.mmenu-active .mobile-menu-close {
    opacity: 1;
}

@media (max-width: 400px) {
    .mobile-menu-close {
        left: calc(100vw - 40px);
        top: 10px;
    }
}

.appear-animate {
    visibility: hidden;
}

.intro-banner {
    min-height: 57.8rem !important;
}

.intro-banner .banner-subtitle .gift {
    margin-left: 0.8rem;
    margin-right: 0.6rem;
    font-size: 2.4em;
}

.intro-banner .banner-title {
    margin-bottom: 3.4rem;
    text-indent: -2px;
    line-height: 1.06;
    width: 650px;
    font-size: 32px !important;
}

.intro-banner .container {
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.intro-banner .banner-content {
    display: inline-block;
    padding-top: 100px;
    top: 50%;
    transform: translateY(-50%);
}

.intro-banner .btn-play {
    display: inline-flex;
    position: relative;
    align-items: center;
    justify-content: center;
    margin-right: 1.5rem;
    width: 7.8rem;
    height: 7.8rem;
    background: #222;
    border-radius: 50%;
    vertical-align: middle;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.intro-banner .btn-play svg {
    width: 50px;
    height: 50px;
    fill: #fff;
}

.intro-banner .custom-absolute-img1 {
    position: absolute;
    margin: 0;
    left: 52.7%;
    right: 0;
    bottom: 0;
    opacity: 0;
}

.intro-banner .custom-absolute-img1 img {
    min-height: 600px;
    object-fit: cover;
    object-position: left top;
    box-shadow: 0px 0px 35px 0px #0000003d;
    border-top-left-radius: 42px;
    border-right: 0px;
    border-bottom: 0px;
}

.intro-banner .custom-absolute-img2 {
    position: absolute;
    left: 57.38%;
    margin: 0;
    max-width: 40%;
    bottom: -14rem;
}

.intro-banner .custom-absolute-img2 img {
    position: relative;
    z-index: 1;
}

.intro-banner .custom-absolute-img2 figure {
    top: -50px;
}

.intro-banner .custom-absolute-img2 figure:before {
    content: "";
    position: absolute;
    left: 10px;
    right: 30px;
    top: 10px;
    bottom: 10px;
}

.intro-banner .mobile-content {
    position: absolute;
    left: 27%;
    right: 6.8%;
    top: 1.8%;
    bottom: 12%;
}

.intro-banner .mobile-image {
    position: relative;
    z-index: 1;
}

.intro-banner .video-desc {
    font-size: 1.6rem;
    line-height: 2.2rem;
    vertical-align: middle;
}

.intro-banner .video-desc span {
    font-size: 1.4rem;
}

.footer-bottom {
    padding: 3.35rem 0;
    border-top: 1px solid rgba(255, 255, 255, 0.05);
}

.footer-bottom p {
    font-size: 1.6rem;
    letter-spacing: -0.01em;
}

@media (min-width: 1366px) {
    .d-xxl-none {
        display: none !important;
    }

    .d-xxl-block {
        display: block !important;
    }
}

@media (min-width: 1200px) {
    .d-xl-inline-block {
        display: inline-block !important;
    }
}

.same-height .flip-card,
.same-height .icon-box {
    height: 100%;
}

.text-lg {
    font-size: 1.2em;
}

.header .divider {
    height: 62px;
    margin-right: 2.4rem;
    background: rgba(255, 255, 255, 0.3);
}

.header-middle.fixed {
    border-bottom-color: transparent;
}

.change-log {
    font-size: 1.4rem;
    margin-bottom: 4px;
}

a {
    text-decoration: none !important;
}

.main-features h2 span {
    margin-left: 1rem;
    color: #e13019 !important;
}

.bg-gradient {
    background: linear-gradient(to top left, #ff472b, #ff472b) !important;
}

.flip-card .flip-content {
    backface-visibility: hidden;
    perspective: inherit;
    transform: translate3d(0, 0, 60px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-features .icon-box {
    padding: 0px;
    box-shadow: 0 5px 50px 0 rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    height: 230px;
    background: #e13019 !important;
    width: 289px;
    display: flex;
}

.header .btn-purchase {
    display: inline-block;
    border: 0px solid #ffffff;
    outline: 0;
    margin-bottom: 4px;
    border-width: 1px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    background-color: #e13019;
    border-color: #ffffff;
    color: #ffffff !important;
    padding: 1em 2em;
    font-weight: 700;
    font-size: 1.4rem;
    font-family: Poppins, sans-serif;
    letter-spacing: -0.025em;
    line-height: 1.2;
    text-transform: uppercase;
    text-align: center;
    transition: color 0.3s, border-color 0.3s, background-color 0.3s, box-shadow 0.3s;
    white-space: nowrap;
    cursor: pointer;
}

.header .btn-purchase:hover {
    background-color: #ffffff;
    border-color: #ffffff;
    color: #d3402c !important;
}

.sticky-header.fixed .btn:hover {
    background-color: #0b80d2;
}

.change-log {
    font-size: inherit;
    letter-spacing: -0.02em;
    margin-bottom: 2px;
}

.radio-labels {
    font-size: 1.4rem;
    font-style: italic;
    color: white;
    font-size: medium;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.skip {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.skip:focus {
  position: static;
  width: auto;
  height: auto;
}

/* responsive ------------ */
@media screen and (max-width: 1268px) {
    .child2FormQuota {
        width: 100vw;
        /* margin-top: -700px; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: -57vw;
        align-items: center;
        position: relative;
        top: 420px;
    }

    .child2FormQuota .subChild1 {
        display: flex;
        flex-direction: column;
        background-color: #181634;
        color: white;
        padding: 40px;
        border-radius: 10px;
        position: relative;
        top: 150px;
        width: 92%;
        box-shadow: 0px 0px 28px 0px #000000c2;
        z-index: 2;
        margin-top: -90px;
        margin-left: 0px;
    }

    .intro-banner .container {
        padding-top: 5rem;
        padding-bottom: 5rem;
        display: flex;
        justify-content: center;
    }

    .sliderHome {
        height: 700px;
    }

    .sliderHome img {
        width: 100%;
        height: 590px;
    }

    .section2 {
        margin-top: 560px;
    }

    .row .mb-5 {
        display: flex;
        justify-content: center;
    }

    .main-features h2 {
        text-align: center !important;
    }
}

@media screen and (max-width: 698px) {
    .banner-title {
        width: 470px !important;
        margin: 0 auto;
    }

    .page-content .banner-content .child1 div button {
        width: 467px !important;
    }

    .child2FormQuota .subChild1 {
        margin-top: 50px;
    }

    .page-content {
        margin-top: -668px !important;
        position: relative;
    }

    .child1 .subChild1ContentBtn {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80%;
    }

    .page-content .banner-content .child1 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 124%;
    }

    .page-content .banner-content .child1 div input {
        font-size: 20px;
        width: 100%;
        padding: 16px;
        border-radius: 6px;
        border: 0px;
        margin-right: 0px;
    }

    .page-content .banner-content .child1 div button {
        padding: 12px;
        border: 0px;
        border-radius: 4px;
        cursor: pointer;
        transition: 0.2s;
        font-weight: bold;
        font-size: 21px;
        margin-top: 15px;
        width: 100%;
    }
}

@media screen and (max-width: 530px) {
    .intro-banner .banner-title {
        font-size: 16px !important;
        width: 280px !important;
    }

    .page-content .banner-content .child1 div button {
        width: 317px !important;
    }

    .intro-banner .banner-subtitle {
        font-size: 2em;
        width: 320px;
        margin: 0 auto;
    }

    .sliderHome li {
        width: 100%;
        list-style: none;
        overflow: hidden;
    }

    .intro-banner .banner-content {
        transform: none;
        margin-top: -40px;
    }

    .content3 p {
        padding-inline: 3%;
        text-align: center;
        margin-top: 30px;
    }

    .content4 p {
        font-size: 22px;
        text-align: center;
        width: 92%;
        margin: 0 auto;
    }

    .content3 h1 {
        padding-inline: 60px;
        text-align: center;
        font-size: 37px;
        margin-top: 90px;
    }

    .sliderHome img {
        min-width: 1340px;
        /* height: 440px; */
        height: 800px;
    }

    .child2FormQuota .subChild1 {
        display: flex;
        flex-direction: column;
        background-color: #181634;
        color: white;
        padding: 40px;
        border-radius: 10px;
        position: relative;
        top: 185px;
        width: 92%;
        box-shadow: 0px 0px 28px 0px #000000c2;
        z-index: 2;
        margin-top: -90px;
        margin-left: 0px;
    }

    .section2 {
        margin-top: 370px;
    }

    .page-content {
        margin-top: -638px !important;
        position: relative;
    }
}

.header-leftTextChange {
    padding-left: 40px;
    font-size: 14px;
    text-align: left;
    padding-bottom: 40px;
}

@media screen and (max-width: 990px) {
    .menuHeaderAll {
        display: none !important;
    }

    .MenuMovilContent {
        display: flex;
    }

    .d-none {
        display: flex !important;
    }

    .main-nav {
        display: flex !important;
        flex-direction: column;
        align-items: center;
    }

    .header .container,
    .header .container-fluid,
    .header .inner-wrap {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .header .btn-purchase {
        display: flex !important;
    }

    .pts1 {
        padding-top: 0;
    }
}

@media screen and (max-width: 558px) {
    .menu {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .header-leftTextChange {
        padding-left: 0px !important;
        font-size: 14px;
        text-align: center;
        padding-bottom: 40px;
    }

    .main-nav .menu>li {
        margin: 0px !important;
    }
}

@media screen and (max-width: 778px) {

    .header .divider,
    .header .main-nav {
        display: flex !important;
    }

    .subsection4Other {
        text-align: center;
        padding: 20px;
    }
}

@media screen and (max-width: 844px) {
    .ContentAllFSC {
        display: flex;
        flex-direction: column;
    }

    .LineCardText {
        width: 100%;
        text-align: center;
        margin-top: 40px;
        font-size: 30px;
        font-weight: bold;
    }

    .ContentAllFSC .FSCHome {
        width: 100%;
        max-width: none;
    }
}

@media screen and (max-width: 1100px) {
    .page-content {
        margin-top: -578px;
        position: relative;
    }
}
@charset "UTF-8";
.owl-carousel .owl-item img {
    height: auto;
}
.bg-gradient2 {
    background: linear-gradient(to top left, #ffffff, #ffffff) !important;
}
.text-orange {
    color: #ff472b !important;

}
.owl-loaded .owl-stage:after {
    content: "";
}
.owl-carousel:not(.owl-loaded) {
    flex-wrap: nowrap;
    overflow: hidden;
}
.owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 1.5rem;
}
.owl-theme .owl-dots .owl-dot.active span {
    background-color: #0b80d2;
    border-color: #0b80d2;
}
.owl-theme .owl-dots .owl-dot span {
    margin: 5px;
}
.owl-dot-white .owl-dots .owl-dot span {
    background-color: #fff;
    border-color: #fff;
    opacity: 0.8;
}
.owl-dot-white .owl-dots .owl-dot.active span {
    background-color: #fff;
    border-color: #fff;
    opacity: 1;
}
*,
:after,
:before {
    box-sizing: inherit;
}
html {
    font-size: 62.5%;
    font-size-adjust: 100%;
    font-weight: 400;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body {
    margin: 0;
    overflow-x: hidden;
    font-family: Poppins, sans-serif;
    font-size: 1.4rem;
    line-height: 1.6;
    color: #666;
}
main {
    display: block;
    position: relative;
}
.page-wrapper {
    position: relative;
    transition: margin 0.4s;
}
.grey-section {
    background: #f3f6f9;
}
ul {
    padding-left: 1.5em;
}
.breadcrumb,
.category ul,
.comments ul,
.dropdown-box,
.filter-items,
.list,
.menu,
.menu ul,
.mobile-menu,
.mobile-menu ul,
.nav,
.nav-filters,
.nav ul,
.pagination,
.product-nav,
.product-tabs > div ul,
.select-menu > ul,
.widget-body,
.widget-body ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
a {
    text-decoration: none;
    color: inherit;
    transition: color 0.3s;
}
a:hover {
    color: #0b80d2;
}
:focus {
    outline: 0;
}
img {
    max-width: 100%;
    height: auto;
}
i {
    font-style: normal;
}
button:focus {
    outline: none;
}
.scrollable,
.sidebar-content {
    -webkit-overflow-scrolling: touch;
}
.scrollable::-webkit-scrollbar,
.sidebar-content::-webkit-scrollbar {
    height: 7px;
    width: 4px;
}
.scrollable::-webkit-scrollbar-thumb,
.sidebar-content::-webkit-scrollbar-thumb {
    margin-right: 2px;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    cursor: pointer;
}
.appear-animate {
    transform: translate3d(0, 0, 0) scale(1);
    will-change: transform, filter, opacity;
}
.scroll-top {
    position: fixed;
    right: 15px;
    bottom: 0;
    width: 40px;
    height: 40px;
    font-size: 16px;
    color: #fff;
    text-align: center;
    line-height: 40px;
    visibility: hidden;
    opacity: 0;
    transition: transform 0.3s, visibility 0.3s, opacity 0.3s;
    transform: translateY(40px);
    z-index: 9999;
    background-color: #43494e;
}
.scroll-top:hover {
    color: #fff;
}
@media (min-width: 768px) {
    .scroll-top.show {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
    }
}
@keyframes fixedTop {
    0% {
        transform: translateY(-100%);
        transform-origin: center top 0px;
    }
    to {
        transform: translateY(0);
    }
}
.sticky-content.fix-top {
    top: 0;
}
.sticky-content.fixed {
    position: fixed;
    left: 0;
    right: 0;
    opacity: 1;
    transform: translateY(0);
    background: #fff;
    z-index: 1051;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
}
.sticky-content.fixed.fix-top {
    animation: fixedTop 0.4s;
}
.w-100 {
    width: 100% !important;
}
.x-50 {
    left: 50% !important;
    transform: translateX(-50%) !important;
}
.x-50.y-50 {
    transform: translate(-50%, -50%) !important;
}
.y-50 {
    top: 50% !important;
    transform: translateY(-50%) !important;
}
.d-none {
    display: none !important;
}
.d-block {
    display: block !important;
}
.d-inline-block {
    display: inline-block !important;
}
.d-flex {
    display: flex !important;
}
.justify-content-center {
    justify-content: center !important;
}
.align-items-center {
    align-items: center !important;
}
.flex-wrap {
    flex-wrap: wrap !important;
}
.overflow-hidden {
    overflow: hidden !important;
}
@media (min-width: 992px) {
    .d-lg-none {
        display: none !important;
    }
    .d-lg-block {
        display: block !important;
    }
}
.font-primary {
    font-family: Poppins, sans-serif !important;
}
.font-secondary {
    font-family: "Open Sans", sans-serif !important;
}
.font-tertiary {
    font-family: "Segoe Script", "Savoye LET", sans-serif !important;
}
.font-weight-bold {
    font-weight: 700 !important;
}
.font-weight-semi-bold {
    font-weight: 600 !important;
}
.font-weight-normal {
    font-weight: 400 !important;
}
.text-uppercase {
    text-transform: uppercase !important;
}
.text-none {
    text-transform: none !important;
}
.text-center {
    text-align: center !important;
}
.text-left {
    text-align: left !important;
}
.text-right {
    text-align: right !important;
}
.text-white {
    color: #fff !important;
}
.text-grey {
    color: #999 !important;
}
.text-body {
    color: #666 !important;
}
.text-dark {
    color: #222 !important;
}
.text-primary {
    color: #ff472b !important;
}
.ls-s {
    letter-spacing: -0.01em !important;
}
.ls-m {
    letter-spacing: -0.025em !important;
}
.ls-l {
    letter-spacing: -0.05em !important;
}
.lh-1 {
    line-height: 1 !important;
}
.bg-white {
    background-color: #fff !important;
}
.bg-dark {
    background-color: #222 !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
    font-weight: 400;
    margin: 0 0 2rem;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: Poppins, sans-serif;
    font-weight: 600;
    line-height: 1.4;
    color: #222;
}
h1 {
    font-size: 4rem;
}
h2 {
    font-size: 3.4rem;
}
h3 {
    font-size: 3rem;
}
h4 {
    font-size: 2.4rem;
}
h5 {
    font-size: 1.8rem;
}
h6 {
    font-size: 1.5rem;
}
p {
    font-family: "Open Sans", sans-serif;
    font-size: 1.4rem;
    line-height: 1.86;
}
.list {
    margin-left: 2rem;
    font-size: 1.4rem;
    line-height: 1.86;
    color: #666;
}
.container,
.container-fluid {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
}

.container-fluid{
    /* overflow-x: auto; */
}

@media (max-width: 479px) {
    .container,
    .container-fluid {
        padding-left: 15px;
        padding-right: 15px;
    }
}
.container-fluid {
    max-width: 1800px;
}
.container {
    max-width: 1220px;
}
.divider {
    display: inline-block;
    vertical-align: middle;
    margin-right: 1.4rem;
    height: 1.9rem;
    width: 1px;
    background-color: #e1e1e1;
}
.row {
    display: flex;
    flex-wrap: wrap;
}
.row > * {
    position: relative;
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
}
.gutter-lg {
    margin-left: -15px;
    margin-right: -15px;
}
.gutter-lg > * {
    padding-right: 15px;
    padding-left: 15px;
}
.mb-0 {
    margin-bottom: 0rem !important;
}
.mb-1 {
    margin-bottom: 0.5rem !important;
}
.pt-1 {
    padding-top: 0.5rem !important;
}
.pb-1 {
    padding-bottom: 0.5rem !important;
}
.mb-2 {
    margin-bottom: 1rem !important;
}
.pt-2 {
    padding-top: 1rem !important;
}
.pb-2 {
    padding-bottom: 1rem !important;
}
.mb-3 {
    margin-bottom: 1.5rem !important;
}
.pb-3 {
    padding-bottom: 1.5rem !important;
}
.mt-4 {
    margin-top: 2rem !important;
}
.mb-4 {
    margin-bottom: 2rem !important;
}
.pb-4 {
    padding-bottom: 2rem !important;
}
.mt-5 {
    margin-top: 2.5rem !important;
}
.mb-5 {
    margin-bottom: 2.5rem !important;
}
.pt-5 {
    padding-top: 2.5rem !important;
}
.mb-6 {
    margin-bottom: 3rem !important;
}
.mt-7 {
    margin-top: 3.5rem !important;
}
.mb-7 {
    margin-bottom: 3.5rem !important;
}
.pt-7 {
    padding-top: 3.5rem !important;
}
.mb-8 {
    margin-bottom: 4rem !important;
}
.pt-8 {
    padding-top: 4rem !important;
}
.mb-9 {
    margin-bottom: 4.5rem !important;
}
.pt-9 {
    padding-top: 4.5rem !important;
}
.pb-9 {
    padding-bottom: 4.5rem !important;
}
.mt-10 {
    margin-top: 5rem !important;
}
.mb-10 {
    margin-bottom: 5rem !important;
}
.pt-10 {
    padding-top: 5rem !important;
}
.pb-10 {
    padding-bottom: 5rem !important;
}
.ml-0 {
    margin-left: 0rem !important;
}
.cols-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
}
@media (min-width: 480px) {
    .cols-xs-2 > * {
        max-width: 50%;
        flex: 0 0 50%;
    }
}
@media (min-width: 576px) {
    .cols-sm-3 > * {
        max-width: 33.3333%;
        flex: 0 0 33.3333%;
    }
}
@media (min-width: 768px) {
    .cols-md-2 > * {
        max-width: 50%;
        flex: 0 0 50%;
    }
    .cols-md-3 > * {
        max-width: 33.3333%;
        flex: 0 0 33.3333%;
    }
    .cols-md-4 > * {
        max-width: 25%;
        flex: 0 0 25%;
    }
}
@media (min-width: 992px) {
    .cols-lg-3 > * {
        max-width: 33.3333%;
        flex: 0 0 33.3333%;
    }
    .cols-lg-4 > * {
        max-width: 25%;
        flex: 0 0 25%;
    }
    .cols-lg-5 > * {
        max-width: 20%;
        flex: 0 0 20%;
    }
    .pt-lg-0 {
        padding-top: 0rem !important;
    }
    .pt-lg-10 {
        padding-top: 5rem !important;
    }
    .pb-lg-10 {
        padding-bottom: 5rem !important;
    }
    .ml-lg-0 {
        margin-left: 0rem !important;
    }
}
@media (min-width: 1200px) {
    .cols-xl-5 > * {
        max-width: 20%;
        flex: 0 0 20%;
    }
}
@media (min-width: 576px) {
    .col-sm-6 {
        max-width: 50%;
        flex: 0 0 50%;
    }
}
@media (min-width: 768px) {
    .col-md-5 {
        max-width: 41.6667%;
        flex: 0 0 41.6667%;
    }
    .col-md-6 {
        max-width: 50%;
        flex: 0 0 50%;
    }
    .col-md-7 {
        max-width: 58.3333%;
        flex: 0 0 58.3333%;
    }
}
@media (min-width: 992px) {
    .col-lg-3 {
        max-width: 25%;
        flex: 0 0 25%;
    }
    .col-lg-4 {
        max-width: 33.3333%;
        flex: 0 0 33.3333%;
    }
    .col-lg-5 {
        max-width: 41.6667%;
        flex: 0 0 41.6667%;
    }
    .col-lg-6 {
        max-width: 50%;
        flex: 0 0 50%;
    }
}
@media (min-width: 1200px) {
    .col-xl-3 {
        max-width: 25%;
        flex: 0 0 25%;
    }
    .col-xl-6 {
        max-width: 50%;
        flex: 0 0 50%;
    }
}
@media (min-width: 992px) {
    .order-lg-first {
        order: -1;
    }
}
.grid {
    margin: -1rem;
}
.grid img {
    object-fit: cover;
}
.grid .grid-item {
    padding: 1rem;
    transform: translate3d(0, 0, 0);
}
@keyframes fadeInUpShorter {
    0% {
        opacity: 0;
        transform: translate(0, 50px);
        transform-origin: 0 0;
    }
    to {
        opacity: 1;
        transform: none;
    }
}
.fadeInUpShorter {
    animation-name: fadeInUpShorter;
}
@keyframes fadeInLeftShorter {
    0% {
        opacity: 0;
        transform: translate(50px, 0);
        transform-origin: 0 0;
    }
    to {
        opacity: 1;
        transform: none;
    }
}
.fadeInLeftShorter {
    animation-name: fadeInLeftShorter;
}
@keyframes fadeInRightShorter {
    0% {
        opacity: 0;
        transform: translate(-50px, 0);
        transform-origin: 0 0;
    }
    to {
        opacity: 1;
        transform: none;
    }
}
.fadeInRightShorter {
    animation-name: fadeInRightShorter;
}
@keyframes blurIn {
    0% {
        opacity: 0;
        filter: blur(20px);
        transform: scale(1.2);
    }
    to {
        opacity: 1;
        filter: blur(0);
        transform: none;
    }
}
.blurIn {
    animation-name: blurIn;
}
.appear-animate {
    visibility: hidden;
}
.appear-animation-visible {
    visibility: visible;
}
.banner {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    font-size: 1rem;
}
.banner figure img {
    display: block;
    width: 100%;
    height: auto;
}
.banner .banner-content {
    position: relative;
}
.banner-subtitle {
    letter-spacing: -0.025em;
    line-height: 1;
}
.banner-title {
    line-height: 1;
}
.banner-fixed > .banner-content,
.banner-fixed > .container,
.banner-fixed > .container-fluid {
    position: absolute;
    z-index: 1;
}
.banner-fixed > .container,
.banner-fixed > .container-fluid {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
.video-banner {
    position: relative;
}
.video-banner .btn-play {
    display: inline-block;
    border-radius: 50%;
    transition: background-color 0.3s, border-color 0.3s;
    border: 2px solid;
    line-height: 63px;
    width: 65px;
    height: 65px;
    font-size: 22px;
}
.video-banner .btn-play:hover {
    background-color: #0b80d2;
    border-color: #0b80d2;
}
.btn {
    display: inline-block;
    border: 2px solid #e4eaec;
    background-color: #e4eaec;
    color: #222;
    outline: 0;
    border-radius: 0;
    padding: 1em 2em;
    font-weight: 700;
    font-size: 1.4rem;
    font-family: Poppins, sans-serif;
    letter-spacing: -0.025em;
    line-height: 1.2;
    text-transform: uppercase;
    text-align: center;
    transition: color 0.3s, border-color 0.3s, background-color 0.3s, box-shadow 0.3s;
    white-space: nowrap;
    cursor: pointer;
}
.btn:active,
.btn:focus,
.btn:hover {
    color: #222;
    border-color: #cfdadd;
    background-color: #cfdadd;
}
.btn i {
    display: inline-block;
    margin-left: 0.4rem;
    line-height: 0;
}
.btn i:before {
    margin: 0;
}
.btn-solid {
    background-color: #fff;
    border-color: #fff;
    color: #222;
}
.btn-solid:active,
.btn-solid:focus,
.btn-solid:hover {
    border-color: #222;
    background-color: #222;
    color: #fff;
}
.btn.btn-rounded {
    border-radius: 3px;
}
.btn.btn-rounded:after,
.btn.btn-rounded:before {
    border-radius: 3px;
}
.btn.btn-block {
    display: block;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
}
.btn.btn-lg {
    padding: 1.22em 2.69em;
}
.btn-link {
    padding: 0;
    color: #222;
    border: 0;
    background-color: transparent;
}
.btn-link:active,
.btn-link:focus,
.btn-link:hover {
    background-color: transparent;
}
.btn-underline:active:after,
.btn-underline:focus:after,
.btn-underline:hover:after {
    transform: scaleX(1);
}
.btn-underline:after {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1px;
    width: 100%;
    border-bottom: 2px solid;
    transform: scaleX(0);
    transition: transform 0.3s;
    content: "";
}
.btn-underline i {
    margin-bottom: 2px;
}
.btn-underline.btn-underline-visible:after {
    transform: scaleX(1);
}
.btn-underline.btn-underline-width-sm:after {
    height: 1px;
    border-bottom: 0;
}
.btn-dark {
    color: #fff;
    border-color: #222;
    background-color: #222;
}
.btn-dark:active,
.btn-dark:focus,
.btn-dark:hover {
    color: #fff;
    border-color: #343434;
    background-color: #343434;
}
.btn-dark.btn-solid {
    color: #222;
    border-color: #fff;
    background-color: #fff;
}
.btn-dark.btn-solid:active,
.btn-dark.btn-solid:focus,
.btn-dark.btn-solid:hover {
    border-color: #222;
    background-color: #222;
    color: #fff;
}
.btn-dark.btn-outline {
    color: #222;
    border-color: #222;
    background-color: transparent;
}
.btn-dark.btn-outline:active,
.btn-dark.btn-outline:focus,
.btn-dark.btn-outline:hover {
    background-color: #222;
    color: #fff;
}
.btn-dark.btn-link {
    background-color: transparent;
    color: #222;
}
.btn-dark.btn-link:active,
.btn-dark.btn-link:focus,
.btn-dark.btn-link:hover {
    color: #222;
}
.btn-dark.btn-underline:active,
.btn-dark.btn-underline:focus,
.btn-dark.btn-underline:hover {
    color: #222;
}
.elements > div {
    margin-bottom: 2rem;
}
.elements > div > a {
    display: block;
}
.element {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.2rem 0 2.4rem;
    border: 2px solid #e4eaec;
}
.element p {
    margin: 0;
    text-align: center;
    color: #222;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.2;
    text-transform: uppercase;
}
.icon-box .icon-box-title {
    margin: 0 0 0.7rem;
    text-transform: uppercase;
    font-size: 1.5rem;
    letter-spacing: -0.025em;
    line-height: 1.6;
}
.icon-box p {
    margin: 0;
    font-size: 1.4rem;
    line-height: 1.72;
    letter-spacing: 0;
    color: #666;
}
.icon-box i:before {
    margin: 0;
}
.icon-box .icon-box-content {
    text-align: center;
}
.icon-box-icon {
    display: inline-block;
    margin-bottom: 2.4rem;
    font-size: 3.5rem;
    font-weight: 600;
    line-height: 0;
    color: #222;
}
.mfp-wrap {
    z-index: 9999;
}
.mfp-bg {
    z-index: 9999;
    background: #000;
    opacity: 0;
    transition: opacity 0.3s ease-out;
}
.mfp-bg.mfp-ready {
    opacity: 0.7;
}
.mfp-bg.mfp-removing {
    opacity: 0;
}
.mfp-container {
    padding: 4rem 2rem;
}
.mfp-close {
    transform: rotateZ(45deg);
    transition: opacity 0.3s;
    width: 18px;
    height: 18px;
}
.mfp-wrap .mfp-close {
    top: 20px;
    right: 20px;
}
.mfp-content .mfp-close {
    top: -25px;
    right: 0;
}
.mfp-iframe-holder .mfp-close,
.mfp-image-holder .mfp-close {
    top: 15px;
    right: 0;
}
.mfp-close span {
    display: none;
}
.mfp-close:after,
.mfp-close:before {
    content: "";
    display: block;
    position: absolute;
    background: #ccc;
    width: 2px;
    height: 100%;
    top: 0;
    left: calc(50% - 1px);
}
.mfp-close:before {
    width: 100%;
    height: 2px;
    top: calc(50% - 1px);
    left: 0;
}
.mfp-arrow,
.pswp__button--arrow--left,
.pswp__button--arrow--right {
    width: 4.8rem;
    height: 4.8rem;
    color: #fff;
    border-radius: 50%;
    font-size: 1.6rem;
    font-weight: 900;
    font-family: "Font Awesome 5 Free";
    text-align: center;
    opacity: 0.5;
    right: 10%;
    transition: opacity 0.3s;
    border: 2px solid;
}
.pswp__ui > button:before {
    background: transparent;
    position: static;
    line-height: 44px;
}
.pswp__ui > button:hover {
    opacity: 1;
}
.pswp__button--arrow--right:before {
    content: "";
}
.mfp-arrow:after,
.mfp-arrow:before {
    content: "";
    position: static;
    display: inline;
    margin: 0;
    border: 0;
}
.mfp-arrow:after {
    content: none;
}
button.mfp-arrow {
    border: 2px solid;
}
.mfp-arrow-left,
.pswp__button--arrow--left {
    left: 10%;
}
.mfp-arrow-left:before,
.pswp__button--arrow--left:before {
    content: "";
}
.mfp-content > * {
    position: relative;
    margin: auto;
    background: #fff;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
    opacity: 0;
}
.mfp-ready .mfp-content > * {
    opacity: 1;
}
.mfp-removing .mfp-content > * {
    opacity: 0;
}
.login-popup {
    max-width: 500px;
    padding: 3.5rem 5rem 4.8rem;
}
.login-popup .nav-link {
    padding: 0 0 1px;
    color: #222;
    font-size: 1.6rem;
    font-weight: 700;
    letter-spacing: -0.025em;
    line-height: 2.43;
}
.login-popup .nav-item {
    margin-bottom: -1px;
}
.login-popup .nav-item:not(:last-child) {
    margin-right: 1px;
}
.login-popup .nav-item .nav-link.active,
.login-popup .nav-item.show .nav-link {
    color: #222;
    border-color: #0b80d2;
}
.login-popup .tab-pane {
    padding-top: 2.8rem;
    padding-bottom: 0;
}
.login-popup form {
    margin-bottom: 2.3rem;
}
.login-popup .form-group {
    margin-bottom: 1.8rem;
}
.login-popup .form-group label {
    display: inline-block;
    margin-bottom: 8px;
    font-size: 1.3rem;
    font-family: "Open Sans", sans-serif;
    line-height: 1;
}
.login-popup .form-group .form-control {
    height: 3.7rem;
}
.login-popup .form-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.5rem;
    font-size: 1.2rem;
}
.login-popup .btn {
    height: 3.9rem;
    padding: 0;
    font-size: 1.3rem;
}
.login-popup .lost-link {
    text-align: right;
    color: #d26e4b;
}
.login-popup .lost-link:hover {
    text-decoration: underline;
}
.login-popup .form-choice label {
    display: block;
    margin-bottom: 0.3rem;
    font-size: 1.2rem;
    color: #999;
}
.newsletter-popup {
    display: flex;
    max-width: 70rem;
    text-align: center;
    background-position: 60% center;
    background-size: cover;
}
.newsletter-popup h4 {
    margin-bottom: 0.9rem;
    font-size: 1.6rem;
}
.newsletter-popup h2 {
    margin-bottom: 1.2rem;
    font-size: 2.8rem;
    line-height: 1.07;
    letter-spacing: -0.025em;
}
.newsletter-popup b {
    display: block;
    margin-top: 0.3rem;
    color: #222;
    font-size: 3rem;
    letter-spacing: 0.025em;
}
.newsletter-popup .btn {
    min-width: auto;
    padding: 0.5em 1.7em;
    font-size: 1.3rem;
}
.newsletter-popup p {
    max-width: 100%;
    margin-bottom: 1.8rem;
    padding: 0 0.5rem;
    font-size: 1.3rem;
    line-height: 2rem;
}
.newsletter-popup label {
    color: #999;
    font-size: 1.2rem;
}
.newsletter-popup .email {
    border: 0;
    color: #999;
    background: #f4f4f4;
}
.newsletter-popup .form-control {
    border: 1px solid #ccc;
    background: #fff;
    border-right: none;
}
.newsletter-content {
    margin-left: auto;
    max-width: 38.8rem;
    padding: 6.6rem 4.2rem 6.4rem;
}
.mfp-product .mfp-container {
    padding: 4rem 3rem 2rem;
}
.mfp-product .mfp-content {
    max-width: 92.3rem;
    margin: auto;
}
.mfp-product .product {
    background-color: #fff;
    padding: 3rem 2rem;
}
.mfp-product .mfp-close {
    position: absolute;
    top: -2.7rem;
    right: 0;
}
.pswp__bg {
    background-color: rgba(0, 0, 0, 0.7);
}
.pswp__img--placeholder--blank {
    background-color: #f2f3f5;
}
.pswp__ui--fit .pswp__caption,
.pswp__ui--fit .pswp__top-bar {
    background-color: transparent;
}
.pswp__caption__center {
    text-align: center;
}
.mfp-ready.mfp-zoom-popup .mfp-content {
    transform: scale(1);
}
.mfp-removing.mfp-zoom-popup .mfp-content,
.mfp-zoom-popup .mfp-content {
    transition: 0.2s ease-in-out;
    transform: scale(0.8);
}
.mfp-ready.mfp-flip-popup .mfp-content {
    transform: translateY(0) perspective(600px) rotateX(0);
}
.mfp-flip-popup .mfp-content,
.mfp-removing.mfp-flip-popup .mfp-content {
    transition: 0.3s ease-out;
    transform: translateY(-20px) perspective(600px) rotateX(20deg);
}
@media (max-width: 767px) {
    .mfp-product .product {
        padding: 2rem 1rem;
    }
    .mfp-arrow {
        color: #444;
    }
}
@media (min-width: 768px) {
    .mfp-product .product-gallery {
        margin-bottom: 0;
    }
    .mfp-product div.product-details {
        overflow-y: auto;
        padding-bottom: 0;
    }
}
@media (max-width: 767px) {
    .mfp-product div.product-details {
        margin-top: 2rem;
    }
    .newsletter-content {
        max-width: 36rem;
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .login-popup {
        max-width: 500px;
        padding: 3.5rem 2rem;
    }
}
.header {
    letter-spacing: -0.01em;
    background: #fff;
    font-size: 1.4rem;
}
.header .container,
.header .container-fluid,
.header .inner-wrap {
    display: flex;
    align-items: center;
}
.header .inner-wrap {
    width: 100%;
}
.header-transparent {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 20;
    background: transparent;
}
.header-transparent .fixed {
    background: #222;
}
.header-bottom,
.header-middle,
.header-top {
    display: flex;
}
.has-center .header-left,
.has-center .header-right {
    flex: 1 1;
}
.has-center .header-right {
    justify-content: flex-end;
}
.has-center .header-center {
    margin-left: 2rem;
    margin-right: 2rem;
}
.header-center,
.header-left,
.header-right {
    display: flex;
    align-items: center;
}
.header-right {
    margin-left: auto;
}
.header-middle {
    padding-top: 2rem;
    padding-bottom: 1.7rem;
    color: #fff;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    font-weight: 600;
}
.header-middle .logo {
    margin-right: 3rem;
}
.header-middle .main-nav {
    margin-right: 0;
}
.header-middle .header-right .cart-dropdown {
    margin-right: 0 !important;
}
.logo {
    max-width: 200px;
    margin-bottom: 0.3rem;
}
.logo img {
    display: block;
}
.sticky-header.fixed {
    position: fixed;
    top: 0;
    width: 100%;
    padding-top: 1.3rem;
    padding-bottom: 1.3rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1100;
    animation: fixedTop 0.4s;
}
@media (max-width: 991px) {
    .sticky-wrapper {
        height: auto !important;
    }
}
.mobile-menu-toggle {
    display: none;
    width: 24px;
    height: 16px;
    color: #0b80d2;
}
.mobile-menu-toggle svg {
    stroke: #222;
    margin: -17px -13px;
    width: 50px;
    height: 50px;
}
@media (max-width: 1199px) {
    .main-nav .menu > li {
        margin-right: 1.5rem;
    }
    .header-middle .logo {
        margin-right: 2rem;
    }
    .header-middle .divider {
        display: none;
    }
}
@media (max-width: 991px) {
    .header .divider,
    .header .main-nav {
        display: none;
    }
    .header-middle .header-left,
    .header-middle .header-right {
        flex: auto;
    }
    .header-middle .header-right {
        justify-content: flex-end;
    }
    .header-middle .header-center {
        margin-left: auto;
        margin-right: auto;
    }
    .header-middle .logo {
        margin-right: 0;
    }
    .mobile-menu-toggle {
        display: block;
        margin-right: 1.5rem;
    }
    .mobile-menu-toggle svg {
        stroke: #0b80d2;
    }
}
.menu {
    display: flex;
    align-items: center;
}
.menu a {
    display: inline-block;
}
.menu .menu-title {
    margin-bottom: 1rem;
    padding: 0 1rem;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
}
.menu li {
    position: relative;
    line-height: 1.5;
}
.menu li a {
    padding: 0.7rem 0 0.7rem 1rem;
}
.menu li .megamenu,
.menu li > ul {
    position: absolute;
    top: -9999px;
    left: 100%;
    margin: 0;
    box-shadow: 0 2px 35px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    visibility: hidden;
    opacity: 0;
    transition: transform 0.2s ease-out;
    transform: translate3d(0, -10px, 0);
}
.menu li > ul {
    min-width: 22.6rem;
    padding: 2rem 0;
    background: #fff;
}
.menu li > ul .submenu > a:after {
    content: "";
    position: absolute;
    right: 22px;
    top: 50%;
    width: 7px;
    height: 7px;
    border: solid #666;
    border-width: 2px 2px 0 0;
    transform: translateY(-50%) rotate(45deg);
}
.menu li > ul li {
    padding: 0 2rem;
}
.menu li .megamenu a,
.menu li > ul a {
    font-weight: 400;
    color: #666;
}
.menu li .megamenu a:hover,
.menu li > ul a:hover {
    color: #0b80d2;
}
.menu .show .megamenu,
.menu .show > ul,
.menu li:hover .megamenu,
.menu li:hover > ul {
    visibility: visible;
    opacity: 1;
    top: -2rem;
    transform: translate3d(0, 0, 0);
}
.menu > li {
    margin-right: 2.3rem;
}
.menu > li:last-child {
    margin-right: 0;
}
.menu > li > a {
    position: relative;
    padding: 2.2rem 0 2.6rem;
    letter-spacing: inherit;
    line-height: 1;
    text-transform: uppercase;
}
.menu > li .megamenu,
.menu > li > ul {
    left: -1.9rem;
}
.menu > li.show .megamenu,
.menu > li.show > ul,
.menu > li:hover .megamenu,
.menu > li:hover > ul {
    top: 100%;
}
.menu-active-underline > li > a:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    border-bottom: 2px solid;
    transform-origin: right center;
    transform: scale(0, 1);
    transition: transform 0.3s;
}
.menu-active-underline > li.active > a,
.menu-active-underline > li:hover > a {
    color: inherit;
}
.menu-active-underline > li.active > a:before,
.menu-active-underline > li:hover > a:before {
    transform-origin: left center;
    transform: scale(1, 1);
}
.megamenu {
    display: flex;
    padding: 1rem;
    min-width: 92rem;
    background: #fff;
}
.megamenu ul {
    padding: 0;
}
.megamenu .row {
    flex: 1 1;
    padding: 0 1rem;
}
.megamenu .row > * {
    padding: 1.8rem 1rem 0.8rem;
}
.megamenu .menu-banner {
    padding: 0;
    overflow: hidden;
}
.megamenu .menu-banner figure {
    height: 100%;
}
.megamenu .menu-banner img {
    height: 100%;
    object-fit: cover;
}
.megamenu .menu-banner .btn-link:hover {
    color: #0b80d2;
}
.menu-banner1 .banner-content {
    left: 9%;
}
.menu-banner1 .banner-subtitle,
.menu-banner1 .banner-title {
    font-size: 3.6rem;
}
.menu-banner1 .banner-subtitle {
    margin-bottom: 0.4rem;
}
.menu-banner1 .banner-title {
    margin-bottom: 1.8rem;
    padding-left: 1.2rem;
    position: relative;
}
.menu-banner1 .banner-title span {
    display: inline-block;
    position: absolute;
    left: -0.9rem;
    top: 50%;
    font-size: 1.2rem;
    line-height: 1;
    transform: rotateZ(-90deg) translateX(0.6rem);
    letter-spacing: -0.1em;
}
.menu-banner2 .banner-content {
    bottom: 10%;
}
.menu-banner2 .banner-title {
    margin-bottom: 0.6rem;
    font-size: 2.6rem;
}
.menu-banner2 .banner-subtitle {
    font-size: 1.6rem;
}
.megamenu .menu-banner {
    padding: 0;
    overflow: hidden;
}
.megamenu .menu-banner figure {
    height: 100%;
}
.megamenu .menu-banner img {
    height: 100%;
    object-fit: cover;
}
.megamenu .menu-banner .btn-link:hover {
    color: #0b80d2;
}
.mobile-menu-wrapper {
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10000;
    font-family: "Open Sans", sans-serif;
    transition: visibility 0.4s;
}
.mobile-menu-container {
    max-width: 296px;
    padding: 2rem 1.5rem;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background: #222529;
    box-shadow: 1px 0 5px rgba(0, 0, 0, 0.5);
    transform: translateX(-296px);
    transition: transform 0.4s;
}
.mobile-menu-container .mobile-menu {
    margin-bottom: 0.5rem;
    background: #222529;
}
.mobile-menu-container.scrollable::-webkit-scrollbar-thumb,
.mobile-menu-container .sidebar-content::-webkit-scrollbar-thumb {
    margin-right: 2px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 7px;
    cursor: pointer;
}
.mobile-menu {
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: -0.025em;
    color: #e1e1e1;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: -0.025em;
}
.mobile-menu ul {
    display: none;
    width: 100%;
}
.mobile-menu > li:first-child {
    padding-top: 0.5rem;
}
.mobile-menu > li:last-child {
    padding-bottom: 0.5rem;
}
.mobile-menu li a {
    display: block;
    position: relative;
    padding: 1.3rem 0.6rem 1.3rem 1rem;
}
.mobile-menu li i {
    display: inline-block;
    margin-bottom: 1px;
    font-size: 2rem;
    margin-right: 0.5rem;
    line-height: 0;
    vertical-align: middle;
}
.mobile-menu li:not(:last-child) {
    border-bottom: 1px solid #2e3237;
}
.mobile-menu .active > a,
.mobile-menu .expanded {
    background: #2e3237;
}
.mobile-menu-overlay {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: #000;
    opacity: 0;
    transition: opacity 0.4s;
}
.mobile-menu-close {
    position: fixed;
    left: calc(100vw - 50px);
    top: 25px;
    transition: opacity 0.3s;
    opacity: 0;
}
.mobile-menu-close i {
    font-size: 2.8rem;
    color: #e1e1e1;
}
.mmenu-anim {
    transform: translateY(30%);
}
.mmenu-anim,
.mmenu-anim > li {
    transition: transform 0.4s, opacity 0.3s;
    transition-timing-function: cubic-bezier(0.5, 0, 0.3, 1);
}
.mmenu-anim > li:nth-child(0) {
    opacity: 0;
    transform: translateY(0px);
}
.mmenu-anim > li:first-child {
    opacity: 0;
    transform: translateY(50px);
}
.mmenu-anim > li:nth-child(2) {
    opacity: 0;
    transform: translateY(100px);
}
.mmenu-anim > li:nth-child(3) {
    opacity: 0;
    transform: translateY(150px);
}
.mmenu-anim > li:nth-child(4) {
    opacity: 0;
    transform: translateY(200px);
}
.mmenu-anim > li:nth-child(5) {
    opacity: 0;
    transform: translateY(250px);
}
.mmenu-anim > li:nth-child(6) {
    opacity: 0;
    transform: translateY(300px);
}
.mmenu-anim > li:nth-child(7) {
    opacity: 0;
    transform: translateY(350px);
}
.mmenu-active {
    overflow: hidden;
}
.mmenu-active .mmenu-anim,
.mmenu-active .mmenu-anim > li {
    opacity: 1;
    transform: translateY(0);
}
.mmenu-active .page-wrapper {
    margin-left: 296px;
    margin-right: -296px;
}
.mmenu-active .mobile-menu-wrapper {
    visibility: visible;
}
.mmenu-active .mobile-menu-container {
    transform: translateX(0);
}
.mmenu-active .mobile-menu-overlay {
    opacity: 0.8;
}
.mmenu-active .mobile-menu-close {
    opacity: 1;
}
@media (max-width: 400px) {
    .mobile-menu-close {
        left: calc(100vw - 40px);
        top: 10px;
    }
}
.col-1 {
    max-width: 8.3333%;
    flex: 0 0 8.3333%;
}
.col-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
}
.col-3 {
    max-width: 25%;
    flex: 0 0 25%;
}
.col-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
}
.col-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
}
.col-6 {
    max-width: 50%;
    flex: 0 0 50%;
}
.col-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
}
.col-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
}
.col-9 {
    max-width: 75%;
    flex: 0 0 75%;
}
.col-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
}
.col-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
}
.col-12 {
    max-width: 100%;
    flex: 0 0 100%;
}
@media (min-width: 576px) {
    .col-sm-1 {
        max-width: 8.3333%;
        flex: 0 0 8.3333%;
    }
    .col-sm-2 {
        max-width: 16.6667%;
        flex: 0 0 16.6667%;
    }
    .col-sm-3 {
        max-width: 25%;
        flex: 0 0 25%;
    }
    .col-sm-4 {
        max-width: 33.3333%;
        flex: 0 0 33.3333%;
    }
    .col-sm-5 {
        max-width: 41.6667%;
        flex: 0 0 41.6667%;
    }
    .col-sm-6 {
        max-width: 50%;
        flex: 0 0 50%;
    }
    .col-sm-7 {
        max-width: 58.3333%;
        flex: 0 0 58.3333%;
    }
    .col-sm-8 {
        max-width: 66.6667%;
        flex: 0 0 66.6667%;
    }
    .col-sm-9 {
        max-width: 75%;
        flex: 0 0 75%;
    }
    .col-sm-10 {
        max-width: 83.3333%;
        flex: 0 0 83.3333%;
    }
    .col-sm-11 {
        max-width: 91.6667%;
        flex: 0 0 91.6667%;
    }
    .col-sm-12 {
        max-width: 100%;
        flex: 0 0 100%;
    }
}
@media (min-width: 768px) {
    .col-md-1 {
        max-width: 8.3333%;
        flex: 0 0 8.3333%;
    }
    .col-md-2 {
        max-width: 16.6667%;
        flex: 0 0 16.6667%;
    }
    .col-md-3 {
        max-width: 25%;
        flex: 0 0 25%;
    }
    .col-md-4 {
        max-width: 33.3333%;
        flex: 0 0 33.3333%;
    }
    .col-md-5 {
        max-width: 41.6667%;
        flex: 0 0 41.6667%;
    }
    .col-md-6 {
        max-width: 50%;
        flex: 0 0 50%;
    }
    .col-md-7 {
        max-width: 58.3333%;
        flex: 0 0 58.3333%;
    }
    .col-md-8 {
        max-width: 66.6667%;
        flex: 0 0 66.6667%;
    }
    .col-md-9 {
        max-width: 75%;
        flex: 0 0 75%;
    }
    .col-md-10 {
        max-width: 83.3333%;
        flex: 0 0 83.3333%;
    }
    .col-md-11 {
        max-width: 91.6667%;
        flex: 0 0 91.6667%;
    }
    .col-md-12 {
        max-width: 100%;
        flex: 0 0 100%;
    }
}
@media (min-width: 992px) {
    .col-lg-1 {
        max-width: 8.3333%;
        flex: 0 0 8.3333%;
    }
    .col-lg-2 {
        max-width: 16.6667%;
        flex: 0 0 16.6667%;
    }
    .col-lg-3 {
        max-width: 25%;
        flex: 0 0 25%;
    }
    .col-lg-4 {
        max-width: 33.3333%;
        flex: 0 0 33.3333%;
    }
    .col-lg-5 {
        max-width: 41.6667%;
        flex: 0 0 41.6667%;
    }
    .col-lg-6 {
        max-width: 50%;
        flex: 0 0 50%;
    }
    .col-lg-7 {
        max-width: 58.3333%;
        flex: 0 0 58.3333%;
    }
    .col-lg-8 {
        max-width: 66.6667%;
        flex: 0 0 66.6667%;
    }
    .col-lg-9 {
        max-width: 75%;
        flex: 0 0 75%;
    }
    .col-lg-10 {
        max-width: 83.3333%;
        flex: 0 0 83.3333%;
    }
    .col-lg-11 {
        max-width: 91.6667%;
        flex: 0 0 91.6667%;
    }
    .col-lg-12 {
        max-width: 100%;
        flex: 0 0 100%;
    }
}
.footer-bottom {
    padding: 3.35rem 0;
    border-top: 1px solid rgba(255, 255, 255, 0.05);
}
.footer-bottom p {
    font-size: 1.6rem;
    letter-spacing: -0.01em;
}
p {
    font-family: Poppins, sans-serif;
}
.custom-scroll-top:before {
    content: "";
    position: absolute;
    left: 15px;
    top: 17px;
    width: 10px;
    height: 10px;
    border: solid #fff;
    border-width: 2px 2px 0 0;
    transform: rotate(-45deg);
}
@keyframes zoomIn {
    0% {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }
    50% {
        opacity: 1;
    }
}
.zoomIn {
    animation-name: zoomIn;
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.fadeIn {
    animation-name: fadeIn;
}
@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
.fadeOut {
    animation-name: fadeOut;
}
figure {
    margin: 0;
}
.btn-gradient,
.demos span {
    position: relative;
    z-index: 1;
    overflow: hidden;
    background: #33363b;
}
.btn-gradient:before,
.demos span:before {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: -1;
    width: 0;
    height: 0;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.2;
    background: linear-gradient(166deg, #08c 35%, #5349ff 65%);
    transition: 0.3s;
}
.demos .grid-item a {
    width: 100%;
    overflow: hidden;
}
.btn-gradient.active:before,
.btn-gradient.gra-reversed:before,
.btn-gradient:focus:before,
.btn-gradient:hover:before,
.demos a:hover > span:before {
    width: 120%;
    padding-top: 120%;
    opacity: 1;
}
.btn-gradient {
    border: 0;
    padding: 1.2rem 2.95rem;
}
.btn-gradient.gra-reversed:focus:before,
.btn-gradient.gra-reversed:hover:before {
    width: 0;
    padding-top: 0;
    opacity: 0.2;
}
.btn-gradient.gra-boxed:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
    border: 2px solid transparent;
    transition: border-color 0.3s;
}
.btn-gradient.gra-boxed:not(.active):not(:hover):after {
    border-color: rgba(204, 204, 204, 0.3);
}
.btn-gradient.btn {
    color: #fff;
    background: #33363b;
}
.btn-gradient.btn-rounded:before {
    border-radius: 50%;
}
.btn-gradient.btn-lg {
    padding: 1.36em 2.92em;
}
.header .btn-gradient {
    margin-bottom: 2px;
}
.bg-gradient {
    background: linear-gradient(to top left, #ff472b, #ff472b) !important;
}
@media (min-width: 1366px) {
    .d-xxl-none {
        display: none !important;
    }
    .d-xxl-block {
        display: block !important;
    }
}
@media (min-width: 1200px) {
    .d-xl-inline-block {
        display: inline-block !important;
    }
}
.text-gradient {
    background-color: #0b80d2;
    background-image: -webkit-gradient(linear-gradient(166deg, #08c 35%, #5349ff 65%));
    background-image: linear-gradient(166deg, #08c 35%, #5349ff 65%);
    background-size: 100%;
    -webkit-box-decoration-break: clone;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    box-decoration-break: clone;
    background-attachment: scroll;
    background-position: 0 0;
    background-repeat: repeat;
}
@media (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .text-gradient {
        background: none;
        color: #0b80d2;
    }
}
.lazy-img {
    height: 0;
    background: #f4f4f4;
}
.btn-dark.btn-solid:active,
.btn-dark.btn-solid:focus,
.btn-dark.btn-solid:hover {
    background: #33363b;
    border-color: #33363b;
}
@keyframes pencilAnimation {
    0% {
        width: 0;
        opacity: 0;
    }
    to {
        width: 106%;
        opacity: 1;
    }
}
.highlight-word {
    position: relative;
    display: inline-block;
}
.highlight-word .highlight {
    position: absolute;
    left: -3%;
    bottom: -1.9rem;
    /*  background: url(images/pencil-blue-line.png) no-repeat; */
    background-size: contain;
    width: 0;
    opacity: 0;
    height: 1.1rem;
    overflow: hidden;
    transition: width ease 1s, opacity ease 1s;
}
.appear-animation-visible .highlight-word .highlight.animated {
    opacity: 1;
    width: 106%;
}
.shape-overlay {
    overflow: hidden;
    position: relative;
    z-index: 1;
}
.shape-overlays {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.same-height .flip-card,
.same-height .icon-box {
    height: 100%;
}
.text-lg {
    font-size: 1.2em;
}
.float-el {
    transition: transform linear 1s;
}
.flip-card {
    position: relative;
    perspective: 1000px;
}
.flip-card .flip-back,
.flip-card .flip-front {
    backface-visibility: hidden;
    transform-style: preserve-3d;
    transition: transform 0.7s;
}
.flip-card .flip-front {
    transform: translate3d(0, 0, 1px) rotateY(0deg);
}
.flip-card .flip-back {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translate3d(0, 0, -1px) rotateY(180deg);
}
.flip-card .flip-content {
    backface-visibility: hidden;
    perspective: inherit;
    transform: translate3d(0, 0, 60px);
}
.flip-card:hover .flip-front {
    transform: translate3d(0, 0, -1px) rotateY(-180deg);
}
.flip-card:hover .flip-back {
    transform: translate3d(0, 0, 1px) rotateY(0deg);
}
.header .divider {
    height: 62px;
    margin-right: 2.4rem;
    background: rgba(255, 255, 255, 0.3);
}
.header-middle.fixed {
    border-bottom-color: transparent;
}
.change-log {
    font-size: 1.4rem;
    margin-bottom: 4px;
}
.header .btn-purchase {
    margin-bottom: 4px;
    border-width: 1px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
}
.sticky-header.fixed .btn:hover {
    background-color: #0b80d2;
}
.change-log {
    font-size: inherit;
    letter-spacing: -0.02em;
    margin-bottom: 2px;
}
.intro-banner {
    min-height: 82.8rem;
}
.intro-banner .banner-subtitle {
    font-size: 2em;
}
.intro-banner .banner-subtitle .gift {
    margin-left: 0.8rem;
    margin-right: 0.6rem;
    font-size: 2.4em;
}
.intro-banner .banner-title {
    margin-bottom: 3.4rem;
    font-size: 5em;
    text-indent: -2px;
    line-height: 1.06;
}
.intro-banner .container {
    padding-top: 5rem;
    padding-bottom: 5rem;
}
.intro-banner .banner-content {
    display: inline-block;
    padding-top: 100px;
    top: 50%;
    transform: translateY(-50%);
}
.intro-banner .btn-play {
    display: inline-flex;
    position: relative;
    align-items: center;
    justify-content: center;
    margin-right: 1.5rem;
    width: 7.8rem;
    height: 7.8rem;
    background: #222;
    border-radius: 50%;
    vertical-align: middle;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
}
.intro-banner .btn-play svg {
    width: 50px;
    height: 50px;
    fill: #fff;
}
.intro-banner .custom-absolute-img1 {
    position: absolute;
    margin: 0;
    left: 52.7%;
    right: 0;
    bottom: 0;
}


.intro-banner .custom-absolute-img1 img {
    min-height: 600px;
    object-fit: cover;
    object-position: left top;
}

.intro-banner .custom-center-search {
    position:absolute;
    left:50%;
    top:2rem;
    /* transform: translate(-50%); */
    /* bottom: -14rem; */
    margin:0;
}
@media (max-width:1500px){
    .intro-banner .custom-center-search {
        position:absolute;
        left:50%;
        transform: translate(-50%);
    } 
}
.intro-banner .custom-absolute-img2 {
    position: absolute;
    /* left: 57.38%; */
    margin: 0;
    /* max-width: 40%; */
    bottom: -14rem;
}
.intro-banner .custom-absolute-img2 img {
    position: relative;
    z-index: 1;
}
.intro-banner .custom-absolute-img2 figure {
    top: -50px;
}
.intro-banner .custom-absolute-img2 figure:before {
    content: "";
    position: absolute;
    left: 10px;
    right: 30px;
    top: 10px;
    bottom: 10px;
    box-shadow: -20px 0px 100px 10px rgba(0, 0, 0, 0.5);
}
.intro-banner .mobile-content {
    position: absolute;
    left: 27%;
    right: 6.8%;
    top: 1.8%;
    bottom: 12%;
}
.intro-banner .mobile-image {
    position: relative;
    z-index: 1;
}
.intro-banner .video-desc {
    font-size: 1.6rem;
    line-height: 2.2rem;
    vertical-align: middle;
}
.intro-banner .video-desc span {
    font-size: 1.4rem;
}
.main-features {
    position: relative;
    padding-top: 9.5rem;
    z-index: 1;
}
.main-features h2 {
    font-size: 3rem;
    font-weight: 800;
    line-height: 3.2rem;
}
.main-features h2 span {
    margin-left: 1rem;
}
.main-features .icon-box {
    padding: 0px;
    box-shadow: 0 5px 50px 0 rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    height: 220px;
    background: #fff;
}
.main-features svg {
    width: 6.5rem;
    height: 6.5rem;
    fill: #ccc;
}
.main-features .icon-box-title {
    font-size: 2.2rem;
    line-height: 2.6rem;
    margin-bottom: 1rem;
}
.main-features p {
    font-size: 12.88px;
    line-height: 22px;
}
.attractive-features .col-gift {
    padding: 48px 10px 0 12px;
}
.attractive-features .col-beautiful {
    padding: 39px 10px 0 6px;
}
.attractive-features h2 {
    margin-bottom: 1.3rem;
    font-size: 5rem;
}
.attractive-features h2 b {
    margin-left: -3px;
}
.attractive-features .enjoy {
    max-width: 30rem;
    margin-bottom: 2.6rem;
    letter-spacing: -0.015em;
}
.attractive-features h3 {
    max-width: 29rem;
    font-size: 4rem;
    letter-spacing: -0.03em;
    line-height: 0.9;
}
.attractive-features ol,
.attractive-features p {
    font-size: 1.6rem;
    line-height: 1.625;
}
.attractive-features .attractive {
    color: #02020c;
}
.attractive-features .list span {
    display: inline-block;
    min-width: 3.2rem;
    color: #0b80d2;
    font-weight: 700;
}
.feature-images {
    position: relative;
    width: 400px;
    height: 388px;
    margin: -17px auto 0;
    transform: translateX(-2px);
}
.feature-images:before {
    content: "";
    position: absolute;
    top: 12px;
    left: 18px;
    right: 22px;
    bottom: 16px;
    border-radius: 3rem;
    transform: rotate(45deg);
    box-shadow: 0 1rem 6rem rgba(0, 0, 0, 0.1);
}
.feature-images > * {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 8px 11px;
    transform: translate(-50%, -50%);
}
.feature-images > .feature1 {
    transform: translate(-50%, -100%);
}
.feature-images > .feature1:after {
    content: "eCommerce";
}
.feature-images > .feature2 {
    transform: translate(-100%, -50%);
}
.feature-images > .feature2:after {
    content: "HTML 5";
}
.feature-images > .feature3 {
    transform: translate(-50%, 0);
}
.feature-images > .feature3:after {
    content: "CSS 3";
}
.feature-images > .feature4 {
    transform: translate(0, -50%);
}
.feature-images .feature-image:after {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: -0.025em;
    color: #fff;
}
@keyframes particleDots1 {
    0%,
    to {
        width: 0;
        height: 0;
        opacity: 0;
    }
    10% {
        width: 1px;
        height: 1px;
        opacity: 1;
    }
    50% {
        width: 10px;
        height: 10px;
        opacity: 1;
    }
    90% {
        width: 5px;
        height: 5px;
    }
}
@keyframes particleDots2 {
    0%,
    to {
        width: 0;
        height: 0;
        opacity: 0;
    }
    10%,
    90% {
        width: 1px;
        height: 1px;
        opacity: 1;
    }
    50% {
        width: 7px;
        height: 7px;
    }
}
.particle span {
    position: absolute;
    border-radius: 50%;
    background: #d3d3d3;
    transform: translate(-50%, -50%);
    animation: infinite particleDots1 1.5s;
}
.particle1 {
    top: -2%;
    left: 53.9%;
    transform: rotate(315deg);
    padding: 0;
}
.particle2 {
    top: 100%;
    left: 54.7%;
    transform: rotate(225deg);
    padding: 0;
}
.particle2 + .particle2 {
    top: 58.1%;
    left: 99.9%;
    transform: rotate(45deg);
}
.particle2 span {
    background: #b5d9f1;
    animation: infinite particleDots2 2s;
}
.experience h3 {
    color: #238cd6;
}
.experience h3 > span {
    font-style: italic;
    padding-right: 0.5rem;
}
.experience h2 {
    font-size: 5rem;
    line-height: 0.95;
}
.experience .col-md-5 {
    padding-left: 4.2rem;
}
.experience p {
    line-height: 1.72;
}
.experience .shapes {
    height: 60rem;
}
.experience .shapes h3 {
    top: 35.5rem;
    left: 3rem;
    font-weight: 400;
}
.experience .shapes > * {
    position: absolute;
}
.experience .white-rect {
    border-radius: 3px;
    width: 263px;
    left: 24.8%;
    height: 173px;
    box-shadow: 0 1rem 6rem rgba(0, 0, 0, 0.1);
}
.experience .window3 {
    top: 5rem;
    right: 15.7%;
}
.experience .black-rect {
    border-radius: 3px;
    background: #282b30;
    width: 210px;
    height: 139px;
    top: 20rem;
    right: 26.7%;
}
.experience .window2 {
    top: 17.4rem;
    right: 11.8%;
}
.experience .window1 {
    top: 7rem;
}
.experience .drop {
    top: 47.1rem;
    left: 29.3%;
    width: 52px;
    height: 52px;
    border-radius: 52%;
    background: #29b6f6;
}
.experience .expshape1 {
    left: -13.15%;
    top: 14rem;
    fill: rgba(41, 182, 246, 0.85);
}
.experience .expshape2 {
    top: 27.9rem;
    left: -9.7%;
    fill: #0595ed;
}
.devotion {
    position: relative;
    padding-left: 15rem;
}
.devotion mark {
    z-index: 1;
    top: 0;
    left: 2rem;
    background: transparent;
    font-size: 8rem;
    text-align: center;
    line-height: 11.2rem;
}
.devotion mark:before {
    z-index: -1;
    background: linear-gradient(to top left, #5349ff, #08c);
}
.devotion:after {
    z-index: 1;
    top: 0;
    left: 2rem;
    border: 1px solid #2793ee;
}
.devotion:after,
.devotion mark:before {
    content: "";
    display: block;
    border-radius: 3rem;
    transform: rotate(45deg);
}
.devotion:after,
.devotion mark,
.devotion mark:before {
    position: absolute;
    width: 11.2rem;
    height: 11.2rem;
}
.devotion h3 {
    padding: 0.1em 0.2em;
    border-radius: 1rem;
    margin-bottom: 0.8rem;
}
.devotion p {
    font-size: 2rem;
    line-height: 1.2;
}
.demos-section {
    background: #282b30;
}
.demos-section mark {
    font-size: 2em;
}
.demos-section h2 {
    font-size: 4rem;
    line-height: 1.3;
    letter-spacing: -0.035em;
}
.demos-section p {
    font-size: 1.6rem;
    line-height: 1.5;
}
.demos-nav {
    letter-spacing: -0.025em;
}
.demos-nav a,
.demos-nav a.active {
    color: #fff;
}
.demos-nav .demo-label {
    position: absolute;
    top: 15px;
    right: -70px;
    display: block;
    text-align: center;
    width: 200px;
    transform: rotateZ(45deg);
    line-height: 28px;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: -0.05em;
    color: #fff;
}
.demos-nav .demo-label.label-popular {
    background-image: linear-gradient(90deg, #f55184 20%, #b73d9c 80%);
}
.demos-nav .demo-label.label-new {
    background-image: linear-gradient(90deg, #3b8df1 20%, #5449ff 80%);
}
.demos-nav .demo-label.label-trending {
    background-image: linear-gradient(90deg, #fe9f5f 20%, #ea5855 80%);
}
.demo-filters {
    padding-bottom: 4rem;
}
.demo-filters li:not(:last-child) {
    margin-right: 2rem;
}
.demo-filters a {
    position: relative;
    display: block;
    background: transparent;
    padding: 1.44em 2.72em;
    border-radius: 3px;
    margin-bottom: 1rem;
}
.demos a {
    display: inline-block;
}
.demos span {
    display: inline-block;
    margin: -16px 0 1.25vw;
    padding: 0.85em 1.3em;
    background-color: #33363b;
    border-radius: 3px;
    text-transform: uppercase;
    font-weight: 700;
    font-family: "Open Sans", sans-serif;
}
.demos svg {
    vertical-align: -7px;
    fill: #fff;
}
.demos img {
    display: block;
    margin: auto;
    border-radius: 3px;
    width: 100%;
}
.elements-section h2,
.features-section h2,
.variation-section h2 {
    font-size: 4rem;
}
.features-section > p,
.variation-section p {
    font-size: 1.6rem;
    line-height: 1.5;
    letter-spacing: -0.03em;
}
.features-section p {
    color: #999;
}
.features svg {
    fill: #0b80d2;
    stroke: #0b80d2;
    stroke-dashoffset: 1500;
    stroke-dasharray: 1500;
    transition: transform 0.3s, fill 0.5s;
}
.features > :nth-child(2n) svg {
    fill: #a33188;
    stroke: #a33188;
}
.features > div {
    margin-bottom: 2.1rem;
}
.features .icon-box {
    padding: 6.2rem 5rem 5.9rem;
    border-radius: 3px;
    background: #fff;
    box-shadow: 0 2rem 5rem rgba(0, 0, 0, 0.05), 0 0 1rem rgba(0, 0, 0, 0.05);
    overflow: hidden;
}
.features .icon-box:hover svg {
    transform: scale(1.4);
    stroke-dashoffset: 0;
    fill: transparent;
    transition: transform 0.3s, fill 0.5s, stroke-dashoffset 6s;
}
.features .icon-box-title {
    margin: 6px 0 1.4rem;
    font-size: 2rem;
    line-height: 1.3;
    letter-spacing: 0;
    text-transform: none;
}
.variation-section {
    margin-top: -16.7rem;
}
.variation-section .owl-carousel {
    width: 140%;
    margin-left: -20%;
    margin-right: -20%;
}
.variation-section .owl-carousel img {
    max-height: 17.2rem;
}
.variation-section img {
    object-fit: cover;
    position: absolute;
    top: 1.7rem;
    border-radius: 2rem;
}
.variation-section svg {
    width: 100%;
    fill: #fff;
    transition: transform 0.5s;
}
.variation-section span {
    margin: 1.8rem 0 4.2rem;
    color: #fff;
    font-size: 2rem;
    letter-spacing: -0.03em;
    transition: transform 0.3s;
}
.elements-section h3 {
    font-size: 1.6rem;
    margin-bottom: 3px;
}
.elements-section .element {
    padding: 1.2rem 0 2.6rem;
    margin-bottom: 1rem;
    background: #fff;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 2rem 3rem rgba(0, 0, 0, 0.05), 0 0 5px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s, background-color 0.3s, color 0.3s;
}
.elements-section .element:hover {
    transform: scale(1.3);
}
.elements-section p {
    font-size: 1.6rem;
}
.elements-section svg {
    margin: 23px 0 8px;
    fill: #666;
}
.elements > div:hover {
    z-index: 1;
}
footer .row {
    margin-top: -8.8rem;
}
footer .icon-box {
    display: inline-block;
    width: 100%;
    padding: 5rem;
    border-radius: 1rem;
    background: #282b30;
    box-shadow: 0 2rem 3rem rgba(0, 0, 0, 0.05);
}
footer .icon-box-icon svg {
    fill: #fff;
}
footer h2 {
    font-size: 5rem;
}
footer h3 {
    font-size: 4rem;
}
footer h3 b {
    color: #246de2;
}
footer .list {
    font-size: 1.8rem;
    letter-spacing: 0.01em;
}
footer .list svg {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-77%, -50%);
    fill: #999;
}
.footer .icon-box-title {
    font-size: 2rem;
    text-transform: none;
}
.mfp-bg {
    background-color: rgba(0, 0, 0, 0.8);
}
.mfp-video-popup .mfp-content {
    max-width: 1000px;
}
.mfp-video-popup video {
    width: 100%;
}
.minipopup-area {
    display: none;
}
@media (min-width: 420px) {
    .intro-banner .btn-dark {
        margin-right: 0.7rem;
    }
}
@media (min-width: 480px) {
    .mr-xs-3 {
        margin-right: 1.5rem;
    }
}
@media (min-width: 576px) {
    .variation-section img {
        transform: translate3d(0px, -160px, 90px) rotate3d(1, 0, 0, -90deg);
        transition: transform 0.5s;
    }
    .variation-section .layout {
        display: block;
        perspective: 500px;
        backface-visibility: visible;
        transform-style: preserve-3d;
    }
    .variation-section .layout:hover img {
        transform: none;
    }
    .variation-section .layout:hover svg {
        transform: translate3d(0px, 120px, -200px) rotate3d(1, 0, 0, 90deg);
    }
    .variation-section .layout:hover h4 {
        transform: translateY(-26px);
    }
}
@media (min-width: 768px) {
    .experience .devotion {
        margin-left: -12rem;
    }
    .p-max-width {
        max-width: 66rem;
        margin-left: auto;
        margin-right: auto;
    }
}
@media (min-width: 992px) {
    .attractive-features .col-features {
        margin-left: -8.3333%;
    }
    .experience .devotion {
        margin-left: -6rem;
    }
    footer .list {
        margin-left: 4rem;
    }
    footer .list li:not(:last-child) {
        margin-right: 9rem;
    }
}
@media (min-width: 1200px) {
    .intro-banner .banner-title {
        font-size: 5em;
    }
    .intro-banner .video-banner {
        margin-left: 6rem;
    }
    .experience .devotion {
        margin-left: 0;
    }
}
@media (max-width: 1220px) {
    .variation-section .overflow-hidden {
        margin: 0 -20px;
    }
}
@media (max-width: 1199px) {
    .features .icon-box {
        max-width: 50rem;
        margin-left: auto;
        margin-right: auto;
        padding-left: 3rem;
        padding-right: 3rem;
    }
    .demo-filters li:not(:last-child) {
        margin-right: 1rem;
    }
    .demo-filters a {
        padding: 1.44em 1.72em;
    }
    .intro-banner .banner-title {
        font-size: 3.5em;
    }
    .features .icon-box {
        max-width: 50rem;
        margin-left: auto;
        margin-right: auto;
        padding-left: 3rem;
        padding-right: 3rem;
    }
}
@media (max-width: 991px) {
    .intro-banner p {
        margin-left: auto;
        margin-right: auto;
    }
    .mobile-menu-toggle svg {
        stroke: #fff;
    }
    .intro-banner {
        height: auto;
        text-align: center;
        font-size: 0.9rem;
    }
    .intro-banner .container {
        position: relative;
    }
    .intro-banner .banner-content {
        transform: none;
    }
    .intro-banner .custom-absolute-img1 {
        left: 10%;
    }
    .intro-banner .custom-absolute-img1 img {
        min-height: 0;
        max-height: 500px;
    }
    .intro-banner .custom-absolute-img2 {
        bottom: -10rem;
    }
    .attractive-features .col-gift {
        padding-left: 5vw;
    }
    .attractive-features .col-beautiful {
        padding-top: 76px;
    }
    footer .list {
        flex-direction: column;
    }
}
@media (max-width: 767px) {
    .header .btn-purchase {
        display: none;
    }
    .main-features .icon-box,
    footer .icon-box {
        max-width: 40rem;
        margin-left: auto;
        margin-right: auto;
    }
    .attractive-features h2 {
        font-size: 4rem;
    }
    .attractive-features h3,
    .elements-section h2,
    .features-section h2,
    .variation-section h2 {
        font-size: 3.1rem;
    }
}
@media (max-width: 575px) {
    .attractive-features .col-gift {
        order: -1;
        padding: 0 10px 8rem 7vw;
    }
    .attractive-features .col-beautiful {
        padding-left: 7vw;
    }
    .elements > div {
        max-width: 30rem;
        margin-left: auto;
        margin-right: auto;
    }
    .attractive-features .col-beautiful {
        padding-top: 8rem;
    }
    .variation-section .layout img {
        width: 306px;
        top: 3rem;
        left: 50%;
        border-radius: 2px 2px 2rem 2rem;
        opacity: 0;
        transform: translate(-50%);
        transition: opacity 0.3s;
    }
    .variation-section .layout:hover img {
        opacity: 1;
    }
}
@media (max-width: 479px) {
    .variation-section .overflow-hidden {
        margin: 0 -15px;
    }
}

.contentHeaderNsns .headerNsns {
  margin-top: -80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  font-family: "Roboto", sans-serif;
  height: 78vh;
  z-index: 4;
  position: relative;
}

.contentHeaderNsns .headerNsns h1 {
  color: white;
  font-size: 40px;
  font-weight: bold;
}

.selectLetters {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding-inline: 10px;
}

.selectLetters .labelLetters {
  background-color: #32485e;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  margin: 5px;
  border-radius: 6px;
  color: white;
  cursor: pointer;
  transition: 0.1s;
  position: relative;
  top: 0px;
  font-weight: bold;
}

.selectLetters .labelLetters:hover {
  background-color: #d4422f;
  top: -5px;
}

/* Style the buttons that are used to open and close the accordion panel */

.acordeonNsNs h1 {
  margin: 0;
  line-height: 2;
  text-align: center;
}

/* .acordeonNsNs h2 {
    margin: 0 0 0.5em;
    font-weight: normal;
    cursor: pointer;
} */

.acordeonNsNs .tab-content h2 {
  margin: -10px 0 0.5em;
  font-weight: 400;
  cursor: pointer;
}

.acordeonNsNs .tab-content h2:focus {
  cursor: pointer;
  border: 4px solid #e13019 !important;
}

.acordeonNsNs input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.acordeonNsNs .row {
  display: flex;
}

.acordeonNsNs .row .col {
  flex: 1 1;
}

.acordeonNsNs .row .col:last-child {
  margin-left: 0px;
}

.LetterAcordeon {
  background-color: #ff4c35;
  color: white;
  display: flex;
  width: 50px;
  height: 50px;
  font-size: 30px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.divContentLetter {
  display: flex;
  align-items: center;
}

.divContentLetter h1 {
  font-size: 20px;
  color: white;
  margin-left: 12px;
  font-weight: bolder;
}

/* Accordion styles */
.acordeonNsNs .tabs {
  border-radius: 0px;
  overflow: hidden;
}

.acordeonNsNs .tab {
  width: 100%;
  color: white;
  overflow: hidden;
}

.acordeonNsNs .tab input:focus {
  width: 100%;
  color: rgb(66, 8, 8);
  /* overflow: hidden; */
  outline: 1px solid rgb(75, 200, 7);
}

/* color: #a01300 !important; */

.acordeonNsNs .tab-label {
  display: flex;
  justify-content: space-between;
  padding: 0px;
  background: #32485e;
  font-weight: bold;
  cursor: pointer;
  font-size: 15px;
  margin: 10px;
  align-items: center;
  margin-bottom: 0px;
  margin-top: 0px;
  border-bottom: 1px solid white;
}

.acordeonNsNs .tab-label:hover {
  background: rgb(182, 55, 38);
}
.acordeonNsNs .tab-label:focus {
  /* color: #a01300 !important; */
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
  /* background-color:yellow; */
}

.acordeonNsNs .tab-label h2:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

div[tabindex="0"]:focus {
  outline: 4px dashed orange;
}

.acordeonNsNs .tab-label::after {
  content: "❯";
  width: 1em;
  height: 1em;
  text-align: center;
  transition: all 0.35s;
  margin-right: 11px;
  color: white;
}

.acordeonNsNs .tab-content {
  padding: 0 1em;
  color: #2c3e50;
  background: white;
  transition: all 0.2s;
  margin: 10px;
  margin-top: -10px;
  overflow: hidden;
  font-size: 15px;
}

/* h2:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
} */
/* .acordeonNsNs .tab-content h2 {
    margin: 0;
    padding: 0;
} */

.acordeonNsNs .tab-close {
  display: flex;
  justify-content: flex-end;
  padding: 1em;
  font-size: 0.75em;
  background: #2c3e50;
  cursor: pointer;
}

.acordeonNsNs .tab-close:hover {
  background: #1a252f;
}

.acordeonNsNs input:checked + .tab-label {
  background: #b83727;
}

.acordeonNsNs input:checked + .tab-label::after {
  transform: rotate(90deg);
}

.acordeonNsNs input:checked ~ .tab-content {
  max-height: -moz-fit-content;
  max-height: fit-content;
  padding: 1em;
  background-color: rgb(242, 242, 242);
  margin: 10px;
  margin-top: 0px;
}

/* slider --------------------------- */
.sliderNSNS {
  height: 80vh;
  width: 100%;
  overflow: hidden;
  margin: 0px;
  margin-top: -12px;
  filter: brightness(0.6);
  animation: hidde 30s infinite alternate ease-in-out;
}

.sliderNSNS ul {
  display: flex;
  padding: 0;
  opacity: 1;
  width: 400%;
  animation: cambio 30s infinite alternate steps(1);
}

.sliderNSNS li {
  width: 100%;
  list-style: none;
}

.sliderNSNS img {
  width: 100%;
}

@keyframes hidde {
  0% {
    opacity: 1;
  }

  19% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  45% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  55% {
    opacity: 1;
  }

  65% {
    opacity: 1;
  }

  70% {
    opacity: 1;
  }

  75% {
    opacity: 1;
  }

  100% {
  }
}

@keyframes cambio {
  0% {
    margin-left: 0;
  }

  20% {
    margin-left: 0;
  }

  25% {
    margin-left: -150%;
  }

  45% {
    margin-left: -150%;
  }

  50% {
    margin-left: -300%;
  }

  70% {
    margin-left: -300%;
  }

  75% {
    margin-left: -300%;
  }

  100% {
    margin-left: -300%;
  }
}

/* filter content ------------------- */

.contentGLossaryNsns .filterContent {
  display: flex;
  flex-direction: row;
  padding: 30px;
  padding-inline: 90px;
}

.contentGLossaryNsns .filterContent .content1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.contentGLossaryNsns .filterContent .content1 h1 {
  font-size: 20px;
  margin-right: 19px;
  margin-top: 3px;
}

.contentGLossaryNsns .filterContent .content1 input {
  padding: 9px;
  padding-right: 30px;
  border-radius: 5px;
  border: 2px solid #b3b3b3;
  outline: none;
}

.contentGLossaryNsns .filterContent .content1 img {
  width: 17px;
  margin-top: 10px;
  position: relative;
  left: -25px;
  cursor: pointer;
}

.contentGLossaryNsns .filterContent .content2 {
  display: flex;
  align-items: flex-start;
  margin-left: 22px;
  margin-right: 28px;
  position: relative;
  z-index: 2;
}

.contentGLossaryNsns .filterContent .content2 h1 {
  font-size: 20px;
  margin-right: 19px;
  margin-top: 3px;
  font-weight: lighter;
}

.contentGLossaryNsns .filterContent .content2 div {
  display: flex;
}

.contentGLossaryNsns .filterContent .content2 h1 {
  font-size: 20px;
  margin-right: 19px;
  margin-top: 3px;
  font-weight: lighter;
}

.contentGLossaryNsns .filterContent .content2 div {
  display: flex;
  flex-direction: row;
}

.contentGLossaryNsns .filterContent .content2 h2 {
  margin-right: 9px;
  margin-top: 3px;
  background-color: white;
  padding: 3px;
  font-size: 16px;
  width: 29px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 2px solid #e13019;
  color: #e13019;
  cursor: pointer;
  transition: 0.2s;
}

.contentGLossaryNsns .filterContent .content2 .h2Active {
  margin-right: 9px;
  margin-top: 3px;
  color: white;
  padding: 3px;
  font-size: 16px;
  width: 29px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 2px solid #e13019;
  background-color: #e13019;
  cursor: pointer;
  transition: 0.2s;
}

.contentGLossaryNsns .filterContent .content2 h2:hover {
  background-color: #e13019;
  border: 2px solid #e13019;
  color: #ffffff;
}

.contentGLossaryNsns .filterContent .content3 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.contentGLossaryNsns .filterContent .content3 h1 {
  font-size: 20px;
  margin-right: 19px;
  margin-top: 3px;
  text-align: left;
  width: 120px;
  margin-left: -60px;
  font-weight: lighter;
}

.contentGLossaryNsns .filterContent .content3 div h2 {
  font-size: 14px;
  background-color: white;
  padding: 8px;
  border: 2px solid #c3c3c3;
  font-weight: lighter;
  margin-bottom: 0px;
  width: 160px;
  cursor: pointer;
  transition: 0.2s;
}

.contentGLossaryNsns .filterContent .content3 div h2:hover {
  background-color: rgb(237, 237, 237);
}

.contentGLossaryNsns .filterContent .content3 div h2 img {
  width: 13px;
  margin-left: 40px;
}

/* glossary content ----------------------------- */
.contentGLossary {
  margin: 90px;
  margin-top: 20px;
}

.contentGLossary .titleGlossary {
  font-size: 26px;
  color: #898989;
}

.contentGLossary .titleGlossary span {
  color: #e13019;
}

.contentGLossary .contentAllGlossary .glossary {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #bbb1b1;
  justify-content: flex-start;
  margin-top: 8px;
}

.contentGLossary .contentAllGlossary .glossary h1 {
  margin: 0px;
  background-color: #191736;
  color: white;
  font-size: 30px !important;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
}

.contentGLossary .contentAllGlossary .glossary h2 {
  margin: 0px;
  font-size: 21px;
  padding-left: 20px;
  background-color: #e13019;
  color: white;
  height: 60px;
  display: flex;
  align-items: center;
  width: 100%;
}

.contentGLossary .contentAllGlossary .glossary img {
  padding: 22px;
  font-size: 16px;
  margin-left: -59px;
  left: -9px;
  position: relative;
  z-index: 5;
}

.contentGLossary .parrafGlossary p {
  background-color: #f3efef;
  padding: 30px;
  width: 100%;
  font-size: 16px;
  margin-bottom: 0px;
}

@media screen and (max-width: 1272px) {
  .contentHeaderNsns .headerNsns {
    margin-top: -80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    font-family: "Roboto", sans-serif;
    height: 510px;
    z-index: 4;
    position: relative;
  }

  .sliderNSNS li {
    width: 100%;
    list-style: none;
    overflow: hidden;
  }

  .sliderNSNS img {
    min-width: 1840px;
    height: 500px;
  }

  .divContentLetter h1 {
    font-size: 15px;
    color: white;
    margin-left: 12px;
    font-weight: bold;
  }

  .contentHeaderNsns .headerNsns h1 {
    color: white;
    font-size: 29px;
    font-weight: bold;
  }
}

@media screen and (max-width: 530px) {
  .selectLetters .labelLetters {
    background-color: #32485e;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    margin: 5px;
    border-radius: 6px;
    color: white;
    cursor: pointer;
    transition: 0.1s;
    position: relative;
    top: 0px;
    font-weight: bold;
  }
}

* {
    padding: 0px;
    margin: 0px;
}

.menuColor1 {
    width: 100%;
    height: 110px;
}

.menuColor2 {
    width: 100%;
    height: 110px;
    position: relative;
    top: 4px;
    margin-bottom: -20px;
}

/* slider --------------------------- */
.sliderRfq {
    height: 360px;
    width: 100%;
    overflow: hidden;
    margin: 0px;
    margin-top: -12px;
    filter: brightness(0.6);
    animation: hidde 30s infinite alternate ease-in-out;
}

.sliderRfq ul {
    display: flex;
    padding: 0;
    width: 400%;
    animation: cambio 30s infinite alternate steps(1);
}

.sliderRfq li {
    width: 100%;
    list-style: none;
}

.sliderRfq img {
    width: 100%;
}

@keyframes hidde {
    0% {
        opacity: 1;
    }

    19% {
        opacity: 1;
    }

    25% {
        opacity: 0;
    }

    30% {
        opacity: 1;
    }

    45% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    55% {
        opacity: 1;
    }

    65% {
        opacity: 1;
    }

    70% {
        opacity: 1;
    }

    75% {
        opacity: 1;
    }

    100% {}
}

@keyframes cambio {
    0% {
        margin-left: 0;
    }

    20% {
        margin-left: 0;
    }

    25% {
        margin-left: -150%;
    }

    45% {
        margin-left: -150%;
    }

    50% {
        margin-left: -300%;
    }

    70% {
        margin-left: -300%;
    }

    75% {
        margin-left: -300%;
    }

    100% {
        margin-left: -300%;
    }
}


/* headerComponent ----------------------- */

.headerComponent {
    margin-top: -360px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    font-family: "Roboto", sans-serif;
    height: 360px;
    z-index: 4;
    position: relative;
}

.headerComponent h1 {
    color: white;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 10px;
}

.headerComponent h2 {
    color: white;
    font-size: 20px;
    width: 350px;
    text-align: center;
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 20px;
}

.headerComponent button {
    background-color: #E13019;
    color: white;
    border: 0px;
    padding: 15px;
    padding-inline: 40px;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    transition: 0.2s;
}

.headerComponent button:hover {
    color: #E13019;
    background-color: white;
}

/* PartInformation ---------------------- */
.PartInformation {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.PartInformation .title {
    font-size: 30px;
    margin: 30px;
    font-weight: bold;
}

.contentInputs select {
    padding: 7px;
    border: 2px solid #cdcdcd;
    border-radius: 5px;
}

.PartInformation .tableInfo {
    background-color: white;
    width: 80%;
    border-radius: 5px;
    border-collapse: collapse;
    overflow: hidden;
    box-shadow: 0px 0px 22px 0px #0000001a;
    border-radius: 5px;
}

.PartInformation .tableInfo .titlesTable {
    background-color: #E13019;
}

.PartInformation .tableInfo .titlesTable td {
    text-align: center;
    color: white;
    padding: 10px;
}

.PartInformation .tableInfo .bodyTable {
    background-color: white;
    text-align: center;
}

.PartInformation .tableInfo .bodyTable {
    background-color: white;
}

.PartInformation .tableInfo .bodyTable td {
    padding: 5px !important;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}

.PartInformation .tableInfo .bodyTable td input {
    padding: 5px !important;
    border-radius: 3px;
    border: 2px solid rgb(220, 220, 220);
    outline: none;
}

.PartInformation .tableInfo .bodyTable td img {
    cursor: pointer;
    width: 35;
}

.PartInformation .contentBtn {
    width: 80%;
    height: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.PartInformation .contentBtn button {
    padding: 10px;
    padding-inline: 20px;
    background-color: #E13019;
    color: white;
    border: 0px;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.2s;
}

.PartInformation .contentBtn button:hover {
    color: #E13019;
    background-color: rgb(232, 232, 232);
    border: 0px;
}

/* ContactFormComp ----------------------------- */

.ContactFormComp .Contact {
    padding: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
}

.ContactFormComp .Contact .firstChild {
    width: 100%;
    height: 100%;
}

.ContactFormComp .Contact .firstChild p {
    padding: 40px;
    text-align: justify;
    padding-top: 0px;
}

.ContactFormComp .Contact .lastChild {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
}

.ContactFormComp .Contact .lastChild .contentInputs {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding-right: 20px;
    margin-bottom: 20px;
}

.ContactFormComp .Contact .lastChild .contentInputs2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 20px;
    margin-bottom: 20px;
}

.ContactFormComp .Contact .lastChild .contentInputs3 {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.ContactFormComp .contactTitle {
    text-align: center;
    font-size: 27px;
    margin-top: 60px;
    color: #E13019;
    font-weight: bold;
}

.ContactFormComp .Contact .lastChild .contentInputs input {
    padding: 7px;
    border-radius: 5px;
    border: 2px solid #cdcdcd;
}

.ContactFormComp .Contact .lastChild .contentInputs span {
    font-size: 12px;
    margin-bottom: 7px;
    color: #E13019;
    font-weight: bolder;
}

.ContactFormComp .Contact .lastChild .contentInputs2 span {
    font-size: 12px;
    margin-bottom: 7px;
    color: #E13019;
    font-weight: bolder;
}

.ContactFormComp .Contact .lastChild .contentInputs2 input {
    padding: 7px;
    border-radius: 5px;
    border: 2px solid #cdcdcd;
}

.ContactFormComp .Contact .lastChild .contentInputs3 span {
    font-size: 12px;
    margin-bottom: 7px;
    /* color: #E13019; */
    color: #9C2111;
    font-weight: bolder;
}

.ContactFormComp .Contact .lastChild .contentInputs3 textarea {
    padding: 7px;
    border-radius: 5px;
    border: 2px solid #cdcdcd;
    min-height: 150px;
    max-height: 150px;
    width: 97%;
    min-width: 97%;
    max-width: 97%;
}

.ContactFormComp {
    background-color: #f3f3f3;
    padding-top: 1px;
    margin-top: 70px;
}

.ContactFormComp .Submit {
    width: 97%;
    padding: 10px;
    background-color: #E13019;
    color: white;
    border: 0px;
    margin-top: 21px;
    border-radius: 6px;
    cursor: pointer;
    transition: 0.2s;
    text-align: center;
}

.ContactFormComp .Submit:hover {
    color: #E13019;
    background-color: white;
}


@media screen and (max-width:810px) {
    .PartInformation .tableInfo {
        background-color: white;
        width: 80%;
        border-radius: 5px;
        border-collapse: collapse;
        overflow: hidden;
        box-shadow: 0px 0px 22px 0px #0000001a;
        border-radius: 5px;
        margin-left: 320px;
        margin-right: 90px;
    }

    .PartInformation .contentBtn {
        width: 650px;
        height: 20px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .PartInformation {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow-x: scroll;
    }
}

@media screen and (max-width:678px) {
    .PartInformation .tableInfo {
        background-color: white;
        width: 80%;
        border-radius: 5px;
        border-collapse: collapse;
        overflow: hidden;
        box-shadow: 0px 0px 22px 0px #0000001a;
        border-radius: 5px;
        margin-left: 480px;
        margin-right: 90px;
    }

    .PartInformation .contentBtn {
        width: 490px;
        height: 20px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

@media screen and (max-width:988px) {
    .menuColor1 {
        width: 100%;
        height: 81px;
    }

    .menuColor2 {
        width: 100%;
        height: 90px;
        position: relative;
        top: 4px;
        margin-bottom: -20px;
    }
}

@media screen and (max-width:522px) {
    .PartInformation .tableInfo {
        background-color: white;
        width: 80%;
        border-radius: 5px;
        border-collapse: collapse;
        overflow: hidden;
        box-shadow: 0px 0px 22px 0px #0000001a;
        border-radius: 5px;
        margin-left: 580px;
        margin-right: 90px;
    }

    .PartInformation .contentBtn {
        width: 390px;
        height: 20px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

@media screen and (max-width:738px) {
    .headerComponent {
        margin-top: -420px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
        font-family: "Roboto", sans-serif;
        height: 360px;
        z-index: 4;
        position: relative;
    }

    .ContactFormComp .Contact {
        padding: 50px;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }

    .ContactFormComp .Contact .firstChild p {
        padding: 0px;
        text-align: justify;
        padding-top: 0px;
        margin-top: 90px;
    }

    .ContactFormComp .Contact .lastChild {
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
    }

    .ContactFormComp .Contact .lastChild .contentInputs {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-right: 0px;
        margin-bottom: 20px;
    }

    .ContactFormComp .Contact .lastChild .contentInputs2 {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-right: 0px;
        margin-bottom: 20px;
    }

    .ContactFormComp .Contact .lastChild .contentInputs3 textarea {
        padding: 7px;
        border-radius: 5px;
        border: 2px solid #cdcdcd;
        min-height: 150px;
        max-height: 150px;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
    }

    .ContactFormComp .Submit {
        width: 100%;
        padding: 10px;
        background-color: #E13019;
        color: white;
        border: 0px;
        margin-top: 21px;
        border-radius: 6px;
        cursor: pointer;
        transition: 0.2s;
    }
}

@media screen and (max-width:584px) {
    .sliderRfq img {
        width: 100%;
        height: 290px;
    }
}

@media screen and (max-width:412px) {
    .PartInformation .tableInfo {
        background-color: white;
        width: 80%;
        border-radius: 5px;
        border-collapse: collapse;
        overflow: hidden;
        box-shadow: 0px 0px 22px 0px #0000001a;
        border-radius: 5px;
        margin-left: 671px;
        margin-right: 90px;
    }

    .PartInformation .contentBtn {
        width: 296px;
        height: 20px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .ContactFormComp .Contact {
        padding: 25px;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }

    .sliderRfq li {
        width: 100%;
        list-style: none;
        overflow: hidden;
    }

    .sliderRfq img {
        min-width: 930px;
        height: 290px;
    }
}


@keyframes awn-fade-in {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes awn-fade-out {
    0% {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

@keyframes awn-slide-right {
    0% {
        left: 100%;
        opacity: 0
    }

    to {
        left: 0;
        opacity: 1
    }
}

@keyframes awn-slide-left {
    0% {
        opacity: 0;
        right: 100%
    }

    to {
        opacity: 1;
        right: 0
    }
}

@keyframes awn-bar {
    0% {
        right: 100%
    }

    to {
        right: 0
    }
}

.awn-popup-loading-dots, .awn-popup-loading-dots:after, .awn-popup-loading-dots:before {
    animation-fill-mode: both;
    animation: awn-loading-dots 1s ease-in-out infinite;
    background: #fff;
    border-radius: 50%;
    height: 6px;
    width: 6px
}

.awn-popup-loading-dots {
    animation-delay: -.16s;
    color: #fff;
    display: inline-block;
    margin-left: 24px;
    position: relative
}

.awn-popup-loading-dots:after, .awn-popup-loading-dots:before {
    content: "";
    position: absolute;
    top: 0
}

.awn-popup-loading-dots:before {
    animation-delay: -.32s;
    left: -16px
}

.awn-popup-loading-dots:after {
    left: 16px
}

@keyframes awn-loading-dots {
    0%, 80%, to {
        box-shadow: 0 0 0 0
    }

    40% {
        box-shadow: 0 0 0 2px
    }
}

#awn-popup-wrapper {
    align-items: center;
    animation-fill-mode: both;
    animation-name: awn-fade-in;
    animation-timing-function: ease-out;
    background: rgba(0, 0, 0, .7);
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99999
}

#awn-popup-wrapper.awn-hiding {
    animation-name: awn-fade-out
}

#awn-popup-wrapper .awn-popup-body {
    background: #fff;
    border-radius: 6px;
    font-size: 14px;
    max-width: 500px;
    min-width: 320px;
    padding: 24px;
    position: relative;
    word-break: break-word
}

#awn-popup-wrapper .awn-popup-body.awn-popup-confirm {
    align-items: center;
    display: flex;
    flex-direction: column
}

#awn-popup-wrapper .awn-popup-body.awn-popup-confirm .fa {
    color: #c26700;
    font-size: 44px
}

#awn-popup-wrapper .awn-popup-body.awn-popup-async-block {
    background: transparent;
    color: #fff;
    font-size: 32px;
    font-weight: 700;
    text-align: center
}

#awn-popup-wrapper .awn-popup-title {
    font-size: 14px;
    font-weight: 700;
    margin-top: 8px;
    text-transform: uppercase
}

#awn-popup-wrapper .awn-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    width: 100%
}

#awn-popup-wrapper .awn-buttons .awn-btn {
    border: 0;
    border-radius: 4px;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    line-height: 32px;
    transition: background .2s linear;
    width: 45%
}

#awn-popup-wrapper .awn-buttons-1 .awn-btn {
    width: 100%
}

#awn-popup-wrapper .awn-buttons .awn-btn-success {
    background: #40871d
}

#awn-popup-wrapper .awn-buttons .awn-btn-success:hover {
    background: #367218
}

#awn-popup-wrapper .awn-buttons .awn-btn-cancel {
    background: #1c76a6
}

#awn-popup-wrapper .awn-buttons .awn-btn-cancel:hover {
    background: #186690
}

#awn-toast-container {
    bottom: 24px;
    box-sizing: border-box;
    position: fixed;
    right: 24px;
    z-index: 99998
}

#awn-toast-container.awn-top-left, #awn-toast-container.awn-top-right {
    bottom: auto;
    top: 24px
}

#awn-toast-container.awn-top-left .awn-toast:first-child, #awn-toast-container.awn-top-right .awn-toast:first-child {
    margin-top: 16px
}

#awn-toast-container.awn-bottom-left, #awn-toast-container.awn-top-left {
    left: 24px;
    right: auto
}

#awn-toast-container.awn-bottom-left .awn-toast, #awn-toast-container.awn-top-left .awn-toast {
    animation-name: awn-slide-left;
    right: 100%
}

#awn-toast-container.awn-bottom-left .awn-toast.awn-hiding, #awn-toast-container.awn-top-left .awn-toast.awn-hiding {
    right: 0
}

#awn-toast-container.awn-bottom-right .awn-toast, #awn-toast-container.awn-top-right .awn-toast {
    animation-name: awn-slide-right;
    left: 100%
}

#awn-toast-container.awn-bottom-right .awn-toast.awn-hiding, #awn-toast-container.awn-top-right .awn-toast.awn-hiding {
    left: 0
}

.awn-toast {
    animation-fill-mode: both;
    animation-timing-function: linear;
    background: #ebebeb;
    border-radius: 6px;
    color: gray;
    cursor: pointer;
    font-size: 14px;
    margin-top: 16px;
    opacity: 0;
    overflow: hidden;
    position: relative;
    width: 320px
}

.awn-toast-content {
    word-break: break-word
}

.awn-toast-label {
    color: gray;
    display: block;
    font-size: 18px;
    text-transform: uppercase
}

.awn-toast-icon {
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 16px;
    top: 6px
}

.awn-toast-icon .fa {
    color: gray;
    font-size: 44px
}

.awn-toast-wrapper {
    border: 2px solid #d1d1d1;
    border-radius: 6px;
    padding: 22px 88px 16px 16px
}

.awn-toast-progress-bar {
    height: 6px;
    left: 0;
    position: absolute;
    right: 0;
    top: 0
}

.awn-toast-progress-bar:after {
    animation-duration: inherit;
    animation-fill-mode: both;
    animation-name: awn-bar;
    animation-timing-function: linear;
    background: gray;
    content: " ";
    height: 6px;
    position: absolute;
    right: 100%;
    top: 0;
    width: 100%
}

.awn-toast.awn-toast-progress-bar-paused .awn-toast-progress-bar:after {
    animation-play-state: paused
}

.awn-toast.awn-hiding {
    animation-name: awn-fade-out !important
}

.awn-toast.awn-toast-success {
    background: #dff8d3;
    color: #40871d
}

.awn-toast.awn-toast-success .awn-toast-wrapper {
    border-color: #a7d590
}

.awn-toast.awn-toast-success .fa, .awn-toast.awn-toast-success b {
    color: #40871d
}

.awn-toast.awn-toast-success .awn-toast-progress-bar:after {
    background: #40871d
}

.awn-toast.awn-toast-info {
    background: #d3ebf8;
    color: #1c76a6
}

.awn-toast.awn-toast-info .awn-toast-wrapper {
    border-color: #9fd3ef
}

.awn-toast.awn-toast-info .fa, .awn-toast.awn-toast-info b {
    color: #1c76a6
}

.awn-toast.awn-toast-info .awn-toast-progress-bar:after {
    background: #1c76a6
}

.awn-toast.awn-toast-alert {
    background: #f8d5d3;
    color: #a92019
}

.awn-toast.awn-toast-alert .awn-toast-wrapper {
    border-color: #f0a29d
}

.awn-toast.awn-toast-alert .fa, .awn-toast.awn-toast-alert b {
    color: #a92019
}

.awn-toast.awn-toast-alert .awn-toast-progress-bar:after {
    background: #a92019
}

.awn-toast.awn-toast-warning {
    background: #ffe7cc;
    color: #c26700
}

.awn-toast.awn-toast-warning .awn-toast-wrapper {
    border-color: #ffc480
}

.awn-toast.awn-toast-warning .fa, .awn-toast.awn-toast-warning b {
    color: #c26700
}

.awn-toast.awn-toast-warning .awn-toast-progress-bar:after {
    background: #c26700
}

[class^=awn-] {
    box-sizing: border-box
}
.headerContentFsc {
    display: flex;
    justify-content: center;
    transform: translateY(-30vh);
}

.linkNSNS {
    text-decoration: underline;
    text-align: center;
    color: #e13019;
}

.titleSmall1 {
    font-size: 14px !important;
}

#loading {
    width: 90px;
    border-radius: 100% !important;
    margin: 0 auto;
    display: block;
    margin-top: -70px;
    position: relative;
    z-index: 5;
    display: block
}

#loading img {
    border-radius: 100% !important;
    filter: hue-rotate(212deg);
}

.myDiv {
    margin-top: 13vh;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
}

html,
body {
    font-family: 'Lato', sans-serif;
}

.wrapper {
    margin-left: -313px;
    display: inline-flex;
    height: 100px;
    width: 400px;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 5px;
    padding: 20px 15px;
    zoom: 0.8;
}

.wrapper .option {
    background: #fff;
    height: 100%;
    min-width: 128px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 0 10px;
    border-radius: 5px;
    cursor: pointer;
    padding: 0 10px;
    border: 2px solid lightgrey;
    transition: all 0.3s ease;
}

.wrapper .option .dot {
    height: 20px;
    width: 20px;
    background: #d9d9d9;
    border-radius: 50%;
    position: relative;
}

.wrapper .option .dot::before {
    position: absolute;
    content: "";
    top: 4px;
    left: 4px;
    width: 12px;
    height: 12px;
    background: #e13019;
    border-radius: 50%;
    opacity: 0;
    transform: scale(1.5);
    transition: all 0.3s ease;
}

input[type="radio"] {
    display: none;
}

#option-1:checked:checked~.option-1,
#option-2:checked:checked~.option-2,
#option-3:checked:checked~.option-3 {
    border-color: #e13019;
    background: #e13019;
}

#option-1:checked:checked~.option-1 .dot,
#option-2:checked:checked~.option-2 .dot,
#option-3:checked:checked~.option-3 .dot {
    background: #fff;
}

#option-1:checked:checked~.option-1 .dot::before,
#option-2:checked:checked~.option-2 .dot::before,
#option-3:checked:checked~.option-3 .dot::before {
    opacity: 1;
    transform: scale(1);
}

.wrapper .option span {
    font-size: 20px;
    color: #808080;
    margin-left: 10px;
}

#option-1:checked:checked~.option-1 span,
#option-2:checked:checked~.option-2 span,
#option-3:checked:checked~.option-3 span {
    color: #fff;
    margin-left: 10px;
}




#contentResultsIndex7 {
    display: none;
}

#contentResultsIndex9 {
    display: none;
}

.modalHomeSearch {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: #00000080;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 30;
    justify-content: center;
}

.modalHomeSearch .Content {
    background-color: white;
    padding: 70px;
    border-radius: 10px;
    width: 80%;
    height: 70%;
    overflow-y: scroll;
}

.modalHomeSearch .Content table {
    width: 100%;
    padding: 8px;
    border-radius: 10px;
    border-spacing: 0;
}

.modalHomeSearch .Content .CloseModalHome {
    display: flex;
    justify-content: flex-end;
    margin: -50px;
    padding: 0px;
    margin-bottom: 0px;
}

.modalHomeSearch .Content .CloseModalHome h1 {
    font-size: 20px;
    background-color: #E13019;
    font-family: 'Roboto';
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.FederalSupplyGroup {
    margin-top: 3vh;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
}

.FederalSupplyGroupContent {
    margin-top: 5vh;
    padding-bottom: 5vh;
    width: 95%;
    max-width: 980px;
    margin: 0 auto;
    background-color: #fff;
    text-align: left;
    font-weight: bold;
}

.FederalSupplyGroupContent h2 {
    padding-top: 3vh;
    text-align: center;
    padding-bottom: 3vh;
    background-color: #191736;
    color: white;
}

.FederalSupplyBlock {
    padding: 0 20px;
}
.FederalSupplyBlock li {
    margin-top: 0.2vh;
    margin-bottom: 0.2vh;
    padding-bottom: 3vh;
}

.modalHomeSearch .Content .CloseModalHome h1:hover {
    background-color: #E13019;
}

.modalHomeSearch .Content::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    /* width of the entire scrollbar */
}

.modalHomeSearch .Content::-webkit-scrollbar-track {
    background: rgb(255, 255, 255);
    /* color of the tracking area */
}

.modalHomeSearch .Content::-webkit-scrollbar-thumb {
    background-color: #E13019;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: 3px solid rgb(255, 255, 255);
    /* creates padding around scroll thumb */
}






.modalHomeSearch .Content table thead tr td {
    background-color: #E13019;
    padding: 10px;
    font-size: 17px;
    color: white;
}

.modalHomeSearch .Content table tbody tr td {
    padding: 11px;
    border-bottom: 1px solid #00000029;
}

/* slider --------------------------- */
.slider {
    height: 510px;
    width: 100%;
    overflow: hidden;
    margin: 0;
    margin-top: -12px;
    filter: brightness(0.6);
    animation: hidde 30s infinite alternate ease-in-out;
}

.slider ul {
    display: flex;
    padding: 0;
    width: 400%;
    animation: cambio 30s infinite alternate steps(1);
}

.slider li {
    width: 100%;
    list-style: none;
}

.slider img {
    width: 100%;
}

@keyframes hidde {
    0% {
        opacity: 1;
    }

    19% {
        opacity: 1;
    }

    25% {
        opacity: 0;
    }

    30% {
        opacity: 1;
    }

    45% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    55% {
        opacity: 1;
    }

    65% {
        opacity: 1;
    }

    70% {
        opacity: 1;
    }

    75% {
        opacity: 1;
    }

    100% {}
}

@keyframes cambio {
    0% {
        margin-left: 0;
    }

    20% {
        margin-left: 0;
    }

    25% {
        margin-left: -150%;
    }

    45% {
        margin-left: -150%;
    }

    50% {
        margin-left: -300%;
    }

    70% {
        margin-left: -300%;
    }

    75% {
        margin-left: -300%;
    }

    100% {
        margin-left: -300%;
    }
}



.headerContentFsc .child1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
}

.headerContentFsc .child1 h1 {
    color: white;
    font-size: 26px;
    margin: 15px;
}

.headerContentFsc .child1 div input {
    font-size: 20px;
    width: 481px;
    padding: 16px;
    border-radius: 6px;
    border: 0;
    margin-right: 15px;
}

.headerContentFsc .child1 div button {
    padding: 20px;
    background-color: #e13019;
    border: 0;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.2s;
    font-weight: 700;
    font-size: 14px;
    position: relative;
    top: -2px;
}

.headerContentFsc .child1 div button:hover {
    color: #E13019;
    background-color: white;
}

.headerContentFsc .child2 {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.headerContentFsc .child2 .subChild1 {
    display: flex;
    flex-direction: column;
    background-color: #181634;
    color: white;
    padding: 40px;
    border-radius: 10px;
    position: relative;
    top: 100px;
    width: 450px;
    z-index: 11;
}

.headerContentFsc .child2 .subChild1 h1 {
    text-align: center;
    color: white;
    font-size: 25px;
    margin-top: 0px;
    margin-bottom: 50px;
    font-weight: bolder;
}

.headerContentFsc .child2 .subChild1 span {
    color: white;
    font-size: 13px;
    margin: 8px;
    margin-left: 0px;
    font-weight: bolder;
}

.headerContentFsc .child2 .subChild1 input {
    padding: 10px;
    background-color: #2c2a4a;
    border: 0px;
    border-radius: 7px;
    margin-bottom: 15px;
    color: white;
}

.headerContentFsc .child2 .subChild1 button {
    padding: 10px;
    background-color: #E13019;
    color: white;
    border-radius: 6px;
    border: 0px;
    margin-top: 19px;
    cursor: pointer;
    transition: 0.2s;
}

.headerContentFsc .child2 .subChild1 button:hover {
    color: #E13019;
    background-color: white;
}

.FilterResultsContent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.sliderClass {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    overflow-y: scroll;
    background-color: white;
    margin: 30px;
    margin-top: 6px;
    flex-wrap: wrap;
    justify-content: center;
    height: 340px;
}

.sliderClass::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    /* width of the entire scrollbar */
}

.sliderClass::-webkit-scrollbar-track {
    background: rgb(255, 255, 255);
    /* color of the tracking area */
}

.sliderClass::-webkit-scrollbar-thumb {
    background-color: #E13019;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: 3px solid rgb(255, 255, 255);
    /* creates padding around scroll thumb */
}





.sliderClass .contentSliderClass {
    background-color: white;
    margin: 13px;
    width: 280px;
    min-width: 280px;
    box-shadow: 0px 0px 11px 0px #0000001c;
    border-radius: 10px;
    margin-bottom: 60px;
    margin-top: 40px;
    cursor: pointer;
    position: relative;
}

.sliderClass .contentSliderClass:hover h1 {
    background-color: #E13019;
}

.sliderClass .contentSliderClass h1 {
    background-color: #475b70;
    text-align: center;
    font-size: 24px;
    padding: 9px;
    color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.sliderClass .contentSliderClass h2 {
    text-align: center;
    font-size: 18px;
    padding-inline: 14px;
}

.sliderClass .contentSliderClass p {
    padding: 14px;
    text-align: center;
    font-size: 14px;
}

.sliderClass .contentSliderClass h3 {
    position: absolute;
    margin: 0 auto;
    left: 43%;
    top: 93%;
    background-color: #E13019;
    width: 41px;
    height: 41px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-family: monospace;
    border-radius: 5px;
    color: white;
}


.FilterResultsContent .contentResults {
    background-color: white;
    width: 270px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-shadow: 0px 0px 15px 0px #0000001c;
    border-radius: 10px;
    margin: 20px;
    margin-bottom: 60px;
    height: 290px;
    position: relative;
}

.FilterResultsContent .contentResults h1 {
    background-color: #475b70;
    width: 100%;
    text-align: center;
    font-size: 23px;
    padding: 12px;
    color: white;
    border-radius: 10px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.FilterResultsContent .contentResults h2 {
    width: 100%;
    text-align: center;
    margin: 7px;
    font-size: 20px;
    padding-inline: 20px;
    font-family: system-ui;
    font-weight: bold;
}

.navigateSmall h1 {
    font-size: 26px;
    margin: 20px;
    font-weight: 500;
    margin-top: -106px;
    margin-bottom: 230px;
    max-width: 730px;
}

.navigateSmall {
    position: relative;
    z-index: 6;
}

.navigateSmall span {
    color: #E13019;
}

.navigateSmall span:hover {
    text-decoration: underline;
    cursor: pointer;
}

.NotFoundItemText {
    font-size: 21px;
    background-color: #E13019;
    padding: 12px;
    border-radius: 8px;
    color: white;
}

.FilterResultsContent .contentResults p {
    padding: 20px;
    text-align: center;
    font-size: 13px;
}

.FilterResultsContent .contentResults button {
    width: 40px;
    background-color: #E13019;
    border: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    position: absolute;
    top: 241px;
    height: 40px;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    transition: 0.2s;
    margin-left: -17px;
}

.FilterResultsContent .contentResults button:hover {
    top: 238px;
}

/* modal Fsc ------------------------------- */
.FilterResultsContent .modalInfoFsc {
    position: fixed;
    background-color: #000000b5;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 4;
    display: none;
    align-items: center;
    justify-content: center;
}

.FilterResultsContent .modalInfoFscActive {
    position: fixed;
    background-color: #000000b5;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 30;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tableParts {
    background-color: white;
    width: 100%;
    padding: 20px;
    border-spacing: 0px;
    /* border: 1px solid #DDDDDD; */
}

.tableParts thead tr td {
    background-color: #E13019;
    text-align: center;
    padding: 8px;
    font-size: 14px;

    color: white;
}

.tableParts thead tr th {
    background-color: #F4F4F4;
    text-align: center;
    padding: 8px;
    font-size: 14px;
    color: #666666;
}

.tableParts tbody tr td {
    border: 1px solid #DDDDDD;
    padding: 11px;
}

.tableITEM_Description {
    background-color: white;
    width: 100%;
    padding: 20px;
    border-spacing: 0px;
    border: 1px solid #DDDDDD;
}

.tableITEM_Description tbody tr th {
    background-color: #F4F4F4;
    text-align: left;
    padding: 8px;
    font-size: 14px;
    color: #666666;
    border: 1px solid #DDDDDD;
}

.tableITEM_Description tbody tr td {
    padding: 11px;
    border: 1px solid #DDDDDD;
}



.FilterResultsContent .ContentModalFilter {
    background-color: white;
    width: 1190px;
    height: 680px;
    border-radius: 10px;
    overflow-y: scroll;
    scroll-behavior: smooth !important;
}

.FilterResultsContent .ContentModalFilter::-webkit-scrollbar {
    width: 10px;
    /* width of the entire scrollbar */
}

.FilterResultsContent .ContentModalFilter::-webkit-scrollbar-track {
    background: rgb(255, 255, 255);
    /* color of the tracking area */
}

.FilterResultsContent .ContentModalFilter::-webkit-scrollbar-thumb {
    background-color: #E13019;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: 3px solid rgb(255, 255, 255);
    /* creates padding around scroll thumb */
}






.FilterResultsContent .ContentModalFilter .exitContent {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.FilterResultsContent .ContentModalFilter .exitContent h1 {
    font-size: 13px;
    background-color: #475b70;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 11px;
    border-radius: 100%;
    font-family: cursive;
    color: white;
    cursor: pointer;
}

.FilterResultsContent .ContentModalFilter .titleContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.FilterResultsContent .ContentModalFilter .titleContent h1 {
    margin: 0px;
    font-size: 24px;
}

.FilterResultsContent .ContentModalFilter .titleContent div {
    background-color: #E13019;
    width: 110px;
    height: 3px;
    margin-top: 10px;
    margin-bottom: 10px;
    opacity: 0;
}

.FilterResultsContent .ContentModalFilter .titleContent h2 {
    font-size: 18px;
    width: 510px;
    text-align: center;
    font-weight: lighter;
}

.FilterResultsContent .ContentModalFilter .contentTable {
    padding: 30px;
    padding-top: 0px;
}

.FilterResultsContent .ContentModalFilter .contentTable table {
    width: 100%;
    text-align: left;
    border-collapse: collapse;
}

.FilterResultsContent .ContentModalFilter .contentTable table td {
    padding: 3px;
}

.FilterResultsContent .ContentModalFilter .contentTable table tbody td {
    width: 0%;
    font-size: 10px;
    padding: 11px;
    padding-left: 20px;
}

.FilterResultsContent .ContentModalFilter .contentTable table tbody td span {
    background-color: #E13019;
    padding: 10px;
    color: white;
    padding-inline: 30px;
    border-radius: 7px;
    cursor: pointer;
    transition: 0.2s;
}

.FilterResultsContent .ContentModalFilter .contentTable table tbody td span:hover {
    color: #E13019;
    background-color: rgb(223, 223, 223);
}

.FilterResultsContent .ContentModalFilter .contentTable table thead tr td {
    background-color: #E13019;
    color: white;
    padding-left: 20px;
}

.FilterResultsContent .ContentModalFilter .contentTable table thead .trColor1 {
    background-color: #191736 !important;
}

.FilterResultsContent .ContentModalFilter .contentTable table tbody tr:nth-child(even) {
    background-color: #f3f3f3;
    color: #898989;
}

.errorNoItem {
    color: #E13019;
    font-size: 21px;
    background-color: #f3f3f3;
    padding: 13px;
    padding-inline: 90px;
    border-radius: 8px;
    margin-top: 80px;
}

@media screen and (max-width:1186px) {
    .headerContentFsc {
        background-size: cover;
        height: 510px;
        background-position: bottom;
        display: flex;
        flex-direction: column;
        padding: 30px;
        margin-top: -400px;
        position: relative;
        align-items: center;
    }

    .headerContentFsc .child2 {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: -50px;
    }

    .headerContentFsc .child2 .subChild1 {
        display: flex;
        flex-direction: column;
        background-color: #181634;
        color: white;
        padding: 40px;
        border-radius: 10px;
        position: relative;
        top: 100px;
        width: 97%;
    }

    .headerContentFsc .child1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .slider img {
        min-width: 1460px;
        height: 530px;
    }

    .slider li {
        width: 100%;
        list-style: none;
        overflow: hidden;
    }
}

@media screen and (max-width:944px) {
    .FilterResultsContent .ContentModalFilter {
        background-color: white;
        width: 96%;
        height: 680px;
        border-radius: 10px;
        overflow-y: scroll;
    }

    .menuFixMargin {
        margin-top: -42px;
    }
}

@media screen and (max-width:648px) {
    .wrapper {
        margin-left: 0px;
        position: relative;
        z-index: 3;
    }
}

@media screen and (max-width:584px) {
    .contentBtnHeaderFsc {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: center;
        width: 100%;
    }

    .headerContentFsc .child1 div button {
        width: 100%;
    }

    .headerContentFsc .child1 div input {
        width: 100%;
        padding: 11px;
        border-radius: 6px;
        border: 0px;
        margin-right: 15px;
        margin-bottom: 12px;
    }

    .headerContentFsc .child1 h1 {
        color: white;
        font-size: 26px;
        margin: 15px;
        text-align: center;
    }

    .FilterResultsContent .ContentModalFilter .contentTable table {
        width: 100%;
        text-align: left;
        border-collapse: collapse;
        min-width: 720px;
    }
}

@media screen and (max-width:412px) {
    .headerContentFsc .child2 .subChild1 {
        display: flex;
        flex-direction: column;
        background-color: #181634;
        color: white;
        padding: 40px;
        border-radius: 10px;
        position: relative;
        top: 100px;
        width: 110%;
    }

    .FilterResultsContent .ContentModalFilter {
        background-color: white;
        width: 96%;
        height: 580px;
        border-radius: 10px;
        overflow-y: scroll;
    }

    .FederalSupplyGroup {
        margin-top: 3vh;
        text-align: center;
        font-size: 30px;
    }

    .navigateSmall h1 {
        font-size: 16px;
        margin: 20px;
        font-weight: 500;
        margin-top: -156px;
        margin-bottom: 40px;
    }

    .navigateSmallFix1 h1 {
        font-size: 16px !important;
        margin: 20px !important;
        font-weight: 500 !important;
        margin-top: -76px !important;
        margin-bottom: -40px !important;
    }

    .navigateSmallFix2 h1 {
        font-size: 16px !important;
        margin: 20px !important;
        font-weight: 500 !important;
        margin-top: -76px !important;
        margin-bottom: 50px !important;
    }

    .FilterResultsContentMovil {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
    }

    .tableParts {
        background-color: white;
        min-width: 980px;
        padding: 20px;
        border-spacing: 0px;
    }
}

footer {
    margin-top: 4vh;
}

.rfqButton {
    padding: 20px;
    background-color: #e13019;
    border: 0;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.2s;
    font-weight: 700;
    font-size: 14px;
    position: relative;
    top: -2px;
}
.HeaderContact {
  margin-bottom: 45px;
}

.HeaderContact .headerContact {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 360px;
  background-size: cover;
  margin-top: -360px;
  position: relative;
}

/* slider --------------------------- */
.sliderContactUs {
  height: 360px;
  width: 100%;
  overflow: hidden;
  margin: 0px;
  margin-top: -12px !important;
  filter: brightness(0.6);
  animation: hidde 30s infinite alternate ease-in-out;
}

.sliderContactUs ul {
  display: flex;
  padding: 0;
  width: 400%;
  animation: cambio 30s infinite alternate steps(1);
}

.sliderContactUs li {
  width: 100%;
  list-style: none;
}

.sliderContactUs img {
  width: 100%;
}

@keyframes hidde {
  0% {
    opacity: 1;
  }

  19% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  45% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  55% {
    opacity: 1;
  }

  65% {
    opacity: 1;
  }

  70% {
    opacity: 1;
  }

  75% {
    opacity: 1;
  }

  100% {
  }
}

@keyframes cambio {
  0% {
    margin-left: 0;
  }

  20% {
    margin-left: 0;
  }

  25% {
    margin-left: -150%;
  }

  45% {
    margin-left: -150%;
  }

  50% {
    margin-left: -300%;
  }

  70% {
    margin-left: -300%;
  }

  75% {
    margin-left: -300%;
  }

  100% {
    margin-left: -300%;
  }
}

.HeaderContact .headerContact h1 {
  color: white;
  font-size: 40px;
  font-weight: bold;
  margin-top: -20px;
}

.HeaderContact .headerContact h2 {
  text-align: center;
  color: white;
  width: 500px;
  font-size: 25px;
  font-weight: bolder;
}

.HeaderContact .headerContact button {
  background-color: #D1422F;
  color: white;
  padding: 14px;
  font-size: 17px;
  border: 0px;
  padding-inline: 50px;
  border-radius: 6px;
  cursor: pointer;
  outline: none;
  transition: 0.2s;
}

.myInput input {
  display: none;
}

.myInput label {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #D1422F;
  color: white;
  padding: 14px;
  font-size: 17px;
  border: 0px;
  padding-inline: 50px;
  border-radius: 6px;
  cursor: pointer;
  outline: none;
  transition: 0.2s;
  text-align: center;
}

.myInput label img {
  width: 50px;
  height: 50px;
}

.HeaderContact .headerContact button:hover {
  color: #D1422F;
  background-color: white;
}

/* ContactFormComp ----------------------------- */

.ContactFormCompComponent .Contact {
  padding: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  background-color: white;
  position: relative;
  border-radius: 10px;
}

.ContactFormCompComponent .Contact .lastChild {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}

.ContactFormCompComponent .Contact .lastChild .contentInputs {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-right: 20px;
  margin-bottom: 20px;
}

.ContactFormCompComponent .Contact .lastChild .contentInputs2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 20px;
  margin-bottom: 20px;
}

.ContactFormCompComponent .Contact .lastChild .contentInputs3 {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.ContactFormCompComponent .Contact .lastChild .contentInputs input {
  padding: 7px;
  border-radius: 5px;
  border: 2px solid #cdcdcd;
}

.ContactFormCompComponent .Contact .lastChild .contentInputs span {
  font-size: 12px;
  margin-bottom: 7px;
  color: #000000;
  font-weight: 400;
}

.ContactFormCompComponent .Contact .lastChild .contentInputs2 span {
  font-size: 12px;
  margin-bottom: 7px;
  color: #000000;
  font-weight: 400;
}

.ContactFormCompComponent .Contact .lastChild .contentInputs2 input {
  padding: 7px;
  border-radius: 5px;
  border: 2px solid #cdcdcd;
}

.ContactFormCompComponent .Contact .lastChild .contentInputs3 span {
  font-size: 12px;
  margin-bottom: 7px;
  color: #000000;
  font-weight: 400;
}

.ContactFormCompComponent .Contact .lastChild .contentInputs3 textarea {
  padding: 7px;
  border-radius: 5px;
  border: 2px solid #cdcdcd;
  min-height: 150px;
  max-height: 150px;
  width: 97%;
  min-width: 97%;
  max-width: 97%;
}

.ContactFormCompComponent {
  background-color: #f3f3f3;
  padding-top: 1px;
  margin: 340px;
  margin-bottom: 90px;
  margin-top: -90px;
  border-radius: 10px;
  box-shadow: 0px 0px 18px 0px #00000036;
}

.ContactFormCompComponent .Submit {
  width: 97%;
  padding: 10px;
  background-color: #D1422F;
  color: white;
  border: 0px;
  margin-top: 21px;
  border-radius: 6px;
  cursor: pointer;
  transition: 0.2s;
  text-align: center;
}

.ContactFormCompComponent .Submit:hover {
  color: #D1422F;
  background-color: white;
}

.MyContactForm {
  background-color: #f3f3f3;
  padding-top: 1px;
  border-radius: 10px;
  box-shadow: 0px 0px 18px 0px #00000036;
}

.MyContactForm .Submit {
  width: 97%;
  padding: 10px;
  background-color: #D1422F;
  color: white;
  border: 0px;
  margin-top: 21px;
  border-radius: 6px;
  cursor: pointer;
  transition: 0.2s;
  text-align: center;
}

.MyContactForm .Submit:hover {
  color: #D1422F;
  background-color: white;
}

@media screen and (max-width: 920px) {
  .sliderContactUs li {
    width: 100%;
    list-style: none;
    overflow: hidden;
  }

  .sliderContactUs img {
    min-width: 1270px;
    height: 390px;
  }
}

@media screen and (max-width: 1158px) {
  .ContactFormCompComponent {
    background-color: #f3f3f3;
    padding-top: 1px;
    margin: 60px;
    margin-top: -90px;
    border-radius: 10px;
    box-shadow: 0px 0px 18px 0px #00000036;
  }

  .MyContactForm {
    background-color: #f3f3f3;
    padding-top: 1px;
    border-radius: 10px;
    box-shadow: 0px 0px 18px 0px #00000036;
  }
}

@media screen and (max-width: 412px) {
  .ContactFormCompComponent {
    background-color: #f3f3f3;
    padding-top: 1px;
    margin: 10px;
    margin-top: -90px;
    border-radius: 10px;
    box-shadow: 0px 0px 18px 0px #00000036;
  }

  .MyContactForm {
    background-color: #f3f3f3;
    padding-top: 1px;
    border-radius: 10px;
    box-shadow: 0px 0px 18px 0px #00000036;
  }

  .ContactFormCompComponent .Contact {
    padding: 35px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    background-color: white;
    position: relative;
    border-radius: 10px;
  }

  .MyContactForm .Contact {
    padding: 35px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    background-color: white;
    position: relative;
    border-radius: 10px;
  }

  .ContactFormCompComponent .Contact .lastChild {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .MyContactForm .Contact .lastChild {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .ContactFormCompComponent .Contact .lastChild .contentInputs {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 0px;
    margin-bottom: 20px;
  }

  .MyContactForm .Contact .lastChild .contentInputs {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 0px;
    margin-bottom: 20px;
  }

  .HeaderContact .headerContact h2 {
    text-align: center;
    color: white;
    width: 100%;
    font-size: 25px;
    font-weight: lighter;
  }

  .ContactFormCompComponent .Contact .lastChild .contentInputs2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 0px;
    margin-bottom: 20px;
  }

  .MyContactForm .Contact .lastChild .contentInputs2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 0px;
    margin-bottom: 20px;
  }

  .ContactFormCompComponent .Contact .lastChild .contentInputs3 textarea {
    padding: 7px;
    border-radius: 5px;
    border: 2px solid #cdcdcd;
    min-height: 150px;
    max-height: 150px;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }

  .MyContactForm .Contact .lastChild .contentInputs3 textarea {
    padding: 7px;
    border-radius: 5px;
    border: 2px solid #cdcdcd;
    min-height: 150px;
    max-height: 150px;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }

  .ContactFormCompComponent .Submit {
    width: 100%;
    padding: 10px;
    background-color: #D1422F;
    color: white;
    border: 0px;
    margin-top: 21px;
    border-radius: 6px;
    cursor: pointer;
    transition: 0.2s;
  }

  .MyContactForm .Submit {
    width: 100%;
    padding: 10px;
    background-color: #D1422F;
    color: white;
    border: 0px;
    margin-top: 21px;
    border-radius: 6px;
    cursor: pointer;
    transition: 0.2s;
  }
}

input.invalid,
select.invalid {
  border: red solid 2px !important;
}

.headerContentFsc {
    background-size: cover;
    height: 510px;
    background-position: bottom;
    display: flex;
    flex-direction: row;
    padding: 30px;
    margin-top: -510px;
    position: relative;
}

.linkNSNS {
    text-decoration: underline;
    text-align: center;
    color: #e13019;
}

.titleSmall1 {
    font-size: 14px !important;
}

.myOl {
    list-style-type: decimal;
    width: 70% !important;
    align-items: center;
    justify-content: center;
}

.myRow {
    display: flex;
    flex-direction: row;
}

.myOl li {
    float: left;
    width: 50px;
    padding: 2px 0px;
    list-style: none;
    color: black;
}

.myOl a li:hover {
    float: left;
    width: 50px;
    padding: 2px 0px;
    list-style: none;
    background-color: rgb(196, 15, 15) !important;
    color: white !important;
    text-decoration: none;
}


#loading {
    width: 90px;
    border-radius: 100% !important;
    margin: 0 auto;
    display: block;
    margin-top: -70px;
    position: relative;
    z-index: 5;
    display: block
}

#loading img {
    border-radius: 100% !important;
    filter: hue-rotate(212deg);
}

.myDiv {
    margin-top: 13vh;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
}

html,
body {
    font-family: 'Lato', sans-serif;
}

.wrapper {
    margin-left: -313px;
    display: inline-flex;
    height: 100px;
    width: 400px;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 5px;
    padding: 20px 15px;
    zoom: 0.8;
}

.wrapper .option {
    background: #fff;
    height: 100%;
    min-width: 128px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 0 10px;
    border-radius: 5px;
    cursor: pointer;
    padding: 0 10px;
    border: 2px solid lightgrey;
    transition: all 0.3s ease;
}

.wrapper .option .dot {
    height: 20px;
    width: 20px;
    background: #d9d9d9;
    border-radius: 50%;
    position: relative;
}

.wrapper .option .dot::before {
    position: absolute;
    content: "";
    top: 4px;
    left: 4px;
    width: 12px;
    height: 12px;
    background: #e13019;
    border-radius: 50%;
    opacity: 0;
    transform: scale(1.5);
    transition: all 0.3s ease;
}

input[type="radio"] {
    display: none;
}

#option-1:checked:checked~.option-1,
#option-2:checked:checked~.option-2,
#option-3:checked:checked~.option-3 {
    border-color: #e13019;
    background: #e13019;
}

#option-1:checked:checked~.option-1 .dot,
#option-2:checked:checked~.option-2 .dot,
#option-3:checked:checked~.option-3 .dot {
    background: #fff;
}

#option-1:checked:checked~.option-1 .dot::before,
#option-2:checked:checked~.option-2 .dot::before,
#option-3:checked:checked~.option-3 .dot::before {
    opacity: 1;
    transform: scale(1);
}

.wrapper .option span {
    font-size: 20px;
    color: #808080;
    margin-left: 10px;
}

#option-1:checked:checked~.option-1 span,
#option-2:checked:checked~.option-2 span,
#option-3:checked:checked~.option-3 span {
    color: #fff;
    margin-left: 10px;
}




#contentResultsIndex7 {
    display: none;
}

#contentResultsIndex9 {
    display: none;
}

.modalHomeSearch {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: #00000080;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 30;
    justify-content: center;
}

.modalHomeSearch .Content {
    background-color: white;
    padding: 70px;
    border-radius: 10px;
    width: 80%;
    height: 70%;
    overflow-y: scroll;
}

.modalHomeSearch .Content table {
    width: 100%;
    padding: 8px;
    border-radius: 10px;
    border-spacing: 0;
}

.modalHomeSearch .Content .CloseModalHome {
    display: flex;
    justify-content: flex-end;
    margin: -50px;
    padding: 0px;
    margin-bottom: 0px;
}

.modalHomeSearch .Content .CloseModalHome h1 {
    font-size: 20px;
    background-color: #E13019;
    font-family: 'Roboto';
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.FederalSupplyGroup {
    margin-top: 3vh;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
}

.modalHomeSearch .Content .CloseModalHome h1:hover {
    background-color: #E13019;
}

.modalHomeSearch .Content::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    /* width of the entire scrollbar */
}

.modalHomeSearch .Content::-webkit-scrollbar-track {
    background: rgb(255, 255, 255);
    /* color of the tracking area */
}

.modalHomeSearch .Content::-webkit-scrollbar-thumb {
    background-color: #E13019;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: 3px solid rgb(255, 255, 255);
    /* creates padding around scroll thumb */
}






.modalHomeSearch .Content table thead tr td {
    background-color: #E13019;
    padding: 10px;
    font-size: 17px;
    color: white;
}

.modalHomeSearch .Content table tbody tr td {
    padding: 11px;
    border-bottom: 1px solid #00000029;
}

/* slider --------------------------- */
.slider {
    height: 510px;
    width: 100%;
    overflow: hidden;
    margin: 0;
    margin-top: -12px;
    filter: brightness(0.6);
    animation: hidde 30s infinite alternate ease-in-out;
}

.slider ul {
    display: flex;
    padding: 0;
    width: 400%;
    animation: cambio 30s infinite alternate steps(1);
}

.slider li {
    width: 100%;
    list-style: none;
}

.slider img {
    width: 100%;
}

@keyframes hidde {
    0% {
        opacity: 1;
    }

    19% {
        opacity: 1;
    }

    25% {
        opacity: 0;
    }

    30% {
        opacity: 1;
    }

    45% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    55% {
        opacity: 1;
    }

    65% {
        opacity: 1;
    }

    70% {
        opacity: 1;
    }

    75% {
        opacity: 1;
    }

    100% {}
}

@keyframes cambio {
    0% {
        margin-left: 0;
    }

    20% {
        margin-left: 0;
    }

    25% {
        margin-left: -150%;
    }

    45% {
        margin-left: -150%;
    }

    50% {
        margin-left: -300%;
    }

    70% {
        margin-left: -300%;
    }

    75% {
        margin-left: -300%;
    }

    100% {
        margin-left: -300%;
    }
}



.headerContentFsc .child1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
}

.headerContentFsc .child1 h1 {
    color: white;
    margin: 15px;
}

.headerContentFsc .child1 div input {
    font-size: 20px;
    width: 481px;
    padding: 16px;
    border-radius: 6px;
    border: 0;
    margin-right: 15px;
}

.headerContentFsc .child1 div button {
    padding: 20px;
    background-color: #e13019;
    border: 0;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.2s;
    font-weight: 700;
    font-size: 14px;
    position: relative;
    top: -2px;
}

.headerContentFsc .child1 div button:hover {
    color: #E13019;
    background-color: white;
}

.headerContentFsc .child2 {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.headerContentFsc .child2 .subChild1 {
    display: flex;
    flex-direction: column;
    background-color: #181634;
    color: white;
    padding: 40px;
    border-radius: 10px;
    position: relative;
    top: 100px;
    width: 450px;
    z-index: 11;
}

.headerContentFsc .child2 .subChild1 h1 {
    text-align: center;
    color: white;
    font-size: 25px;
    margin-top: 0px;
    margin-bottom: 50px;
    font-weight: bolder;
}

.headerContentFsc .child2 .subChild1 span {
    color: white;
    font-size: 13px;
    margin: 8px;
    margin-left: 0px;
    font-weight: bolder;
}

.headerContentFsc .child2 .subChild1 input {
    padding: 10px;
    background-color: #2c2a4a;
    border: 0px;
    border-radius: 7px;
    margin-bottom: 15px;
    color: white;
}

.headerContentFsc .child2 .subChild1 button {
    padding: 10px;
    background-color: #E13019;
    color: white;
    border-radius: 6px;
    border: 0px;
    margin-top: 19px;
    cursor: pointer;
    transition: 0.2s;
}

.headerContentFsc .child2 .subChild1 button:hover {
    color: #E13019;
    background-color: white;
}

.FilterResultsContent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.sliderClass {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    overflow-y: scroll;
    background-color: white;
    margin: 30px;
    margin-top: 6px;
    flex-wrap: wrap;
    justify-content: center;
    height: 340px;
}

.sliderClass::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    /* width of the entire scrollbar */
}

.sliderClass::-webkit-scrollbar-track {
    background: rgb(255, 255, 255);
    /* color of the tracking area */
}

.sliderClass::-webkit-scrollbar-thumb {
    background-color: #E13019;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: 3px solid rgb(255, 255, 255);
    /* creates padding around scroll thumb */
}





.sliderClass .contentSliderClass {
    background-color: white;
    margin: 13px;
    width: 280px;
    min-width: 280px;
    box-shadow: 0px 0px 11px 0px #0000001c;
    border-radius: 10px;
    margin-bottom: 60px;
    margin-top: 40px;
    cursor: pointer;
    position: relative;
}

.sliderClass .contentSliderClass:hover h1 {
    background-color: #E13019;
}

.sliderClass .contentSliderClass h1 {
    background-color: #475b70;
    text-align: center;
    font-size: 24px;
    padding: 9px;
    color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.sliderClass .contentSliderClass h2 {
    text-align: center;
    font-size: 18px;
    padding-inline: 14px;
}

.sliderClass .contentSliderClass p {
    padding: 14px;
    text-align: center;
    font-size: 14px;
}

.sliderClass .contentSliderClass h3 {
    position: absolute;
    margin: 0 auto;
    left: 43%;
    top: 93%;
    background-color: #E13019;
    width: 41px;
    height: 41px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-family: monospace;
    border-radius: 5px;
    color: white;
}


.FilterResultsContent .contentResults {
    background-color: white;
    width: 270px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-shadow: 0px 0px 15px 0px #0000001c;
    border-radius: 10px;
    margin: 20px;
    margin-bottom: 60px;
    height: 290px;
    position: relative;
}

.FilterResultsContent .contentResults h1 {
    background-color: #475b70;
    width: 100%;
    text-align: center;
    font-size: 23px;
    padding: 12px;
    color: white;
    border-radius: 10px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.FilterResultsContent .contentResults h2 {
    width: 100%;
    text-align: center;
    margin: 7px;
    font-size: 20px;
    padding-inline: 20px;
    font-family: system-ui;
    font-weight: bold;
}

.navigateSmall h1 {
    margin: 20px;
    font-weight: 500;
    margin-top: -106px;
    margin-bottom: 230px;
    max-width: 730px;
}

.navigateSmall {
    position: relative;
    z-index: 6;
}

.navigateSmall span {
    color: #E13019;
}

.navigateSmall span:hover {
    text-decoration: underline;
    cursor: pointer;
}

.NotFoundItemText {
    font-size: 21px;
    background-color: #E13019;
    padding: 12px;
    border-radius: 8px;
    color: white;
}

.FilterResultsContent .contentResults p {
    padding: 20px;
    text-align: center;
    font-size: 13px;
}

.FilterResultsContent .contentResults button {
    width: 40px;
    background-color: #E13019;
    border: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    position: absolute;
    top: 241px;
    height: 40px;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    transition: 0.2s;
    margin-left: -17px;
}

.FilterResultsContent .contentResults button:hover {
    top: 238px;
}

/* modal Fsc ------------------------------- */
.FilterResultsContent .modalInfoFsc {
    position: fixed;
    background-color: #000000b5;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 4;
    display: none;
    align-items: center;
    justify-content: center;
}

.FilterResultsContent .modalInfoFscActive {
    position: fixed;
    background-color: #000000b5;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 30;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tableParts {
    background-color: white;
    width: 100%;
    padding: 20px;
    border-spacing: 0px;
    /* border: 1px solid #DDDDDD; */

}

.tableParts thead tr td {
    background-color: #E13019;
    text-align: center;
    padding: 8px;
    font-size: 14px;

    color: white;
}

.tableParts thead tr th {
    background-color: #F4F4F4;
    text-align: center;
    padding: 8px;
    font-size: 14px;
    color: #666666;
}

.tableParts tbody tr td {
    border: 1px solid #DDDDDD;
    padding: 11px;
}

/* .tableITEM_Description {
    background-color: white;
    width: 100%;
    padding: 20px;
    border-spacing: 0px;
    border: 1px solid #DDDDDD;
} */

.tableITEM_Description {
    background-color: white;
    width: 100%;
    /* padding: 318px; */
    border-spacing: 0px;
    border: 1px solid #DDDDDD;
    /* padding-left: 318px;
    padding-right: 318px;
    padding-bottom: 10px; */
}

.tableITEM_Description tbody tr th {
    background-color: #F4F4F4;
    text-align: left;
    padding: 8px;
    font-size: 14px;
    color: #666666;
    border: 1px solid #DDDDDD;
}

.tableITEM_Description tbody tr td {
    padding: 11px;
    border: 1px solid #DDDDDD;
}



.FilterResultsContent .ContentModalFilter {
    background-color: white;
    width: 1190px;
    height: 680px;
    border-radius: 10px;
    overflow-y: scroll;
    scroll-behavior: smooth !important;
}

.FilterResultsContent .ContentModalFilter::-webkit-scrollbar {
    width: 10px;
    /* width of the entire scrollbar */
}

.FilterResultsContent .ContentModalFilter::-webkit-scrollbar-track {
    background: rgb(255, 255, 255);
    /* color of the tracking area */
}

.FilterResultsContent .ContentModalFilter::-webkit-scrollbar-thumb {
    background-color: #E13019;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: 3px solid rgb(255, 255, 255);
    /* creates padding around scroll thumb */
}






.FilterResultsContent .ContentModalFilter .exitContent {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.FilterResultsContent .ContentModalFilter .exitContent h1 {
    font-size: 13px;
    background-color: #475b70;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 11px;
    border-radius: 100%;
    font-family: cursive;
    color: white;
    cursor: pointer;
}

.FilterResultsContent .ContentModalFilter .titleContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.FilterResultsContent .ContentModalFilter .titleContent h1 {
    margin: 0px;
    font-size: 24px;
}

.FilterResultsContent .ContentModalFilter .titleContent div {
    background-color: #E13019;
    width: 110px;
    height: 3px;
    margin-top: 10px;
    margin-bottom: 10px;
    opacity: 0;
}

.FilterResultsContent .ContentModalFilter .titleContent h2 {
    font-size: 18px;
    width: 510px;
    text-align: center;
    font-weight: lighter;
}

.FilterResultsContent .ContentModalFilter .contentTable {
    padding: 30px;
    padding-top: 0px;
}

.FilterResultsContent .ContentModalFilter .contentTable table {
    width: 100%;
    text-align: left;
    border-collapse: collapse;
}

.FilterResultsContent .ContentModalFilter .contentTable table td {
    padding: 3px;
}

.FilterResultsContent .ContentModalFilter .contentTable table tbody td {
    width: 0%;
    font-size: 10px;
    padding: 11px;
    padding-left: 20px;
}

.FilterResultsContent .ContentModalFilter .contentTable table tbody td span {
    background-color: #E13019;
    padding: 10px;
    color: white;
    padding-inline: 30px;
    border-radius: 7px;
    cursor: pointer;
    transition: 0.2s;
}

.FilterResultsContent .ContentModalFilter .contentTable table tbody td span:hover {
    color: #E13019;
    background-color: rgb(223, 223, 223);
}

.FilterResultsContent .ContentModalFilter .contentTable table thead tr td {
    background-color: #E13019;
    color: white;
    padding-left: 20px;
}

.FilterResultsContent .ContentModalFilter .contentTable table thead .trColor1 {
    background-color: #191736 !important;
}

.FilterResultsContent .ContentModalFilter .contentTable table tbody tr:nth-child(even) {
    background-color: #f3f3f3;
    color: #898989;
}

.errorNoItem {
    color: #E13019;
    font-size: 21px;
    background-color: #f3f3f3;
    padding: 13px;
    padding-inline: 90px;
    border-radius: 8px;
    margin-top: 80px;
}

@media screen and (max-width:1186px) {
    .headerContentFsc {
        background-size: cover;
        height: 510px;
        background-position: bottom;
        display: flex;
        flex-direction: column;
        padding: 30px;
        margin-top: -400px;
        position: relative;
        align-items: center;
    }

    .headerContentFsc .child2 {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: -50px;
    }

    .headerContentFsc .child2 .subChild1 {
        display: flex;
        flex-direction: column;
        background-color: #181634;
        color: white;
        padding: 40px;
        border-radius: 10px;
        position: relative;
        top: 100px;
        width: 97%;
    }

    .headerContentFsc .child1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .slider img {
        min-width: 1460px;
        height: 530px;
    }

    .slider li {
        width: 100%;
        list-style: none;
        overflow: hidden;
    }
}

@media screen and (max-width:944px) {
    .FilterResultsContent .ContentModalFilter {
        background-color: white;
        width: 96%;
        height: 680px;
        border-radius: 10px;
        overflow-y: scroll;
    }

    .menuFixMargin {
        margin-top: -42px;
    }


}

@media screen and (max-width:648px) {
    .wrapper {
        margin-left: 0px;
        position: relative;
        z-index: 3;
    }


}

@media screen and (max-width:584px) {
    .contentBtnHeaderFsc {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: center;
        width: 100%;
    }

    .headerContentFsc .child1 div button {
        width: 100%;
    }

    .headerContentFsc .child1 div input {
        width: 100%;
        padding: 11px;
        border-radius: 6px;
        border: 0px;
        margin-right: 15px;
        margin-bottom: 12px;
    }

    .headerContentFsc .child1 h1 {
        color: white;
        margin: 15px;
        text-align: center;
    }

    .FilterResultsContent .ContentModalFilter .contentTable table {
        width: 100%;
        text-align: left;
        border-collapse: collapse;
        min-width: 720px;
    }
}

@media screen and (max-width:412px) {
    .headerContentFsc .child2 .subChild1 {
        display: flex;
        flex-direction: column;
        background-color: #181634;
        color: white;
        padding: 40px;
        border-radius: 10px;
        position: relative;
        top: 100px;
        width: 110%;
    }

    .FilterResultsContent .ContentModalFilter {
        background-color: white;
        width: 96%;
        height: 580px;
        border-radius: 10px;
        overflow-y: scroll;
    }

    .FederalSupplyGroup {
        margin-top: 3vh;
        text-align: center;
        font-size: 30px;
    }

    .navigateSmall h1 {
        font-size: 16px;
        margin: 20px;
        font-weight: 500;
        margin-top: -156px;
        margin-bottom: 40px;
    }

    .navigateSmallFix1 h1 {
        font-size: 16px !important;
        margin: 20px !important;
        font-weight: 500 !important;
        margin-top: -76px !important;
        margin-bottom: -40px !important;
    }

    .navigateSmallFix2 h1 {
        font-size: 16px !important;
        margin: 20px !important;
        font-weight: 500 !important;
        margin-top: -76px !important;
        margin-bottom: 50px !important;
    }

    .FilterResultsContentMovil {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
    }

    .tableParts {
        background-color: white;
        min-width: 980px;
        padding: 20px;
        border-spacing: 0px;
    }


}

footer {
    margin-top: 4vh;
}

.rfqButton {
    padding: 20px;
    background-color: #e13019;
    border: 0;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.2s;
    font-weight: 700;
    font-size: 14px;
    position: relative;
    top: -2px;
}

/*   //?Acordeon */

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
    background-color: #eee;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    transition: 0.4s;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.active,
.accordion:hover {
    background-color: #ccc;
}

/* Style the accordion panel. Note: hidden by default */
.panel {
    padding: 0 18px;
    background-color: white;
    display: block;
    overflow: hidden;
    width: 100%;
    /* overflow-x:auto; */
}

.panelMPN {
    padding: 0 18px;
    background-color: white;
    display: none;
    /* max-height: 0; */
    overflow: hidden;

}

/* .panel {
    padding: 0 18px;
    background-color: white;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
  } */

.accordion:after {
    content: 'Read More \02795';
    /* Unicode character for "plus" sign (+) */
    font-size: 13px;
    color: #777;
    float: right;
    margin-right: 10px;
}

.accordion-active:after {
    content: "Read Less \2796";
    /* Unicode character for "minus" sign (-) */
    float: right;
    margin-right: 10px;
}


@media screen and (max-width:767px) {
    .panel {
        overflow-x: auto;
    }

    .panel-MPN {
        overflow-x: auto;
    }

    .container-fluid {
        overflow-x: auto;
    }
}



/* 


  @media screen and (max-width: 991px){
    .container-fluid{
        overflow-x:auto;
      }
  } */
.modal-component {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(255, 255, 255, 0.5);
  transition: opacity 0.2s ease-out, visibility 0.2s ease-out, background-color 0.2s ease-out;
}

.modal-component.show {
  opacity: 1;
  visibility: visible;
}

.modal-component.show .modal-content {
  scale: 1;
}

.modal-component.small .modal-content {
  width: min(272px, calc(100vw - 32px));
}

.modal-component.medium .modal-content {
  width: min(400px, calc(100vw - 32px));
}

.modal-component.large .modal-content {
  width: min(680px, calc(100vw - 32px));
}

.modal-component.extra-large .modal-content {
  width: min(920px, calc(100vw - 32px));
}

.modal-component.fluid .modal-content {
  width: calc(100vw - 160px);
}

.modal-component .modal-content {
  position: relative;
  scale: 0.5;
  background-color: white;
  transition: scale 0.2s ease-out;
}

.modal-component .modal-content .modal-header {
  display: flex;
  align-items: center;
  grid-gap: 1em;
  gap: 1em;
  padding: 1em 3em;
  background-color: #E13019;
}

.modal-component .modal-content .modal-header p {
  margin: 0;
}

.modal-component .modal-content .modal-header img {
  height: 2em;
  aspect-ratio: 1;
  filter: invert(100%);
}

.modal-component .modal-content .modal-header p {
  font-size: 1em;
  font-weight: 500;
  line-height: 1.5;
  color: white;
}

.modal-component .modal-content .modal-body {
  padding: 1.5em 3em 0 6em;
}

.modal-component .modal-content .modal-body hr {
  opacity: 0.5;
}

.modal-component .modal-content .modal-footer {
  display: flex;
  justify-content: flex-end;
  grid-gap: 8px;
  gap: 8px;
  padding: 1.5em 3em 1.5em 3em;
}

.modal-component .modal-content .modal-footer button {
  font-weight: 600;
  font-size: 1em;
  text-transform: uppercase;
  color: #595959;
  background: none;
  outline: none;
  box-shadow: none;
  border: none;
}
button.button-component {
  all: unset;
  padding: 12px 32px;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease-out, color 0.2s ease-out;
  border-radius: 6px;
}

button.button-component.normal {
  color: white;
  background-color: #e13019;
  box-shadow: 0px 2.93902px 2.93902px rgba(0, 0, 0, 0.25);
}

button.button-component.normal:hover {
  background-color: #595959;
}

button.button-component.underline {
  text-decoration: underline;
  color: #e13019;
}

button.button-component.underline:hover {
  color: #595959;
}
.manofacturer table th {
    background: #ccc;
    font-weight: bold;
}

.manofacturer {
    height: auto;
    margin: 50px;
    min-height: 530px;
}

.manofacturer table th, .manofacturer table td {
    /* padding: 0.5rem !important; */
    /* text-align: left !important; */
    font-size: 15px;
    min-width: 150px;
    text-align: center;
}

.manofacturer table {
    width: 100% !important;
    border-bottom: 1px solid #ccc !important;
}

.manofacturer h1 {
    background: #e13019 !important;
    color: #fff !important;
}
.trHead th{
    background: #e13019 !important;
    color: #fff !important;
}

.tdBody td{
    background: #fff !important;
    color: #000 !important;
}

.manofacturer h3 {
    color: #e13019 !important;
    font-weight: normal !important;
}

.manofacturerTable {
    overflow-x: scroll;
    padding-bottom: 70px;
}

.contentTables {
    display: flex;
    flex-wrap: wrap;
}

.titleDiv{
    background-color: #d6d6d6;
    padding: 10px;
    margin-top: 20px;
    text-align: center;
    border: 1px solid #bebebe;
}
.ContentDiv {
    text-align: center;
    padding: 10px;
}
div.scrollmenu {
    overflow: auto;
    white-space: nowrap;
    max-width: 100%;
}

div.scrollmenu a {
    background-color: #181634;
    display: inline-block;
    color: white;
    text-align: center;
    padding: 2px 3px;
    text-decoration: none;
    margin-right: 2px;
}

div.scrollmenu a:hover {
    background-color: #777;
}

.scrollmenu a.active,
.scrollmenu a.active a{
    background-color: #777;
}

.myOl {
    list-style-type: decimal;
    width: 600px;
    align-items: center;
    justify-content: center;
}

.myRow {
    display: flex;
    flex-direction: row;
}

.myOl {
    overflow: auto;
    white-space: nowrap;
}

.myOl li {
    float: left;
    width: 50px;
    padding: 2px 0px;
    list-style: none;
    color: black;
}

.myOl a li:hover {
    width: 50px;
    padding: 2px 0px;
    list-style: none;
    background-color: rgb(196, 15, 15);
    color: white;
    text-decoration: none;
    display: inline-block;
}



.headerContentFsc {
    background-size: cover;
    height: 510px;
    background-position: bottom;
    display: flex;
    flex-direction: row;
    padding: 30px;
    margin-top: -510px;
    position: relative;
}

.linkNSNS {
    text-decoration: underline;
    text-align: center;
    color: #e13019;
}

.titleSmall1 {
    font-size: 14px !important;
}

#loading {
    width: 90px;
    border-radius: 100% !important;
    margin: 0 auto;
    display: block;
    margin-top: -70px;
    position: relative;
    z-index: 5;
    display: block
}

#loading img {
    border-radius: 100% !important;
    filter: hue-rotate(212deg);
}

.myDiv {
    margin-top: 13vh;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
}

html,
body {
    font-family: 'Lato', sans-serif;
}

.wrapper {
    margin-left: -313px;
    display: inline-flex;
    height: 100px;
    width: 400px;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 5px;
    padding: 20px 15px;
    zoom: 0.8;
}

.wrapper .option {
    background: #fff;
    height: 100%;
    min-width: 128px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 0 10px;
    border-radius: 5px;
    cursor: pointer;
    padding: 0 10px;
    border: 2px solid lightgrey;
    transition: all 0.3s ease;
}

.wrapper .option .dot {
    height: 20px;
    width: 20px;
    background: #d9d9d9;
    border-radius: 50%;
    position: relative;
}

.wrapper .option .dot::before {
    position: absolute;
    content: "";
    top: 4px;
    left: 4px;
    width: 12px;
    height: 12px;
    background: #e13019;
    border-radius: 50%;
    opacity: 0;
    transform: scale(1.5);
    transition: all 0.3s ease;
}

input[type="radio"] {
    display: none;
}

#option-1:checked:checked~.option-1,
#option-2:checked:checked~.option-2,
#option-3:checked:checked~.option-3 {
    border-color: #e13019;
    background: #e13019;
}

#option-1:checked:checked~.option-1 .dot,
#option-2:checked:checked~.option-2 .dot,
#option-3:checked:checked~.option-3 .dot {
    background: #fff;
}

#option-1:checked:checked~.option-1 .dot::before,
#option-2:checked:checked~.option-2 .dot::before,
#option-3:checked:checked~.option-3 .dot::before {
    opacity: 1;
    transform: scale(1);
}

.wrapper .option span {
    font-size: 20px;
    color: #808080;
    margin-left: 10px;
}

#option-1:checked:checked~.option-1 span,
#option-2:checked:checked~.option-2 span,
#option-3:checked:checked~.option-3 span {
    color: #fff;
    margin-left: 10px;
}




#contentResultsIndex7 {
    display: none;
}

#contentResultsIndex9 {
    display: none;
}

.modalHomeSearch {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: #00000080;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 30;
    justify-content: center;
}

.modalHomeSearch .Content {
    background-color: white;
    padding: 70px;
    border-radius: 10px;
    width: 80%;
    height: 70%;
    overflow-y: scroll;
}

.modalHomeSearch .Content table {
    width: 100%;
    padding: 8px;
    border-radius: 10px;
    border-spacing: 0;
}

.modalHomeSearch .Content .CloseModalHome {
    display: flex;
    justify-content: flex-end;
    margin: -50px;
    padding: 0px;
    margin-bottom: 0px;
}

.modalHomeSearch .Content .CloseModalHome h1 {
    font-size: 20px;
    background-color: #E13019;
    font-family: 'Roboto';
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.FederalSupplyGroup {
    margin-top: 3vh;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
}

.modalHomeSearch .Content .CloseModalHome h1:hover {
    background-color: #E13019;
}

.modalHomeSearch .Content::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    /* width of the entire scrollbar */
}

.modalHomeSearch .Content::-webkit-scrollbar-track {
    background: rgb(255, 255, 255);
    /* color of the tracking area */
}

.modalHomeSearch .Content::-webkit-scrollbar-thumb {
    background-color: #E13019;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: 3px solid rgb(255, 255, 255);
    /* creates padding around scroll thumb */
}






.modalHomeSearch .Content table thead tr td {
    background-color: #E13019;
    padding: 10px;
    font-size: 17px;
    color: white;
}

.modalHomeSearch .Content table tbody tr td {
    padding: 11px;
    border-bottom: 1px solid #00000029;
}

/* slider --------------------------- */
.slider {
    height: 510px;
    width: 100%;
    overflow: hidden;
    margin: 0;
    margin-top: -12px;
    filter: brightness(0.6);
    animation: hidde 30s infinite alternate ease-in-out;
}

.slider ul {
    display: flex;
    padding: 0;
    width: 400%;
    animation: cambio 30s infinite alternate steps(1);
}

.slider li {
    width: 100%;
    list-style: none;
}

.slider img {
    width: 100%;
}

@keyframes hidde {
    0% {
        opacity: 1;
    }

    19% {
        opacity: 1;
    }

    25% {
        opacity: 0;
    }

    30% {
        opacity: 1;
    }

    45% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    55% {
        opacity: 1;
    }

    65% {
        opacity: 1;
    }

    70% {
        opacity: 1;
    }

    75% {
        opacity: 1;
    }

    100% {}
}

@keyframes cambio {
    0% {
        margin-left: 0;
    }

    20% {
        margin-left: 0;
    }

    25% {
        margin-left: -150%;
    }

    45% {
        margin-left: -150%;
    }

    50% {
        margin-left: -300%;
    }

    70% {
        margin-left: -300%;
    }

    75% {
        margin-left: -300%;
    }

    100% {
        margin-left: -300%;
    }
}



.headerContentFsc .child1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
}

.headerContentFsc .child1 h1 {
    color: white;
    font-size: 26px;
    margin: 15px;
}

.headerContentFsc .child1 div input {
    font-size: 20px;
    width: 481px;
    padding: 16px;
    border-radius: 6px;
    border: 0;
    margin-right: 15px;
}

.headerContentFsc .child1 div button {
    padding: 20px;
    background-color: #e13019;
    border: 0;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.2s;
    font-weight: 700;
    font-size: 14px;
    position: relative;
    top: -2px;
}

.headerContentFsc .child1 div button:hover {
    color: #E13019;
    background-color: white;
}

.headerContentFsc .child2 {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.headerContentFsc .child2 .subChild1 {
    display: flex;
    flex-direction: column;
    background-color: #181634;
    color: white;
    padding: 40px;
    border-radius: 10px;
    position: relative;
    top: 100px;
    width: 450px;
    z-index: 11;
}

.headerContentFsc .child2 .subChild1 h1 {
    text-align: center;
    color: white;
    font-size: 25px;
    margin-top: 0px;
    margin-bottom: 50px;
    font-weight: bolder;
}

.headerContentFsc .child2 .subChild1 span {
    color: white;
    font-size: 13px;
    margin: 8px;
    margin-left: 0px;
    font-weight: bolder;
}

/* label */
.headerContentFsc .child2 .subChild1 label {
    color: white;
    font-size: 13px;
    margin: 8px;
    margin-left: 0px;
    font-weight: bolder;
}

.headerContentFsc .child2 .subChild1 input {
    padding: 10px;
    background-color: #2c2a4a;
    border: 0px;
    border-radius: 7px;
    margin-bottom: 15px;
    color: white;
}

.headerContentFsc .child2 .subChild1 button {
    padding: 10px;
    background-color: #E13019;
    color: white;
    border-radius: 6px;
    border: 0px;
    margin-top: 19px;
    cursor: pointer;
    transition: 0.2s;
}

.headerContentFsc .child2 .subChild1 button:hover {
    color: #E13019;
    background-color: white;
}

.FilterResultsContent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.sliderClass {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    overflow-y: scroll;
    background-color: white;
    margin: 30px;
    margin-top: 6px;
    flex-wrap: wrap;
    justify-content: center;
    height: 340px;
}

.sliderClass::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    /* width of the entire scrollbar */
}

.sliderClass::-webkit-scrollbar-track {
    background: rgb(255, 255, 255);
    /* color of the tracking area */
}

.sliderClass::-webkit-scrollbar-thumb {
    background-color: #E13019;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: 3px solid rgb(255, 255, 255);
    /* creates padding around scroll thumb */
}





.sliderClass .contentSliderClass {
    background-color: white;
    margin: 13px;
    width: 280px;
    min-width: 280px;
    box-shadow: 0px 0px 11px 0px #0000001c;
    border-radius: 10px;
    margin-bottom: 60px;
    margin-top: 40px;
    cursor: pointer;
    position: relative;
}

.sliderClass .contentSliderClass:hover h1 {
    background-color: #E13019;
}

.sliderClass .contentSliderClass h1 {
    background-color: #475b70;
    text-align: center;
    font-size: 24px;
    padding: 9px;
    color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.sliderClass .contentSliderClass h2 {
    text-align: center;
    font-size: 18px;
    padding-inline: 14px;
}

.sliderClass .contentSliderClass p {
    padding: 14px;
    text-align: center;
    font-size: 14px;
}

.sliderClass .contentSliderClass h3 {
    position: absolute;
    margin: 0 auto;
    left: 43%;
    top: 93%;
    background-color: #E13019;
    width: 41px;
    height: 41px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-family: monospace;
    border-radius: 5px;
    color: white;
}


.FilterResultsContent .contentResults {
    background-color: white;
    width: 270px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-shadow: 0px 0px 15px 0px #0000001c;
    border-radius: 10px;
    margin: 20px;
    margin-bottom: 60px;
    height: 290px;
    position: relative;
}

.FilterResultsContent .contentResults h1 {
    background-color: #475b70;
    width: 100%;
    text-align: center;
    font-size: 23px;
    padding: 12px;
    color: white;
    border-radius: 10px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.FilterResultsContent .contentResults h2 {
    width: 100%;
    text-align: center;
    margin: 7px;
    font-size: 20px;
    padding-inline: 20px;
    font-family: system-ui;
    font-weight: bold;
}

.navigateSmall h1 {
    font-size: 26px;
    margin: 20px;
    font-weight: 500;
    max-width: 730px;
}

.navigateSmall {
    position: relative;
    z-index: 6;
}

.navigateSmall span {
    color: #E13019;
}

.navigateSmall span:hover {
    text-decoration: underline;
    cursor: pointer;
}

.NotFoundItemText {
    font-size: 21px;
    background-color: #E13019;
    padding: 12px;
    border-radius: 8px;
    color: white;
}

.FilterResultsContent .contentResults p {
    padding: 20px;
    text-align: center;
    font-size: 13px;
}

.FilterResultsContent .contentResults button {
    width: 40px;
    background-color: #E13019;
    border: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    position: absolute;
    top: 241px;
    height: 40px;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    transition: 0.2s;
    margin-left: -17px;
}

.FilterResultsContent .contentResults button:hover {
    top: 238px;
}

/* modal Fsc ------------------------------- */
.FilterResultsContent .modalInfoFsc {
    position: fixed;
    background-color: #000000b5;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 4;
    display: none;
    align-items: center;
    justify-content: center;
}

.FilterResultsContent .modalInfoFscActive {
    position: fixed;
    background-color: #000000b5;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 30;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tableParts-tbl2 {
    background-color: white;
    width: 100%;
    padding: 20px;
    border-spacing: 0px;
    border: 1px solid #DDDDDD;
}

.tableParts-tbl2 thead tr td {
    background-color: #E13019;
    text-align: center;
    padding: 8px;
    font-size: 14px;

    color: white;
}

.tableParts-tbl2 thead tr th {
    background-color: #F4F4F4;
    text-align: center;
    padding: 8px;
    font-size: 14px;
    color: #666666;
}

.tableParts-tbl2 tbody tr td {
    border: 1px solid #DDDDDD;
    padding: 11px;
}



/*  */

.tableParts {
    background-color: white;
    width: 100%;
    /* padding: 247px; */
    border-spacing: 0px;
    /* border: 1px solid #DDDDDD; */
    /* padding-left: 367px;
    padding-right: 560px;
    margin-left: -32%; */
}

.tableParts thead tr td {
    background-color: #E13019;
    text-align: center;
    padding: 8px;
    font-size: 14px;

    color: white;
}

.tableParts thead tr th {
    background-color: #E13019;
    text-align: center;
    padding: 8px;
    font-size: 14px;
    color: white;
}

.tableParts tbody tr td {
    border: 1px solid #DDDDDD;
    padding: 11px;
}

/* .tableITEM_Description {
    background-color: white;
    width: 100%;
    padding: 20px;
    border-spacing: 0px;
    border: 1px solid #DDDDDD;
} */

/* .tableITEM_Description {
    background-color: white;
    width: 128%;
    /* padding: 318px; */
  /*  border-spacing: 0px;
    border: 1px solid #DDDDDD;
    padding-left: 20px;
    padding-right: 318px;
    padding-bottom: 10px;
/*} */

/* .tableITEM_Description {
    background-color: white;
    width: 173%;
    /* padding: 318px; */
    /* border-spacing: 0px; */
    /* border: 1px solid #DDDDDD; */
    /* padding-left: 510px;
    /* padding-right: 320px; */
    /* padding-bottom: 10px; */
    /* margin-left: -45%; */ */
/* } */ */

.tableITEM_Description {
    background-color: white;
    width: 100%;
    /* padding: 318px; */
    border-spacing: 0px;
    border: 1px solid #DDDDDD;
    /* padding-left: 510px; */
    /* padding-right: 320px; */
    padding-bottom: 10px;
    /* margin-left: -45%; */
}


.tableITEM_Description tbody tr th {
    background-color: #E13019;
    text-align: left;
    padding: 8px;
    font-size: 14px;
    color: white;
    border: 1px solid #DDDDDD;
}

.tableITEM_Description tbody tr td {
    padding: 11px;
    border: 1px solid #DDDDDD;
}



.FilterResultsContent .ContentModalFilter {
    background-color: white;
    width: 1190px;
    height: 680px;
    border-radius: 10px;
    overflow-y: scroll;
    scroll-behavior: smooth !important;
}

.FilterResultsContent .ContentModalFilter::-webkit-scrollbar {
    width: 10px;
    /* width of the entire scrollbar */
}

.FilterResultsContent .ContentModalFilter::-webkit-scrollbar-track {
    background: rgb(255, 255, 255);
    /* color of the tracking area */
}

.FilterResultsContent .ContentModalFilter::-webkit-scrollbar-thumb {
    background-color: #E13019;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: 3px solid rgb(255, 255, 255);
    /* creates padding around scroll thumb */
}






.FilterResultsContent .ContentModalFilter .exitContent {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.FilterResultsContent .ContentModalFilter .exitContent h1 {
    font-size: 13px;
    background-color: #475b70;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 11px;
    border-radius: 100%;
    font-family: cursive;
    color: white;
    cursor: pointer;
}

.FilterResultsContent .ContentModalFilter .titleContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.FilterResultsContent .ContentModalFilter .titleContent h1 {
    margin: 0px;
    font-size: 24px;
}

.FilterResultsContent .ContentModalFilter .titleContent div {
    background-color: #E13019;
    width: 110px;
    height: 3px;
    margin-top: 10px;
    margin-bottom: 10px;
    opacity: 0;
}

.FilterResultsContent .ContentModalFilter .titleContent h2 {
    font-size: 18px;
    width: 510px;
    text-align: center;
    font-weight: lighter;
}

.FilterResultsContent .ContentModalFilter .contentTable {
    padding: 30px;
    padding-top: 0px;
}

.FilterResultsContent .ContentModalFilter .contentTable table {
    width: 100%;
    text-align: left;
    border-collapse: collapse;
}

.FilterResultsContent .ContentModalFilter .contentTable table td {
    padding: 3px;
}

.FilterResultsContent .ContentModalFilter .contentTable table tbody td {
    width: 0%;
    font-size: 10px;
    padding: 11px;
    padding-left: 20px;
}

.FilterResultsContent .ContentModalFilter .contentTable table tbody td span {
    background-color: #E13019;
    padding: 10px;
    color: white;
    padding-inline: 30px;
    border-radius: 7px;
    cursor: pointer;
    transition: 0.2s;
}

.FilterResultsContent .ContentModalFilter .contentTable table tbody td span:hover {
    color: #E13019;
    background-color: rgb(223, 223, 223);
}

.FilterResultsContent .ContentModalFilter .contentTable table thead tr td {
    background-color: #E13019;
    color: white;
    padding-left: 20px;
}

.FilterResultsContent .ContentModalFilter .contentTable table thead .trColor1 {
    background-color: #191736 !important;
}

.FilterResultsContent .ContentModalFilter .contentTable table tbody tr:nth-child(even) {
    background-color: #f3f3f3;
    color: #898989;
}

.errorNoItem {
    color: #E13019;
    font-size: 21px;
    background-color: #f3f3f3;
    padding: 13px;
    padding-inline: 90px;
    border-radius: 8px;
    margin-top: 80px;
}

@media (min-width:1187px){
    .nscContainer{
        margin-top: 150px;
    }
}

/* @media screen and (max-width: 1268px){
    .child2 {
        width: 100vw;
        margin-top: -752px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: -57vw;
        align-items: center;
        position: relative;
        top: 420px;
    }
} */

@media screen and (max-width:1186px) {
    .headerContentFsc {
        background-size: cover;
        height: 510px;
        background-position: bottom;
        display: flex;
        flex-direction: column;
        padding: 30px;
        position: relative;
        align-items: center;
    }

    .headerContentFsc .child2 {
        width: 100vw;
        position: relative;
        /* margin-left: -57vw; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: -50px;

        /* width: 100vw;
        margin-top: -752px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: -57vw;
        align-items: center;
        position: relative;
        top: 420px; */
    }

    .headerContentFsc .child2 .subChild1 {
        display: flex;
        flex-direction: column;
        background-color: #181634;
        color: white;
        padding: 40px;
        border-radius: 10px;
        position: relative;
        top: 100px;
        width: 92%;
    }

    .headerContentFsc .child1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .slider img {
        min-width: 1460px;
        height: 530px;
    }

    .slider li {
        width: 100%;
        list-style: none;
        overflow: hidden;
    }
}

@media screen and (max-width:944px) {
    .FilterResultsContent .ContentModalFilter {
        background-color: white;
        width: 96%;
        height: 680px;
        border-radius: 10px;
        overflow-y: scroll;
    }

    .menuFixMargin {
        margin-top: -42px;
    }
}

@media screen and (max-width:648px) {
    .wrapper {
        margin-left: 0px;
        position: relative;
        z-index: 3;
    }
}

@media screen and (max-width:584px) {
    .contentBtnHeaderFsc {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: center;
        width: 100%;
    }

    .headerContentFsc .child1 div button {
        width: 100%;
    }

    .headerContentFsc .child1 div input {
        width: 100%;
        padding: 11px;
        border-radius: 6px;
        border: 0px;
        margin-right: 15px;
        margin-bottom: 12px;
    }

    .headerContentFsc .child1 h1 {
        color: white;
        font-size: 26px;
        margin: 15px;
        text-align: center;
    }

    .FilterResultsContent .ContentModalFilter .contentTable table {
        width: 100%;
        text-align: left;
        border-collapse: collapse;
        min-width: 720px;
    }
}

@media screen and (max-width:412px) {
    .headerContentFsc .child2 .subChild1 {
        display: flex;
        flex-direction: column;
        background-color: #181634;
        color: white;
        padding: 40px;
        border-radius: 10px;
        position: relative;
        top: 100px;
        width: 92%;
    }

    .FilterResultsContent .ContentModalFilter {
        background-color: white;
        width: 96%;
        height: 580px;
        border-radius: 10px;
        overflow-y: scroll;
    }

    .FederalSupplyGroup {
        margin-top: 3vh;
        text-align: center;
        font-size: 30px;
    }

    .navigateSmall h1 {
        font-size: 16px;
        margin: 20px;
        font-weight: 500;
        margin-bottom: 40px;
    }

    .navigateSmallFix1 h1 {
        font-size: 16px !important;
        margin: 20px !important;
        font-weight: 500 !important;
        margin-top: -76px !important;
        margin-bottom: -40px !important;
    }

    .navigateSmallFix2 h1 {
        font-size: 16px !important;
        margin: 20px !important;
        font-weight: 500 !important;
        margin-top: -76px !important;
        margin-bottom: 50px !important;
    }

    .FilterResultsContentMovil {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
    }

    .tableParts {
        background-color: white;
        min-width: 980px;
        padding: 20px;
        border-spacing: 0px;
    }
    .tableParts-tbl2 {
        background-color: white;
        min-width: 980px;
        padding: 20px;
        border-spacing: 0px;
    }
}

footer {
    margin-top: 4vh;
}

.rfqButton {
    padding: 4px 15px;
    background-color: #e13019;
    border: 0;
    color: #fff;
    margin-right: 10px;
    margin-left: 10px;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.2s;
    font-weight: 700;
    height: 30px;
    font-size: 14px;
    position: relative;
    top: -2px;
}

.request_container{
    display: flex;
    flex-direction: column;
    background-color: #181634;
    color: white;
    padding: 40px;
    border-radius: 10px;
    position: relative;
    top: 0;
    width: 450px;
    z-index: 11;
}

.text-press{
    text-align: left;
}


@media screen and (min-width: 1187px) {
    .request_container{
        display: flex;
        flex-direction: column;
        background-color: #181634;
        color: white;
        padding: 40px;
        border-radius: 10px;
        position: relative;
        top: 0 !important;
        width: 450px;
        z-index: 11;
    }
}

@media (max-width:371px){
    .nscContainer h1,.nscContainer h2{
        font-size: 20px;
    }
}
/*!
 =========================================================
 * Material Dashboard React - v1.10.0 based on Material Dashboard - v1.2.0
 =========================================================
 * Product Page: http://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2021 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)
 =========================================================
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
.ct-grid {
  stroke: rgba(255, 255, 255, 0.2);
  stroke-width: 1px;
  stroke-dasharray: 2px;
}

.ct-series-a .ct-point,
.ct-series-a .ct-line,
.ct-series-a .ct-bar,
.ct-series-a .ct-slice-donut {
  stroke: rgba(255, 255, 255, 0.8);
  stroke-width: 10px;
}

.ct-label.ct-horizontal.ct-end {
  align-items: flex-end;
  justify-content: flex-start;
  text-align: end;
  text-anchor: start;
}

.ct-label {
  color: rgba(255, 255, 255, 0.7);
}

.ct-chart-line .ct-label,
.ct-chart-bar .ct-label {
  display: block;
  display: flex;
}

.ct-label {
  fill: rgba(0, 0, 0, 0.4);
  line-height: 1;
  margin-left: 40px;
}

html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #d5d4d4;
  color: #3c4858;
  margin: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 300;
  line-height: 1.5em;
}

blockquote footer:before,
blockquote small:before {
  content: "\2014 \00A0";
}

small {
  font-size: 80%;
}

h1 {
  font-size: 3em;
  line-height: 1.15em;
}

h2 {
  font-size: 2.4em;
}

h3 {
  font-size: 1.825em;
  line-height: 1.4em;
  margin: 20px 0 10px;
}

h4 {
  font-size: 1.3em;
  line-height: 1.4em;
}

h5 {
  font-size: 1.25em;
  line-height: 1.4em;
  margin-bottom: 15px;
}

h6 {
  font-size: 1em;
  text-transform: uppercase;
  font-weight: 500;
}

body {
  background-color: #d5d4d4;
  color: #3c4858;
}

blockquote p {
  font-style: italic;
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  line-height: 1.5em;
}

a {
  text-decoration: none;
}

a:hover,
a:focus {
  text-decoration: none;
}

legend {
  border-bottom: 0;
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}

*:focus {
  outline: 0;
}

a:focus,
a:active,
button:active,
button:focus,
button:hover,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"]>input[type="button"]::-moz-focus-inner {
  outline: 0 !important;
}

legend {
  margin-bottom: 20px;
  font-size: 21px;
}

output {
  padding-top: 8px;
  font-size: 14px;
  line-height: 1.42857;
}

label {
  font-size: 14px;
  line-height: 1.42857;
  color: #aaaaaa;
  font-weight: 400;
}

footer {
  padding: 15px 0;
  margin-top: 5vh;
}

footer ul {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}

footer ul li {
  display: inline-block;
}

footer ul li a {
  color: inherit;
  padding: 15px;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 3px;
  text-decoration: none;
  position: relative;
  display: block;
}

footer ul li a:hover {
  text-decoration: none;
}

@media (max-width: 991px) {

  body,
  html {
    position: relative;
    overflow-x: hidden;
  }

  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 0;
    top: 0;
    left: auto;
    right: 260px;
    content: "";
    z-index: 9999;
    overflow-x: hidden;
  }
}

.fixed-plugin {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  line-height: 1.5em;
  position: fixed;
  top: 180px;
  right: 0;
  width: 64px;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1031;
  border-radius: 8px 0 0 8px;
  text-align: center;
  top: 120px;
}

.fixed-plugin .SocialMediaShareButton,
.fixed-plugin .github-btn {
  display: inline-block;
}

.fixed-plugin li>a,
.fixed-plugin .badge {
  transition: all 0.34s;
  -webkit-transition: all 0.34s;
  -moz-transition: all 0.34s;
  text-decoration: none;
}

.fixed-plugin .fa-cog {
  color: #ffffff;
  padding: 10px;
  border-radius: 0 0 6px 6px;
  width: auto;
}

.fixed-plugin .dropdown-menu {
  right: 80px;
  left: auto;
  width: 290px;
  border-radius: 0.1875rem;
  padding: 0 10px;
  position: absolute;
  color: rgba(0, 0, 0, 0.87);
  display: inline-block;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  background: #fff;
  border-radius: 3px;
}

.fixed-plugin .fa-circle-thin {
  color: #ffffff;
}

.fixed-plugin .active .fa-circle-thin {
  color: #00bbff;
}

.fixed-plugin .dropdown-menu>.active>a,
.fixed-plugin .dropdown-menu>.active>a:hover,
.fixed-plugin .dropdown-menu>.active>a:focus {
  color: #777777;
  text-align: center;
}

.fixed-plugin img {
  border-radius: 0;
  width: 100%;
  height: 100px;
  margin: 0 auto;
}

.fixed-plugin .dropdown-menu li>a:hover,
.fixed-plugin .dropdown-menu li>a:focus {
  box-shadow: none;
}

.fixed-plugin .badge {
  border: 3px solid #ffffff;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 23px;
  margin-right: 5px;
  position: relative;
  width: 23px;
  background-color: rgba(30, 30, 30, 0.97);
}

.fixed-plugin .badge.active,
.fixed-plugin .badge:hover {
  border-color: #00bbff;
}

.fixed-plugin .badge-purple {
  background-color: #9c27b0;
}

.fixed-plugin .badge-blue {
  background-color: #00bcd4;
}

.fixed-plugin .badge-green {
  background-color: #4caf50;
}

.fixed-plugin .badge-orange {
  background-color: #ff9800;
}

.fixed-plugin .badge-red {
  background-color: #f44336;
}

.fixed-plugin h5 {
  font-size: 14px;
  margin: 10px;
}

.fixed-plugin .dropdown-menu li {
  display: block;
  padding: 4px 0px;
  width: 25%;
  float: left;
}

.fixed-plugin li.adjustments-line,
.fixed-plugin li.header-title,
.fixed-plugin li.button-container {
  width: 100%;
  height: 50px;
  min-height: inherit;
  padding: 0px;
  text-align: center;
}

.fixed-plugin li.adjustments-line p {
  margin: 0;
}

.fixed-plugin li.adjustments-line div,
.fixed-plugin li.header-title div,
.fixed-plugin li.button-container div {
  margin-bottom: 5px;
}

.fixed-plugin li.header-title {
  height: 30px;
  line-height: 25px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
}

.fixed-plugin .adjustments-line p {
  float: left;
  display: inline-block;
  margin-bottom: 0;
  font-size: 1em;
  color: #3c4858;
}

.fixed-plugin .adjustments-line a {
  color: transparent;
}

.fixed-plugin .adjustments-line a .badge-colors {
  position: relative;
  top: -2px;
}

.fixed-plugin .adjustments-line a a:hover,
.fixed-plugin .adjustments-line a a:focus {
  color: transparent;
}

.fixed-plugin .adjustments-line .dropdown-menu>li.adjustments-line>a {
  padding-right: 0;
  padding-left: 0;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  margin: 0;
}

.fixed-plugin .dropdown-menu>li>a.img-holder {
  font-size: 16px;
  text-align: center;
  border-radius: 10px;
  background-color: #fff;
  border: 3px solid #fff;
  padding-left: 0;
  padding-right: 0;
  opacity: 1;
  cursor: pointer;
  display: block;
  max-height: 100px;
  overflow: hidden;
  padding: 0;
}

.fixed-plugin .dropdown-menu>li>a.img-holder img {
  margin-top: auto;
}

.fixed-plugin .dropdown-menu>li:hover>a.img-holder,
.fixed-plugin .dropdown-menu>li:focus>a.img-holder {
  border-color: rgba(0, 187, 255, 0.53);
}

.fixed-plugin .dropdown-menu>.active>a.img-holder,
.fixed-plugin .dropdown-menu>.active>a.img-holder {
  border-color: #00bbff;
  background-color: #ffffff;
}

.fixed-plugin .dropdown .dropdown-menu {
  transform: translateY(-15%);
  top: 27px;
  opacity: 0;
  transform-origin: 0 0;
  display: none;
}

.fixed-plugin .dropdown .dropdown-menu:before {
  border-bottom: 0.4em solid transparent;
  border-left: 0.4em solid rgba(0, 0, 0, 0.2);
  border-top: 0.4em solid transparent;
  right: -16px;
  top: 46px;
}

.fixed-plugin .dropdown .dropdown-menu:after {
  border-bottom: 0.4em solid transparent;
  border-left: 0.4em solid #ffffff;
  border-top: 0.4em solid transparent;
  right: -16px;
}

.fixed-plugin .dropdown .dropdown-menu:before,
.fixed-plugin .dropdown .dropdown-menu:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 46px;
  width: 16px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
}

.fixed-plugin .dropdown.show .dropdown-menu {
  display: block;
  visibility: visible;
  opacity: 1;
  transform: translateY(-13%);
  transform-origin: 0 0;
}

.fixed-plugin.rtl-fixed-plugin {
  right: auto;
  left: 0px;
  border-radius: 0 8px 8px 0;
}

.fixed-plugin.rtl-fixed-plugin .dropdown-menu {
  right: auto;
  left: 80px;
}

* {
  letter-spacing: normal !important;
}
